import React, { Component } from 'react';
// import ErrorPage from '../ErrorPage';
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage';

// !!! IF Async code or Event handlers- add props error - boolean; !!!
// for example:
// const [error, setError] = useState(false);
// getResponse.then(() => ..., setError(true))
// .catch((err) => setError(true));

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return ({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { error = false, children } = this.props;

    if (hasError || error) {
      return <NotFoundPage />;
    }
    return children;
  }
}
