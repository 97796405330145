import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { GradeBookStudentRow } from '../GradeBookPage/GradebooksPageComponents/ClassGradeBook/GradeBookStudentRow/GradeBookStudentRow';
import { ClassGradeBookPageHeader } from './ClassGradeBookPageHeader/ClassGradeBookPageHeader';
import { StyledClassGradeBook } from './StyledClassGradeBook.js';
// import { defaultResponseFromServer } from '../../GradeBookClassObjects';
import { TestTableHeader } from '../GradeBookPage/GradebooksPageComponents/ClassGradeBook/TestTableHeader/TestTableHeader';
import { GradebookSudentInfoCell } from '../GradeBookPage/GradebooksPageComponents/ClassGradeBook/GradebookSudentInfoCell/GradebookSudentInfoCell';
import { TableColorLegend } from '../../components/TableColorLegend/TableColorLegend';
// import ButtonText from '../../Components/buttons/buttonText/ButtonText.jsx'
import { AppContext } from '../../context/AppContext';
import { config } from '../../config';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import './ClassGradeBookPage.scss';
import NoDataClassGradebook from './NoDataClassGradebook/NoDataClassGradebook';
import NoTestDataClassGradebook from './NoTestDataClassGradebook/NoTestDataClassGradebook';
import NoStudentsDataClassGradebook from './NoStudentsDataClassGradebook copy/NoStudentsDataClassGradebook';
import NoStudentsFirstColumn from './NoStudentsFirstColumn/NoStudentsFirstColumn';


export const ClassGradeBookPage = () => {
  const {
    showLoader,
    setShowLoader,
    currentSchoolId,
    updateCurrentClassId,
    classInfo,
    setClassInfo,
  } = useContext(AppContext);

  // get query param

  const queryParams = new URLSearchParams(window.location.search);
  const className = queryParams.get('class');
  const classId = queryParams.get('classId');

  const [unfilteredTests, setUnFilteredTests] = useState();

  const [tests, setTests] = useState();
  const [studentTests, setStudentTests] = useState();
  const [studentInfo, setStudentInfo] = useState();

  const [conceptFilter, setConceptFilter] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const [searchValue, setSearchValue] = useState('');

  const filterBySearchValue = () => {
    const filter = [];
    if (unfilteredTests) {
      for (let i = 0; i < unfilteredTests.length; i++) {
        const testNameToloer = unfilteredTests[i].testName.toLowerCase();
        // console.log(testNameToLowerCase)
        if (testNameToloer.includes(searchValue.toLowerCase())) {
          filter.push(unfilteredTests[i]);
        }
      }
      if (searchValue) {
        setTests(filter);
      } else {
        setTests(unfilteredTests);
      }
    }
  };

  const fetchToGetdata = async () => {
    setShowLoader(true);
    axios
      .post(`${config.API_URL}/api/gradebook/info`, {
        classId: classId,
        conceptFilter: conceptFilter,
        startDateFilter: startDateFilter,
        endDateFilter: endDateFilter,
      })
      .then(
        (response) => {
          // console.log('response', response.data);
          setStudentTests(response.data.students);
          setTests(response.data.tests);
          setUnFilteredTests(response.data.tests);
          setClassInfo({
            tests: response.data.tests.length,
            score: response.data.score,
            speed: response.data.speed,
            grade: response.data.grade,
            year: response.data.year,
            className: response.data.className,
            classId: response.data.classId,
            teachers: response.data.teachers,
          });
          updateCurrentClassId(classId);
          setShowLoader(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    fetchToGetdata();
    fetchToGetStudents();
  }, [conceptFilter, endDateFilter, currentSchoolId]);

  useEffect(() => {
    filterBySearchValue();
  }, [searchValue]);

  const testOrder = tests ? tests.reduce((sum, currentId) => [...sum, currentId.testId], []) : [];

  const gridWidth = [];
  for (let i = 0; i < testOrder.length; i++) {
    gridWidth.push('262px');
  }

  // ==========================
  async function fetchToGetStudents() {
    setShowLoader(true);
    const response = await axios.get(`${config.API_URL}/api/student?schoolId=${currentSchoolId}`);
    // console.log('response.data- students', response.data);
    setStudentInfo(response.data);
    setShowLoader(false);
  }
  // ==========================

  let studentsInClass = [];
  function getStudentsInClass() {
    if (studentInfo?.length > 0) {
      studentsInClass = studentInfo.filter((stud) => stud.classId === Number(classInfo.classId));
      return studentsInClass;
    }
  }
  getStudentsInClass();


  console.log('studentsInClass', studentsInClass)
  console.log('studentTests', studentTests);

  const filteredStud = studentsInClass.filter((stud) => stud.isArchive === 0);
  console.log('filteredStud', filteredStud);
  return (
    <div className="classGradeBookWrapper">
      {showLoader && <LoaderFullBook />}

      {!tests || !classInfo ? (
        <div style={{ color: 'white' }} className="">
          loading...{' '}
        </div>
      ) : (
        <>
          <InternalPageContainer />
          <ClassGradeBookPageHeader
            conceptFilter={conceptFilter}
            setConceptFilter={setConceptFilter}
            startDateFilter={startDateFilter}
            setStartDateFilter={setStartDateFilter}
            endDateFilter={endDateFilter}
            setEndDateFilter={setEndDateFilter}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            classInfo={classInfo}
            classId={classId}
            studentsTest={studentTests}
            tableHeaderType={'gradebook'}
            tests={tests}
            fetchToGetdata={fetchToGetdata}
            fetchToGetStudents={fetchToGetStudents}
          />

          <StyledClassGradeBook rowLength={testOrder} gridTemplate={gridWidth.join(' ')}>
            <>
              <div style={{ display: 'flex' }} className="">
                {/* first student column */}
                {studentsInClass.length > 0 ? (
                  <div
                    className={
                      studentsInClass?.length > 0
                        ? 'classGradeBook_gradeBook_studentsInfo_container'
                        : 'classGradeBook_gradeBook_studentsInfo_container containerMargin'
                    }
                  >
                    <div className="classGradeBook_gradeBook_table_headerForStudent">
                      Students ({studentsInClass.length})
                    </div>

                    {/* if the are students and NO tests */}
                    {studentsInClass?.length > 0 &&
                      studentTests?.length === 0 &&
                      studentsInClass
                        .filter((stud) => stud.isArchive === 0)
                        // .reverse()
                        .map((student, index) => {
                          return (
                            <GradebookSudentInfoCell
                              student={student}
                              // testOrder = {testOrder}
                              index={index}
                              key={index}
                              // key = {student.studentIdSchool}
                            />
                          );
                        })}

                    {/* if the are students and tests */}
                    {studentsInClass?.length > 0 &&
                      studentTests?.length > 0 &&
                      studentTests
                        // .filter((stud) => stud.isArchive === 0)
                        // .reverse()
                        .map((student, index) => {
                          return (
                            <GradebookSudentInfoCell
                              student={student}
                              // testOrder = {testOrder}
                              index={index}
                              key={index}
                              // key = {student.studentIdSchool}
                            />
                          );
                        })}
                  </div>
                ) : (
                  <NoStudentsFirstColumn />
                )}

                {/* second test column */}
                {tests.length === 0 && studentTests.length === 0 ? (
                  <NoTestDataClassGradebook />
                ) : (
                  <>
                    <div className="classGradeBook_gradeBook_tablecontainer">
                      <div className="classGradeBook_gradeBook_table_header">
                        {tests.map((test) => {
                          return (
                            <TestTableHeader
                              test={test}
                              key={test.testId}
                              studentTests={studentTests}
                              fetchToGetdata={fetchToGetdata}
                            />
                          );
                        })}
                      </div>
                      {studentTests.length > 0 ? (
                        <div className="classGradeBook_gradeBook_table_Wrapper">
                          <div className="classGradeBook_gradeBook_table_Body">
                            {studentTests.map((student, index) => {
                              return (
                                <GradeBookStudentRow
                                  student={student}
                                  // tests={tests}
                                  testOrder={testOrder}
                                  index={index}
                                  key={student.studentIdSchool}
                                />
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <NoStudentsDataClassGradebook />
                      )}
                    </div>
                  </>
                )}
              </div>
            </>

            {/* average data column */}
            <div className="classGradeBook_gradeBook_averageDataColumn">
              <div className="classGradeBook_gradeBook_averageDataColumn_header">AVERAGE</div>
              <div className="classGradeBook_gradeBook_averageDataColumn_allTestsWrapper">
                {tests.map((test) => {
                  return (
                    <div className="classGradeBook_gradeBook_averageDataColumn_testData">
                      {test.testAvScoreInClass ? (
                        <div
                          className="classGradeBook_gradeBook_averageDataColumn_testScore"
                          style={{
                            color: test.testAvScoreInClass ? test.testAvScoreInClass.color : null,
                          }}
                        >
                          {test.testAvScoreInClass.score.toFixed(0)}
                        </div>
                      ) : (
                        <div className="classGradeBook_gradeBook_averageDataColumn_testScore"></div>
                      )}
                      {test.testAvSpeedInClass ? (
                        <div
                          className="classGradeBook_gradeBook_averageDataColumn_testSpeed"
                          style={{ color: test.testAvSpeedInClass.color }}
                        >
                          {test.testAvSpeedInClass.speed}
                        </div>
                      ) : (
                        <div
                          style={{ color: '#828282' }}
                          className="classGradeBook_gradeBook_averageDataColumn_testSpeed"
                        >
                          NO DATA
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </StyledClassGradeBook>
          <TableColorLegend />
        </>
      )}
    </div>
  );
};
