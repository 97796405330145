import { useCallback, useContext,  useState } from 'react';
import { LoaderFullBook } from '../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { AppContext } from '../../../../context/AppContext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styles from './NameSelect.module.scss';

export const NameSelect = () => {
  const {
    showLoader,
    // setShowLoader,
    students,
    // setStudents,
    currentStudentId,
    setCurrentStudentId,
    currentStudent,
    setCurrentStudent,
  } = useContext(AppContext);

  // console.log('students', students);
  const [mouseOverStudentId, setMouseOverStudentId] = useState(null);

  const handleStudentMouseOver = useCallback(
    (studentId) => () => setMouseOverStudentId(studentId),
    []
  );
  const handleSchoolMouseOut = useCallback(() => setMouseOverStudentId(null), []);

  const handleChooseStudent = (studentId) => () => {
    students.find((stud) => stud.id === studentId);
    setCurrentStudentId(studentId);
    setCurrentStudent(students.find(({ Id }) => Id === currentStudentId));
  };

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <div className={styles.container} onMouseLeave={handleSchoolMouseOut}>
        <div className={styles.currentStudentName}>
          {currentStudent?.firstName ? currentStudent.firstName : students[0]?.firstName}{' '}
          {currentStudent?.lastName ? currentStudent.lastName : students[0]?.lastName}
        </div>
        <div className={styles.optionsContainer}>
          {students.map(({ id, firstName, lastName }) => (
            <div
              key={id}
              onClick={handleChooseStudent(id)}
              onMouseOver={handleStudentMouseOver(id)}
              className={styles.option}
            >
              {firstName} {lastName}
            </div>
          ))}
        </div>
        <ArrowDropDownIcon color="disabled" fontSize="large" className={styles.arrowDown} />
      </div>
    </>
  );
};


