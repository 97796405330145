import React from 'react'
import { Pagination } from '../../../../components/Pagination/Pagination';
import { Table } from '../../../../components/Table/Table';
import { TableHeadLine } from '../../../../components/TableHeadLine/TableHeadLine';
import './AllTestsAnalyticsTable.scss'

const typeTableRow = {
  tableFor: 'allTestsAnalytics',
  tableCellWidth: `300px 130px 300px 90px 130px`,
  tableWidth: '1176px',
  gridGap: '23px',
  rowPaddingLeft: '0px',
  height: '32px',
  boxShadow: 'none',
  fontSize: '1.6rem',
};

const AllTestsAnalyticsTable = ({
  tableDataStudents,
  filterAverageScore,
  filterAverageSpeed,
  setFilterAverageScore,
  setFilterAverageSpeed,
  sortFunctionForName,
  sortFunctionForScore,
  sortFunctionForSpeed,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  totalCount,
  setTotalCount,
  sortName,
  sortDirection,
}) => {

  // console.log('filterAverageScore', filterAverageScore)
  // console.log('filterAverageSpeed', filterAverageSpeed)
    
  const headerForAvScore =
    filterAverageScore === '0-49' // 'below50'
      ? ' below 50% accuracy'
      : filterAverageScore === '50-79' // 'from50to70'
      ? ' 50 - 79% accuracy'
      : filterAverageScore === '80-89' // 'from70to90'
      ? ' 80 - 89% accuracy'
      : filterAverageScore === '90-100' // 'from90to100'
      ? ' 90%+ accuracy'
      : null;
  const headerForSpeed =
    filterAverageSpeed === -1 // 'belowExpected'
      ? 'Below expected speed  '
      : filterAverageSpeed === 0 // 'asExpected'
      ? ' As expected speed '
      : filterAverageSpeed === 1 // 'aboveExpected'
      ? 'Above expected speed'
      : null;
  const headerColorForAvScore =
    filterAverageScore === '0-49' // 'below50'
      ? '#FB463B'
      : filterAverageScore === '50-79' // 'from50to70'
      ? '#E2B534'
      : filterAverageScore === '80-89' // 'from70to90'
      ? '#0F9D58'
      : filterAverageScore === '90-100' // 'from90to100'
      ? '#4285F4'
      : null;
  const headerColorForAvSpeed =
    filterAverageSpeed === -1 // 'belowExpected'
      ? '#FB463B'
      : filterAverageSpeed === 0 // 'asExpected'
      ? '#0F9D58'
      : filterAverageSpeed === 1 // 'aboveExpected'
      ? '#4285F4'
      : null;

  const showHeadLine = filterAverageSpeed || filterAverageScore ? true : false;

  const headlineType = {
    titleName: 'All tests',
    speedTitle: headerForSpeed,
    speedColor: headerColorForAvSpeed,
    scoreTitle: headerForAvScore,
    scoreColor: headerColorForAvScore,
    isInput: false,
    isExportButton: false,
    isExportCsv: false,
    resetButton: () => {
      setFilterAverageScore(null);
      setFilterAverageSpeed(null);
    },
  };

  const tableHeadForTestsAnalitics = [
    {
      fieldName: 'Label',
      width: '300px',
      sortByLetters: false,
      sortByNum: false,
      sortByLetters: true,
      functionality: sortFunctionForName,
    },
    {
      fieldName: 'Concept',
      width: '130px',
      sortByLetters: false,
      sortByNum: false,
    },
    {
      fieldName: 'Teachers',
      width: '300px',
      sortByLetters: false,
      sortByNum: false,
    },
    {
      fieldName: 'Score',
      width: '90px',
      sortByLetters: false,
      sortByNum: false,
      sortByScore: true,
      functionality: sortFunctionForScore,
    },
    {
      fieldName: 'Speed',
      width: '130px',
      sortByLetters: false,
      sortByNum: false,
      sortByStatus: true,
      functionality: sortFunctionForSpeed,
    },
  ];


  return (
    <div>
       {showHeadLine ?
        <TableHeadLine headlineType={headlineType} />
         : 
      <div className='allTestsAnalyticsTableTitle'>
        ALL TESTS
      </div>
        } 
      {/* {showHeadLine && <TableHeadLine headlineType={headlineType} />} */}
      {/* <TableHeadLine headlineType={headlineType} /> */}
      <div className="TableForAnalyticPage_wrapper">
        <Table
          typeTableHead={tableHeadForTestsAnalitics}
          typeTableRow={'allTestsAnalytics'}
          tableRowData={tableDataStudents}
          sortName={sortName}
          sortDirection={sortDirection}
          forPage={'allTestsAnalytics'}
        />
        <Pagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
        />
      </div>
    </div>
  );
}

export default AllTestsAnalyticsTable