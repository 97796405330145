import noStudentsImg from '../../../images/noStudentsImg.png';
import roundBGImage from '../../../images/roundBGImage.png';
import arrowImage from '../../../images/arrow.svg';
import './NoStudentsDataClassGradebook.scss';

const NoStudentsDataClassGradebook = () => {
  return (
    <>
      <img
        className="noStudentsImage"
        src={noStudentsImg}
        width="144"
        height="239"
        alt="girl_reading"
      />
      <img
        className="roundBGImage"
        src={roundBGImage}
        width="331"
        height="317"
        alt="round"
      />
      <img
        className="noTestsArrow"
        src={arrowImage}
        width="266"
        height="148"
        alt="Navigate arrow"
      />

      {/* second test column */}
      <div className="classGradeBook_NoStudentsData_gradeBook_tablecontainer">
        <div className="classGradeBook_NoStudentsData_gradeBook_table_Wrapper">
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_white"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_grey"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_white"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_grey"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_white"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_grey"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_white"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_grey"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_white"></p>
          <p className="classGradeBook_NoStudentsData_gradeBook_table_Row_grey"></p>
        </div>
      </div>
    </>
  );
};

export default NoStudentsDataClassGradebook;
