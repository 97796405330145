import React from 'react';
import ParentHeaderProfile from "../ParentHeaderProfile/ParentHeaderProfile";
import imgHeaderLogo from '../../../../images/LOGO-header.png';
import { WidthLimiter } from "../../../../components/WidthLimiter/WidthLimiter";
import { NameSelect } from '../NameSelect/NameSelect';
import { ParentSchoolSelect } from '../ParentSchoolSelect/ParentSchoolSelect';
import './ParentHeader.scss'

const ParentHeader = () => {

  return (
      <div className="headerWrapper">
      <WidthLimiter>
        <header className="header">
          <div className="headerLogo_wrapper">
            <img className="imgLogo" src={imgHeaderLogo} alt="" />
            <ParentSchoolSelect />
            <NameSelect />
          </div>
          <ParentHeaderProfile/>
        </header>
      </WidthLimiter>
    </div>
  );
}

export default ParentHeader;