import { forwardRef, useCallback, useState } from 'react';
import { Alert } from '../../../Alert/Alert';
import { ModalContainer } from '../../../Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../Modal/ModalHeader/ModalHeader';
import { ModalWrapper } from "../../../Modal/ModalWrapper/ModalWrapper";
import TestForm from '../TestForm';

const EditTestModal = forwardRef(({
  closeModal,
  callRerender,
  editData,
  callAlert,
  closeAlert
}, ref) => {
  const [loader, setLoader] = useState(false);
  // const [isAlert, setIsAlert] = useState({
  //   type: false,
  //   text: '',
  //   close: () => { },
  //   show: false,
  // });

  // const callAlert = useCallback((obj) =>
  //   setIsAlert(obj), []);
  // const closeAlert = useCallback(() => setIsAlert({
  //   type: false,
  //   text: '',
  //   close: () => { },
  //   show: false,
  // }), []);

  return (
    <>
      <ModalWrapper loader={loader}>
        <ModalContainer ref={ref}>
          <ModalHeader header="Edit Test" onClose={closeModal} />
          <TestForm
            closeModal={closeModal}
            callAlert={callAlert}
            closeAlert={closeAlert}
            callRerender={callRerender}
            setLoader={setLoader}
            isEditForm={true}
            editData={editData}
          />
        </ModalContainer>
      </ModalWrapper>
      {/* {isAlert.show &&
        <Alert
          alertType={isAlert.type}
          text={isAlert.text}
          close={isAlert.close}
        />} */}
    </>
  )
});

export default EditTestModal;
