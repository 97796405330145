import { useCallback, useState } from 'react';
import ParentHeaderProfilePhoto from './ParentHeaderProfilePhoto/ParentHeaderProfilePhoto';
import ParentHeaderProfilePopover from './ParentHeaderProfilePopover/ParentHeaderProfilePopover';
import ParentEditProfileModal from './ParentEditProfileModal/ParentEditProfileModal';

const ParentHeaderProfile = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [modalParentEditProfileOpen, setModalParentEditProfileOpen] = useState(false);

  // === for ParentHeaderProfilePopover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // console.log(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // === for ParentEditProfileModal

  const handleParentEditProfileModalOpen = useCallback(() => {
    setAnchorEl(null);
    setModalParentEditProfileOpen(true);
    // console.log('handleEditProfileModalOpen')
  }, []);

  const handleParentEditProfileModalClose = useCallback(
    () => setModalParentEditProfileOpen(false),
    []
  );

  return (
    <>
      <ParentHeaderProfilePhoto handleClick={handleClick} />
      <ParentHeaderProfilePopover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleParentEditProfileModalOpen={handleParentEditProfileModalOpen}
      />
      {modalParentEditProfileOpen && (
        <ParentEditProfileModal onClose={handleParentEditProfileModalClose} />
      )}
    </>
  );
};

export default ParentHeaderProfile;
