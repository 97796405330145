import React, { forwardRef, PropsWithChildren } from 'react';
import styles from './ModalContainer.module.scss';

export const ModalContainer = forwardRef<HTMLDivElement, PropsWithChildren<{}>>(
  ({ children }, ref) => (
    <div
      ref={ref}
      className={styles.container}
    >
      {children}
    </div>
  )
);
