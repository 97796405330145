import { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import ButtonText from '../../../../../components/buttons/buttonText/ButtonText';
import { ModalWrapper } from '../../../../../components/Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../components/Modal/ModalHeader/ModalHeader';
import { ModalFooter } from '../../../../../components/Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../../../../components/Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { LoaderFullBook } from '../../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { SuccessfulEditClass } from './SuccessfulEditClass/SuccessfulEditClass';
import { OutlinedTextField } from '../../../../../components/OutlinedTextField/OutlinedTextField';
import { AppContext } from '../../../../../context/AppContext';
import { config } from '../../../../../config';
import { Box } from '@material-ui/core';
import { Alert } from '../../../../../components/Alert/Alert';
import { ArchiveClassModal } from '../ArchiveClassModal/ArchiveClassModal';
import './EditClassModal.scss';

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxIcon fontSize="medium" style={{ color: '#A6192E' }} />;

const gradeToSelectDefault = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const arrOfYearsToSelect = [];
const currentYear = new Date().getFullYear();
let currentYearPlusTwo = currentYear + 2;
for (let i = 0; i < 11; i++) {
  arrOfYearsToSelect.push(currentYearPlusTwo);
  currentYearPlusTwo -= 1;
}

export const EditClassModal = ({
  close,
  // classInfo,
  loader,
  loadDataForGradeBook,
  callRerender = () => {},
}) => {
  // console.log('classInfo', classInfo)
  const { showLoader, setShowLoader, classInfo, setClassInfo, currentSchoolId, currentClassId } =
    useContext(AppContext);
  console.log('classInfo', classInfo)

  const [teacherName, setTeacherName] = useState([]); // all teachers in school
  // const [ gradeToSelect, setGradeToSelect ] = useState( gradeToSelectDefault ) // get from server
  const [grade, setGrade] = useState(classInfo?.grade ? classInfo?.grade : classInfo?.class);

  const [newClassName, setNewClassName] = useState(classInfo?.className);
  const [selectedTeachers, setSelectedTeachers] = useState(
    classInfo?.teachers
      ? classInfo?.teachers?.reduce(
        (sum, currentTeacher) => [
          ...sum,
          { title: currentTeacher?.name, value: currentTeacher?.teacherId },
        ],
        []
      )
      : 0
  );
  const [year, setYear] = useState(classInfo?.year);
  // const [showSelectTeacher, setShowSelectTeacher] = useState(false);

  const [successfulEditClass, setSuccessfulEditClass] = useState(null);

  // console.log('selectedTeachers', selectedTeachers);
  const [newTeachersIdArr, setNewTeachersIdArr] = useState([]); // в запрос нужен TeachersId
  useEffect(() => {
    const newArr = [];
    if (selectedTeachers !== 0) {
      selectedTeachers?.forEach((item) => {
        newArr.push(item.value);
      });
    }
    setNewTeachersIdArr(newArr)
  }, [selectedTeachers])

  useEffect(() => {
    console.log(newTeachersIdArr)
  }, [newTeachersIdArr])
  // console.log('newTeachersIdArr', newTeachersIdArr);

  //ALERT
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { },
    show: false,
  });

  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(
    () =>
      setIsAlert({
        type: false,
        text: '',
        close: () => { },
        show: false,
      }),
    []
  );

  // ===== SET GRADE =====
  const handleChangeGrade = (event) => {
    setErrorInGrade(false);
    setGrade(event.target.value);
  };

  // ===== LOAD TEACHERS =====
  function loadTeachers() {
    setShowLoader(true);
    fetch(`${config.API_URL}/api/header/info?schoolId=${currentSchoolId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        credentials: 'include',
      },
    })
      .then(async (res) => await res.json())
      .then((json) => {
        const toSetTeachers = json?.teachers?.filter(item => item.isArchive !== 1)
        .reduce(
          (sum, currentTeacher) => [
            ...sum,
            { title: currentTeacher.teacherName, value: currentTeacher.teacherId },
          ],
          []
        );
        setTeacherName(toSetTeachers);
        setShowLoader(false);
      });
  }

  useEffect(() => {
    loadTeachers();
  }, []);

  const defaultValueTeacher = [];
  if (teacherName.length > 0) {
    for (let i = 0; i < teacherName.length; i++) {
      for (let k = 0; k < selectedTeachers.length; k++) {
        if (teacherName[i].value === selectedTeachers[k].value) {
          defaultValueTeacher.push(i);
        }
      }
    }
  }
  // console.log('defaultValueTeacher', defaultValueTeacher);

  // FOR HANDLING ERRORS
  const [errorInGrade, setErrorInGrade] = useState();
  const [errorInClassName, setErrorInClassName] = useState();
  const [errorInTeacher, setErrorInTeacher] = useState();
  const [errorInYear, setErrorInYear] = useState();

  // ===== HANDLE send New CHANGES =====
  const handleSaveClassChanges = async () => {
    if (!newClassName) {
      setErrorInClassName(true);
    }
    if (selectedTeachers.length < 1) {
      setErrorInTeacher(true);
      callAlert({
        type: false,
        text: 'Teacher name is required.',
        close: closeAlert,
        show: true,
      });
    }
    if (!newClassName || selectedTeachers.length < 1) {
      callAlert({
        type: false,
        text: 'Teacher name is required.',
        close: closeAlert,
        show: true,
      });
      return;
    }
    const validateClassName = newClassName.toUpperCase().trim();

    // ===== REQUEST =====
    const requestBody = {
      id: classInfo.classId,
      schoolId: currentSchoolId,
      grade: grade,
      className: validateClassName,
      teachers: newTeachersIdArr,
      year: year,
    };
    console.log('requestBody', requestBody);

    try {
      if (requestBody.teachers === []) {
        callAlert({
          type: false,
          text: 'Teacher name is required.',
          close: closeAlert,
          show: true,
        });
        return;
      }
      setShowLoader(true);
      const response = await axios.patch(`${config.API_URL}/api/gradebook/`, requestBody);
      if (response.data) {
        console.log('response.data', response.data);
        setShowLoader(false);
        setClassInfo((prev) => {
          const classToUpdate = { ...prev };
          classToUpdate.className = validateClassName;
          classToUpdate.grade = grade;
          classToUpdate.teachers = newTeachersIdArr;
          classToUpdate.year = year;
          return classToUpdate;
        });
        setSuccessfulEditClass(true);
        loadDataForGradeBook();
        callRerender();
      }
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        setShowLoader(false);
        callAlert({
          type: false,
          text: 'Class with this params already exist.',
          close: closeAlert,
          show: true,
        });
        return;
      }
      close();
      setShowLoader(false);
      setSuccessfulEditClass(false);
      console.log(e);
    }
  };

  // ===== SET NEW TEACHERS =====
  const handleChangeTeacher =
    // (event) => {
    // const { target: { value } } = event;
    // setSelectedTeachers(typeof value === 'string' ? value.split(',') : value);
    (event, newValue) => setSelectedTeachers(newValue);

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  // ===== Archive Class Modal =====
  const [archiveClassModal, setArchiveClassModal] = useState(false);
  const handleOpenArchiveClassModal = () => setArchiveClassModal(true);
  const handleCloseArchiveClassModal = () => setArchiveClassModal(false);

  // to archive: payload should be an array of Ids
  const thisClassToActiveClasses = [];
  thisClassToActiveClasses.push(currentClassId);
  console.log('thisClassToActiveClasses', thisClassToActiveClasses);

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <ModalWrapper loader={loader}>
        <ModalContainer>
          <ModalHeader header="Edit Class" onClose={close} />

          {/* SELECT NEW GRADE */}
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '302px', marginTop: 10 },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <OutlinedTextField
                fullWidth
                select
                label="Select Grade"
                // defaultValue={`${classInfo.class}${classInfo.className}`}
                value={grade}
                onChange={handleChangeGrade}
                onFocus={() => {
                  setErrorInGrade(false);
                  // setErrorClassIsExist(false);
                }}
              >
                {gradeToSelectDefault?.map((grade) => {
                  return <MenuItem value={grade}>{grade} Grade</MenuItem>;
                })}
              </OutlinedTextField>
            </div>
          </Box>

          {/* ENTER CLASS NAME */}
          <div className="inputWrapper">
            <div
              style={{
                marginTop: 25,
                border: errorInClassName ? '1px solid #A6192E' : null,
              }}
              className="editClassModal_editClassNameWrapper"
            >
              <OutlinedTextField
                fullWidth
                // id="outlined-basic"
                label="Enter Class Name"
                // variant="outlined"
                // error
                // defaultValue={classInfo?.className}
                value={newClassName}
                onChange={(e) => {
                  setNewClassName(e.target.value);
                  setErrorInClassName(false);
                }}
              />
            </div>
            {errorInClassName && (
              <div className="editClassModal_errorWrapper textHelper" style={{ bottom: 5 }}>
                Class name is required{' '}
                <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
              </div>
            )}
          </div>

          {/* ENTER TEACHER */}
          <div className="inputWrapper">
            <div
              className="editClassModal_editTeacherNameWrapper"
              style={{
                marginTop: 25,
                border: errorInTeacher ? '1px solid #A6192E' : null,
                borderRadius: 0,
                // height: 56,
              }}
            >
              <Autocomplete
                // defaultValue={defaultValueTeacher.reduce(
                //   (sum, number) => [...sum, teacherName[number]],
                //   []
                // )}
                // defaultValue = {teacherName[0]}
                defaultValue={selectedTeachers}
                onChange={handleChangeTeacher}
                limitTags={1}
                multiple
                id="checkboxes-tags-demo"
                options={teacherName}
                onFocus={() => {
                  setErrorInTeacher(false);
                }}
                ListboxProps={{ style: { maxHeight: '120px' }, position: 'bottom-start' }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.title}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ fontSize: 16 }}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8, height: 25 }}
                      checked={selected}
                    />
                    {option.title}
                  </li>
                )}
                style={{ width: 302 }}
                renderInput={(params) => (
                  <div
                    className="selectWrapper"
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <OutlinedTextField
                      {...params}
                      label="Select Teacher"
                      placeholder="Select Teacher"
                      style={{ fontSize: 16 }}
                    />
                    <div className="textHelper" style={{ bottom: 5 }}>
                      {errorInTeacher && (
                        <>
                          <p>Teacher Name is required</p>
                          <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
                        </>
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>

          {/* SELECT YEAR */}
          <div
            className="editClassModal_editYearNameWrapper"
            style={{
              border: errorInYear ? '1px solid #A6192E' : null,
              borderRadius: 0,
              height: 56,
              marginTop: 25,
            }}
          >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '302px' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <OutlinedTextField
                  fullWidth
                  select
                  label="Select Year"
                  // defaultValue={classInfo?.year}
                  value={year}
                  onFocus={() => {
                    setErrorInYear(false);
                  }}
                  onChange={handleChangeYear}
                >
                  {arrOfYearsToSelect.map((year) => {
                    return <MenuItem value={year}>{year}</MenuItem>;
                  })}
                </OutlinedTextField>
              </div>
            </Box>
          </div>

          {errorInYear && (
            <div className="textHelper year" style={{ bottom: 20 }}>
              Year is required{' '}
              <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
            </div>
          )}

          <div className="editClassModal_archiveButtonWrapper">
            <ButtonText
              type={`3`}
              text={` Archive`}
              iconName={`ArchiveTwoTone`}
              active={true}
              funtionality={handleOpenArchiveClassModal}
            />
          </div>
          {archiveClassModal && (
            <ArchiveClassModal
              onClose={handleCloseArchiveClassModal}
              activeClasses={thisClassToActiveClasses}
              loadDataForGradeBook={loadDataForGradeBook}
              closeEditModal={close}
            />
          )}

          <ModalFooter>
            <ModalSecondaryButton onClick={close}>Cancel</ModalSecondaryButton>
            <ModalPrimaryButton typeBtn="submit" onClick={handleSaveClassChanges}>
              Edit
            </ModalPrimaryButton>
          </ModalFooter>
        </ModalContainer>
      </ModalWrapper>

      {successfulEditClass !== null && (
        <SuccessfulEditClass
          successfulEditClass={successfulEditClass}
          close={close}
          loader={loader}
          setSuccessfulEditClass={setSuccessfulEditClass}
        // loadDataForGradeBook={loadDataForGradeBook}
        // successfulAddClass={successfulAddClass}
        />
      )}
      {isAlert.show && <Alert alertType={isAlert.type} text={isAlert.text} close={isAlert.close} />}
    </>
  );
};
