import { TableHead } from '../TableHead';
import { TableRowStudent } from '../TableRowStudents/TableRowStudents';
import { TableRowTeachers } from '../TableRowTeachers/TableRowTeachers';
import { TableRowStudentAnalytics } from '../TableRowStudentAnalytics/TableRowStudentAnalytics';
import { responseDataStudents } from '../../ObjectsForTable';
import { TableRowAllTestsAnalytics } from '../TableRowAllTestsAnalytics/TableRowAllTestsAnalytics';

const propsToTableStudents = 'students';

const tableHeadForStudents = [
  {
    fieldName: 'checkBox',
    width: '20px',
    sortByLetters: false,
    sortByScore: false,
    sortByStatus: false,
  },
  {
    fieldName: 'Name',
    width: '300px',
    sortByLetters: true,
    sortByScore: false,
    sortByStatus: false,
  },
  {
    fieldName: 'ID',
    width: '96px',
    sortByLetters: false,
    sortByScore: false,
    sortByStatus: false,
  },
  {
    fieldName: 'Class',
    width: '41px',
    sortByLetters: false,
    sortByScore: false,
    sortByStatus: false,
  },
  {
    fieldName: 'Av. score, %',
    width: '103px',
    sortByScore: true,
    sortByStatus: false,
    sortByLetters: false,
  },
  {
    fieldName: 'Av. speed',
    width: '118px',
    sortByScore: false,
    sortByStatus: true,
    sortByLetters: false,
  },
  {
    fieldName: 'Parents',
    width: '400px',
    sortByLetters: false,
    sortByScore: false,
    sortByStatus: false,
  },
  {
    fieldName: 'Actions',
    width: '105px',
    sortByLetters: false,
    sortByScore: false,
    sortByStatus: false,
  },
];

// =====================================================================
// const tableHeadForTeachers = [
//   {
//     fieldName: 'checkBox',
//     width: '16px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Photo',
//     width: '40px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Name',
//     width: '300px',
//     sortByLetters: true,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Av. score, %',
//     width: '100px',
//     sortByNum: true,
//     sortByLetters: false,
//   },
//   {
//     fieldName: 'Speed',
//     width: '112px',
//     sortByNum: true,
//     sortByLetters: false,
//   },
//   {
//     fieldName: 'Phone',
//     width: '170px',
//     sortByNum: false,
//     sortByLetters: false,
//   },
//   {
//     fieldName: 'Email',
//     width: '200px',
//     sortByNum: false,
//     sortByLetters: false,
//   },
//   {
//     fieldName: 'Classes',
//     width: '88px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Actions',
//     width: '122px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
// ];

// =====================================================================
// const tableHeadForTestsAnalitics = [
//   {
//     fieldName: 'Label',
//     width: '240px',
//     sortByLetters: true,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Concept',
//     width: '96px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Status',
//     width: '78px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Teachers',
//     width: '240px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Date',
//     width: '88px',
//     sortByLetters: false,
//     sortByNum: true,
//   },
//   {
//     fieldName: 'Av. Score',
//     width: '80px',
//     sortByLetters: false,
//     sortByNum: true,
//   },
//   {
//     fieldName: 'Av. Speed',
//     width: '104px',
//     sortByLetters: false,
//     sortByNum: true,
//   },
// ];

// =====================================================================

// const tableHeadForStudentsInfo = [
//   {
//     fieldName: '#',
//     width: '22px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Test Label',
//     width: '300px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Score',
//     width: '48px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Speed',
//     width: '113px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Total Q-ns',
//     width: '68px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Exp. speed',
//     width: '119px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Concept',
//     width: '152px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
//   {
//     fieldName: 'Date',
//     width: '112px',
//     sortByLetters: false,
//     sortByNum: true,
//   },
//   {
//     fieldName: 'Absent',
//     width: '48px',
//     sortByLetters: false,
//     sortByNum: false,
//   },
// ];
// const TableType = {
//     tableFor: 'studentsDetails',
//     tableCellWidth: `16px 300px 96px 40px 100px 112px 384px 104px`,
//     tableWidth: '1078px'
// }

// typeTableHead = header info(cells)

// tableType : tableFor, totalWidth, tableCellWidth

// =====================================================================

const tableTypes = [
  {
    tableFor: 'students',
    tableCellWidth: `16px 300px 96px 100px 100px 112px 324px 104px`,
    tableWidth: '1360px',
  },
  {
    tableFor: 'teachers',
    tableCellWidth: `16px 40px 300px 100px 112px 170px 200px 88px 52px`,
    tableWidth: '1360px',
    height: '56px'
  },
  {
    tableFor: 'studentsDetails',
    tableCellWidth: `22px 300px 48px 113px 68px 119px 152px 112px 48px`,
    tableWidth: '1102px',
  },
  {
    tableFor: 'testsAnalytics',
    tableCellWidth: `240px 96px 78px 240px 88px 80px 104px `,
    tableWidth: '1144px',
  },
  {
    tableFor: 'allTestsAnalytics',
    tableCellWidth: `300px 130px 300px 90px 130px`,
    tableWidth: '1176px',
  }
];

export const Table = ({
  typeTableHead = tableHeadForStudents,
  typeTableRow = propsToTableStudents,
  tableRowData = responseDataStudents,
  forPage,
  sortName,
  openModal,
  openModalEdit,
  openSelectMenu,
  selectedTeacher,
  selectedStudent,
  selectAll,
  sortByStatus,
  statusSortDir,
  fillDefaultEditValues,
  openModalUnArchive,
  selectedTeacherType,
  selectedStudentType,
  isSelectAll,
  callAlert,
  letterSortDir,
  scoreSortDir,
  closeAlert,
  sortByLetter,
  sortByScore,
  sortDirection,
  setIsSelectedAll,
}) => {
  let tableType;

  for (let i = 0; i < tableTypes.length; i++) {
    if (typeTableRow === tableTypes[i].tableFor) {
      tableType = tableTypes[i];
    }
  }

  // console.log('typeTableRow', typeTableRow);
  // console.log('tableType', tableType);
  // console.log('tableType.tableFor', tableType.tableFor);

  return (
    <div>
      <TableHead
        isSelectAll={isSelectAll}
        sortName={sortName}
        sortDirection={sortDirection}
        sortByStatusFunc={sortByStatus}
        statusSortDir={statusSortDir}
        scoreSortDir={scoreSortDir}
        letterSortDir={letterSortDir}
        sortByScoreFunc={sortByScore}
        sortByLetter={sortByLetter}
        typeTableHead={typeTableHead}
        typeTableRow={tableType}
        forPage={forPage}
        selectAll={selectAll}
        selectedTeacherType={selectedTeacherType}
        selectedStudentType={selectedStudentType}
        selectedTeacher={selectedTeacher}
        selectedStudent={selectedStudent}
      />
      {tableType.tableFor === 'students' ? (
        <div className="">
          {tableRowData.map((rowData, index) => {
            return (
              <TableRowStudent
                fillDefaultEditValues={fillDefaultEditValues}
                openModalEdit={openModalEdit}
                openModalUnArchive={openModalUnArchive}
                selectedStudentType={selectedStudentType}
                setIsSelectedAll={setIsSelectedAll}
                key={rowData.studentId}
                typeTableRow={tableType}
                student={rowData}
                index={index}
                forPage={forPage}
                isSelectAll={isSelectAll}
                openSelectMenu={openSelectMenu}
                selectAll={selectAll}
                selectedStudent={selectedStudent}
                dataLength={tableRowData.length}
                callAlert={callAlert}
                closeAlert={closeAlert}
              />
            );
          })}
        </div>
      ) : tableType.tableFor === 'testsAnalytics' ? (
        <div className="">
          {tableRowData.map((rowData, index) => {
            return (
              <TableRowStudentAnalytics
                key={rowData.id}
                typeTableRow={tableType}
                test={rowData}
                index={index}
                forPage={forPage}
              />
            );
          })}
        </div>
      ) : tableType.tableFor === 'allTestsAnalytics' ? (
        <div className="">
          {tableRowData.map((rowData, index) => {
            return (
              <TableRowAllTestsAnalytics
                key={index}
                typeTableRow={tableType}
                test={rowData}
                index={index}
                forPage={forPage}
              />
            );
          })}
        </div>
      ) : (
        <div className="">
          {tableRowData.map((rowData, index) => {
            return (
              <TableRowTeachers
                key={rowData.teacherId}
                typeTableRow={tableType}
                teacher={rowData}
                index={index}
                openModal={openModal}
                fillDefaultEditValues={fillDefaultEditValues}
                openSelectMenu={openSelectMenu}
                selectedTeacher={selectedTeacher}
                openModalUnArchive={openModalUnArchive}
                selectedTeacherType={selectedTeacherType}
                isSelectAll={isSelectAll}
                callAlert={callAlert}
                setIsSelectedAll={setIsSelectedAll}
                closeAlert={closeAlert}
                dataLength={tableRowData.length}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
