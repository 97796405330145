import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { ArchiveService } from '../../../pages/ArchivePage/ArchiveService';
import { ModalWrapper } from '../../Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../Modal/ModalHeader/ModalHeader';
import ArchiveCsvConstructor from './ArchiveCsvConstructor';

const service = new ArchiveService();

function ArchiveExportCsvModal({ callAlert, closeAlert, closeModal }) {
  const [archiveData, setArchiveData] = useState({});
  const [loader, setLoader] = useState(true);

  //get SchoolId
  const { currentSchoolId } = useContext(AppContext);

  // useEffect for Data
  useEffect(() => {
    setLoader(true);
    const getData = () => {
      service
        .getArchive(currentSchoolId)
        .then((response) => {
          setArchiveData(response);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          callAlert({
            type: false,
            text: 'Ooops, server error.' + ' Please, contact the admin of the website.',
            close: closeAlert,
            show: true,
          });
        });
    };
    if (currentSchoolId !== null) {
      // to avoid double rendering
      getData();
    }
    return () => setArchiveData({});
  }, [callAlert, closeAlert, currentSchoolId]);

  return (
    <>
      <ModalWrapper loader={loader}>
        <ModalContainer>
          <ModalHeader header="Export CSV" onClose={closeModal} />
          <ArchiveCsvConstructor
            archiveData={archiveData}
            callAlert={callAlert}
            closeAlert={closeAlert}
            closeModal={closeModal}
            setLoader={setLoader}
          />
        </ModalContainer>
      </ModalWrapper>
    </>
  );
}

export default ArchiveExportCsvModal;
