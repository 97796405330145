import React, { useState } from 'react';
import { ModalWrapper } from '../../../Modal/ModalWrapper/ModalWrapper';
import { ModalHeader } from '../../../Modal/ModalHeader/ModalHeader';
import { ModalFooter } from '../../../Modal/ModalFooter/ModalFooter';
import { ModalPrimaryButton } from '../../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { ModalSecondaryButton } from '../../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { Controller, useForm } from 'react-hook-form';
import { OutlinedTextField } from '../../../OutlinedTextField/OutlinedTextField';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { editSchool } from '../../../../services/schools';
import styles from './EditSchoolModal.module.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLocation, useHistory } from 'react-router-dom';

const EditSchoolModal = ({
  schools,
  onClose,
  currentSchool,
  setShowLoader,
  updateSchools,
  setCurrentSchoolId,
  mouseOverSchoolId,
  setUpdateSchoolInfo,
  callAlert,
  closeAlert,
}) => {
  let location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search)

  const defaultSchoolToEdit = {
    Id: currentSchool?.Id,
    Name: currentSchool?.Name,
    Address: currentSchool?.Address,
    ClassCount: currentSchool?.ClassCount,
    TeacherCount: currentSchool?.TeacherCount,
    TestCount: currentSchool?.TestCount,
    StudentCount: currentSchool?.StudentCount,
  }
  const [editedSchool, setEditedSchool] = useState(defaultSchoolToEdit);
  
  console.log('currentSchool', currentSchool);
  console.log('editedSchool', editedSchool);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });

  const schoolToEditBodyRequest = {
    id: currentSchool.Id,
    name: editedSchool?.Name ?? currentSchool?.Name,
    address: editedSchool?.Address ?? currentSchool?.Address,
  };

  const onSubmit = async () => {
    console.log('currentSchool', currentSchool);
    console.log('editedSchool', editedSchool);
    console.log('editedSchool.Name', editedSchool.Name);
    console.log('editedSchool.Address', editedSchool.Address);
    console.log('schoolToEditBodyRequest', schoolToEditBodyRequest);

    if (!editedSchool.Name || editedSchool.Name === undefined) {
      callAlert({
        type: false,
        text: 'Enter School Name',
        close: closeAlert,
        show: true,
      });
      return;
    }
    else if (
      schools.find((school) => school.Name === editedSchool.Name) &&
      schools.find((school) => school.Address === editedSchool.Address)
    ) {
      callAlert({
        type: false,
        text: 'This school name or address already exists.',
        close: closeAlert,
        show: true,
      });
      return;
    } 
    setShowLoader(true);
    editSchool(schoolToEditBodyRequest)
      .then((response) => {
        // console.log("EditSchool-response", response);
        updateSchools((prevSchools) =>
          prevSchools.map((prevSchool) => {
            if (prevSchool.Id === schoolToEditBodyRequest.id) {
              const editedSchool = {
                Id: schoolToEditBodyRequest.id,
                Name: schoolToEditBodyRequest.name,
                Address: schoolToEditBodyRequest.address,
                ClassCount: prevSchool.ClassCount,
                TeacherCount: prevSchool.TeacherCount,
                TestCount: prevSchool.TestCount,
                StudentCount: prevSchool.StudentCount,
              };
              setCurrentSchoolId(currentSchool.Id);
              if (location.pathname === '/gradebook/class') {
                history.push('/gradebooks')
              }
              if (location.pathname === '/archive/class') {
                history.push('/archive')
              }
              if (location.pathname === '/analytics') {
                if (queryParams.has('classId')) {
                  queryParams.delete('classId')
                  queryParams.delete('class')
                  history.replace({
                    search: queryParams.toString(),
                  })
                }
              }
              localStorage.setItem('currentSchoolId', JSON.stringify(currentSchool.Id));
              setUpdateSchoolInfo((prev) => !prev);
              return editedSchool;
            } else {
              return prevSchool;
            }
          })
        );
        setShowLoader(false);
          callAlert({
            type: true,
            text: 'School successfully edited.',
            close: closeAlert,
            show: true,
          });
      })
      .catch(function (error) {
        console.log('error', error);
        setShowLoader(false);
                  callAlert({
            type: false,
            text: 'Something went wrong. Try again.',
            close: closeAlert,
            show: true,
          });
      });
    onClose();
  };

  const onChange = ({ target }) => {
    const { name, value } = target;
    setEditedSchool((prevState) => ({ ...prevState, [name]: value }));
    console.log('editedSchoolOnChange', editedSchool)
  };

  return (
    <ModalWrapper>
      <form className={styles.EditSchoolModal_wrapper} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader header="Edit School" onClose={onClose} />
        <div className={styles.inputWrapper}>
          <OutlinedTextField
            {...register('Name', {
              required: 'Required Field',
              minLength: 1,
            })}
            fullWidth
            label="School name"
            autoComplete="off"
            defaultValue={currentSchool?.Name}
            style={{ width: 302, margin: 0 }}
            onChange={onChange}
          />
          <div className={styles.textHelper}>
            {errors?.schoolName && (
              <>
                <span>{errors?.Name?.message || 'Required Field'}</span>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>
        <div className={styles.inputWrapper}>
          {/* <Controller
            control={control}
            name="Address"
            render={({ field }) => ( */}
          <OutlinedTextField
            {...register('Address')}
            fullWidth
            label="Address (optional)"
            autoComplete="off"
            defaultValue={currentSchool?.Address}
            // {...field}
            onChange={onChange}
            style={{ width: 302, margin: 0, marginTop: 26 }}
            error={Boolean(errors?.Address)}
            helperText={errors.Address?.message}
          />
          {/* )}
          /> */}
        </div>
        <ModalFooter>
          <ModalSecondaryButton onClick={onClose}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton onClick={onSubmit}>Save Changes</ModalPrimaryButton>
        </ModalFooter>
      </form>
    </ModalWrapper>
  );
};

export default EditSchoolModal;

// ===== SCHOOL ENDPOINTS TO BACKEND =====
// Update school
// base_url/api/school
// type PATCH
// request: {
// 	id: 0,
// 	name: '',
// 	address: ''
// }
