import { useState } from 'react'
import { ModalContainer } from '../Modal/ModalContainer/ModalContainer'
import { ModalHeader } from '../Modal/ModalHeader/ModalHeader'
import { ModalWrapper } from '../Modal/ModalWrapper/ModalWrapper'
import './SubmitTestModal.scss'
import SubmitTestForm from './SubmitTestsForm/SubmitTestForm'

export default function SubmitTestModal({ close, testsData, setIsAlert, setTableDataUpdated }) {
  const [loader, setLoader] = useState(false);

  return (
    <ModalWrapper loader={loader}>
      <ModalContainer>
        {testsData.length ? (
          <>
            <ModalHeader header="Submit Results" onClose={close} />
            <SubmitTestForm
              close={close}
              testsData={testsData}
              setLoader={setLoader}
              setIsAlert={setIsAlert}
              setTableDataUpdated={setTableDataUpdated}
            />
          </>
        ) : (
          <>
            <ModalHeader header="No Test Available" onClose={close} color="darkHeader" />
            <div className="noAvailableText">All student tests already with results</div>
            <button onClick={close} className="noAvailableBtn">
              OK
            </button>
          </>
        )}
      </ModalContainer>
    </ModalWrapper>
  );
}