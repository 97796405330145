import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import { OutlinedTextField } from '../OutlinedTextField/OutlinedTextField';
import CircularProgress from '@mui/material/CircularProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AppContext } from '../../context/AppContext';
import { config } from '../../config';
import './SelectParents.scss';
import { Controller } from 'react-hook-form';

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
const checkedIcon = <CheckBoxTwoToneIcon fontSize="medium" style={{ color: '#5B5B5B' }} />;

export default function SelectParents({ control, defaultClasses = [], setParentsCounter }) {
  const [serverError, setServerError] = React.useState(false);
  const { currentSchoolId } = React.useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const [loading, setLoading] = React.useState()

  React.useEffect(() => {
    setLoading(open && options.length === 0)
  }, [open, options.length])


  React.useEffect(() => {
    if (inputValue.length === 2) {
      setOpen(true);
    } else if (inputValue.length < 2) {
      setOpen(false);
    }
  }, [inputValue])

  const requestFetch = (url, method, body = null) => {
    const headers = {
      'Content-Type': 'application/json'
    }

    return fetch(url, {
      method: method,
      body: JSON.stringify(body),
      headers: headers
    }).then(response => {
      if (response.ok) {
        return response.json()
      }

      return response.json().then(error => {
        const e = new Error('Smth gone wrong')
        e.data = error
        throw e
      })
    });
  }

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      await requestFetch(`${config.API_URL}/api/student/parents`,
        'POST', { schoolId: currentSchoolId, value: inputValue })
        .then(data => {
          const newArr = [];

          data.forEach((item) => {
            const newObj = {};
            const title = `${item.FirstName} ${item.LastName}`;
            const id = item.Id;
            const email = item.Email;
            const phone = item.Phone;
            const firstName = item.FirstName;
            const lastName = item.LastName;

            newObj.title = title;
            newObj.id = id;
            newObj.email = email;
            newObj.phone = phone;
            newObj.firstName = firstName;
            newObj.lastName = lastName;

            newArr.push(newObj);
          });

          if (newArr.length === 0) {
            setLoading(false);
          } else {
            setOptions(newArr);
          }
        }).catch(() => { setLoading(false); setServerError(true) });
    })();
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Controller
      name="parents"
      control={control}
      render={({ field, formState }) => (
        <>
          <Autocomplete
            onChange={(_, data) => {
              setParentsCounter(data);
              return field.onChange(data)
            }}
            open={open}
            onOpen={() => { }}
            onClose={() => {
              setOpen(false);
            }}
            loading={loading}
            noOptionsText={serverError ? 'Oops, server error. Try again later' : 'No Options found'}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            ListboxProps={{ style: { maxHeight: '120px' }, position: "bottom-start" }}
            options={options}
            multiple
            limitTags={1}
            disableCloseOnSelect
            defaultValue={defaultClasses.length ? ((arr = defaultClasses) => {
              const newArr = [];

              arr.forEach((item) => {
                const newObj = {};

                const title = `${item.FirstName} ${item.LastName}`;
                const id = item.Id;
                const email = item.Email;
                const phone = item.Phone;
                const firstName = item.FirstName;
                const lastName = item.LastName;

                if (item?.id
                  && item?.title
                  && item?.email
                  && item?.phone
                  && item?.firstName
                  && item?.lastName) {
                  newArr.push(item);
                  return;
                }

                newObj.title = title;
                newObj.id = id;
                newObj.email = email;
                newObj.phone = phone;
                newObj.firstName = firstName;
                newObj.lastName = lastName;

                newArr.push(newObj);
              })

              field.onChange(newArr);
              return newArr;
            }) : []}
            getOptionLabel={(option) => option?.title ? option?.title : ''}
            renderOption={(props, option, { selected }) => (
              <li {...props}
                className={field?.value?.length === 2 ? 'disabled' : ''}
                style={{
                  fontSize: '14px',
                  height: 32,
                }}
                key={option.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <div className='selectWrapper'>
                <OutlinedTextField
                  {...params}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  label="Select Existing Parents"
                  inputRef={field.ref}
                  placeholder="Search"
                  InputProps={{
                    ...params.InputProps,
                    // required: !field.value,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={15} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
                <div className={'textHelper'}>
                  {formState?.errors?.parents?.message &&
                    (
                      <>
                        <p>{formState?.errors?.parents?.message}</p>
                        <InfoOutlinedIcon />
                      </>
                    )}
                </div>
              </div>
            )}
          />
        </>
      )}
    />
  );
}
