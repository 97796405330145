import React, { useState, useEffect, useCallback } from 'react';

import { Typography } from '@material-ui/core';
import styles from './StudentsFormFields.module.scss';
import { OutlinedTextField } from '../../../../../components/OutlinedTextField/OutlinedTextField';
import { DateTextField } from '../../../../../components/DateTextField/DateTextField';
import { SelectTypeahead } from '../../../../../components/SelectTypeahead/SelectTypeahead';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SelectClasses from '../../../../../components/SelectClasses/SelectClasses';
import { Controller } from 'react-hook-form';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import StudentsModalArchive from '../../StudentsModalArchive/StudentsModalArchive';

export const StudentsFormFields = ({

  register,
  errors,
  control,
  submitForm = () => { },
  close,
  defaultStudent,
  defaultClass,
  isEdit = false,
  getValues
}) => {
  console.log(defaultClass)
  const [startDate, setStartDate] = useState(getValues('birthDate') ? getValues('birthDate')
    : defaultStudent ? new Date(defaultStudent.birthday) : undefined);

  const [archiveModal, setArchiveModal] = useState(false);

  const openModalArchive = useCallback(() => setArchiveModal(true), []);
  const closeModalArchive = useCallback(() => setArchiveModal(false), [])


  const valuesArr = [];
  valuesArr.push(defaultStudent);
  // console.log(defaultStudent);

  return (
    <div className={styles.fieldSet}>
      <Typography className={styles.text} gutterBottom paragraph>
        1/2 Student personal Information
      </Typography>

      <div className={styles.inputWrapper}>
        <OutlinedTextField
          {...register('firstName', {
            required: 'Required Field',
          })}
          fullWidth
          defaultValue={defaultStudent ? defaultStudent?.firstName : ''}
          label="First Name"
          placeholder={'Enter First Name'}
          className={styles.formField}
        ></OutlinedTextField>

        <div className={styles.textHelper}>
          {errors?.firstName && (
            <>
              <p>{errors?.firstName?.message || 'Error!'}</p>
              <InfoOutlinedIcon />
            </>
          )}
        </div>
      </div>

      <div className={styles.inputWrapper}>
        <OutlinedTextField
          {...register('lastName', {
            required: 'Required Field',
          })}
          fullWidth
          defaultValue={defaultStudent ? defaultStudent?.lastName : ''}
          label="Last Name"
          placeholder={'Enter Last Name'}
          className={styles.formField}
        />

        <div className={styles.textHelper}>
          {errors?.lastName && (
            <>
              <p>{errors?.lastName?.message || 'Error!'}</p>
              <InfoOutlinedIcon />
            </>
          )}
        </div>
      </div>

      <Controller
        name="birthDate"
        control={control}
        rules={{
          required: 'Required Field',
        }}
        defaultValue={startDate}
        render={({ field, formState }) => (
          <div className={styles.inputWrapper}>
            <DateTextField
              fieldRef={field.ref}
              register={register}
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
                return field.onChange(date);
              }}
              inputLabel="Date of Birth"
              className={styles.formField}
            />

            <div className={styles.textHelper}>
              {formState?.errors?.birthDate && (
                <>
                  <p>{formState?.errors?.birthDate?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
            </div>
          </div>
        )}
      />

      <div className={styles.inputWrapper}>
        <OutlinedTextField
          {...register('studentId', {
            required: 'Required Field',
            maxLength: 11,
          })}
          fullWidth
          defaultValue={defaultStudent ? defaultStudent?.id : ''}
          label="Student ID"
          placeholder={'Enter Student ID'}
          className={styles.formField}
        />

        <div className={styles.textHelper}>
          {errors?.studentId && (
            <>
              <p>{errors?.studentId?.message || 'Enter less than 12 symbols'}</p>
              <InfoOutlinedIcon />
            </>
          )}
        </div>
      </div>

      <SelectClasses
        isMultiple={false}
        control={control}
        defaultClasses={
          defaultClass?.classId
            ? [{ title: defaultClass?.class, id: defaultClass?.classId }].flat()
            : getValues('classes')
            ? getValues('classes')
            : defaultStudent
            ? [{ title: defaultStudent?.class, id: defaultStudent?.classId }].flat()
            : []
        }
      />

      {isEdit && (
        <button type="button" className={styles.archiveBtn} onClick={openModalArchive}>
          <ArchiveOutlinedIcon />
          Archive Student
        </button>
      )}

      {archiveModal && (
        <StudentsModalArchive
          submitForm={submitForm}
          selectedStudent={valuesArr}
          close={closeModalArchive}
          closeModalEdit={close}
        />
      )}
    </div>
  );
};
