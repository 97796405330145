import React from 'react'
import "./StudentInfoList.scss"

const StudentInfoList = ({studentInfo}) => {
  function rounded (number){
      return +number.toFixed(1);
  }
const grade = studentInfo?.Grade || studentInfo?.Grade === 0 ? studentInfo?.Grade : '-';
const className = studentInfo?.ClassName ? studentInfo?.ClassName : '-';
const totalTests = studentInfo?.TotalTests ? studentInfo?.TotalTests : '-';
const averageScore = studentInfo?.avScore ? rounded(studentInfo?.avScore) : '-';
const averageSpeed = studentInfo?.avSpeed && studentInfo !== undefined ? studentInfo?.avSpeed : '-';
const id = studentInfo?.Id ? studentInfo?.Id : '-';
  
  return (
    <>
      <ul className="StudentInfoList">
        <li className="StudentInfoListItem">
          <b>{grade}</b>
          <span className="StudentInfoListItemName">Grade</span>
        </li>
        <li className="StudentInfoListItem">
          <b>{className}</b>
          <span className="StudentInfoListItemName">Class</span>
        </li>
        <li className="StudentInfoListItem">
          <b>{totalTests}</b>
          <span className="StudentInfoListItemName">Tests</span>
        </li>
        <li className="StudentInfoListItem">
          <b>{averageScore} %</b>
          <span className="StudentInfoListItemName">Av. Score</span>
        </li>
        <li className="StudentInfoListItem">
          <b>{averageSpeed}</b>
          <span className="StudentInfoListItemName">Av. Speed</span>
        </li>
        <li className="StudentInfoListItem">
          <b>{id}</b>
          <span className="StudentInfoListItemName">ID</span>
        </li>
      </ul>
    </>
  );
}

export default StudentInfoList