import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { TestsService } from '../../../../pages/TestsPage/TestsService';
import { AppContext } from '../../../../context/AppContext';
import { convertSecondsToString, convertStringTimeToSeconds } from '../../../../model';
import ErrorBoundary from '../../../ErrorBaundary';
import TestInputSelect from './TestInputSelect';
import { OutlinedTextField } from '../../../OutlinedTextField/OutlinedTextField';
import { ModalFooter } from '../../../Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import TestsModalForArchiving from '../../TestsModalForArchiving';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import styles from './TestForm.module.scss';
import { formatTestInput } from '../../../../model/formatTestInput';

const service = new TestsService();

function TestForm({
  defaultClass,
  closeModal,
  callAlert,
  closeAlert,
  callRerender,
  setLoader,
  isEditForm,
  editData = [],
  testsData = [],
  classInfo = {}
}) {
  const {
    id: testId,
    isArchive: testIsArchive,
    label: testLabel,
    concept: testConcept,
    classes,
  } = editData;

  const { currentSchoolId: schoolId } = useContext(AppContext);
  // for classes select
  const [loaderClasses, setLoaderClasses] = useState(false);
  const [classesData, setClassesData] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [openClasses, setOpenClasses] = useState(false);
  // for other fields
  const [openConcept, setOpenConcept] = useState(false);
  const [enteredSpeed, setEnteredSpeed] = useState(
    editData?.expectedSpeed ? convertSecondsToString(editData.expectedSpeed) : ''
  );
  const prevEnteredSpeed = useRef('');
  // for addTest Data
  const [label, setLabel] = useState(isEditForm ? testLabel : '');
  const [classesId, setClassesId] = useState([]);
  const [questions, setQuestions] = useState(
    editData?.totalQuestions ? editData.totalQuestions : ''
  );
  const [concept, setConcept] = useState(isEditForm ? testConcept : '');
  const [speed, setSpeed] = useState('');

  // for editTest variant modal
  const [modalArchive, setModalArchive] = useState(false);
  const openArchiveModal = useCallback(() => setModalArchive(true), []);
  const closeArchiveModal = useCallback(() => setModalArchive(false), []);

  const testClasses =
    typeof classes !== 'undefined'
      ? classes.map(({ id, grade, className }) => {
        return {
          id,
          className: `${grade}${className}`,
        };
      })
      : [];

  // console.log('classes', classes)
  // console.log('testClasses', testClasses);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  // if there are a request for concepts
  // const [loaderConcept, setLoaderConcept] = useState(false);
  const conceptsData = ['Arithmetic', 'Multiplication'];
  // for speed input field
  const speedPattern =
    // /^(?:([01]?\d|2[0-3])[a-zA-Z]+\s?)([0-5]?\d)[a-zA-Z]+\s?([0-5]?\d)[a-zA-Z]+\s?$/iu;
    /^(?:([01]?\d|2[0-3])[h]+\s?)([0-5]?\d)[m]+\s?([0-5]?\d)[s]+\s?$/iu;
  const speedRegisterOptions = {
    required: 'Required Field',
    pattern: {
      value: speedPattern,
      message: 'Speed format mismatch: XXh XXm XXs',
    },
  };

  // for Submit Success - close AddModal with delay (deleted after testing)
  // const delayedClosure = useCallback(() =>setTimeout(() =>closeModal(), 200), [closeModal]);

  // for get classes data request
  const getClasses = useCallback(() => {
    service
      .getAllClasses(schoolId)
      .then((response) => {
        const allClasses = response
          .map(({ Id, Grade, ClassName }) => {
            return {
              id: Id,
              className: `${Grade}${ClassName}`,
            };
          })
          .sort((a, b) => a - b);
        setClassesData(allClasses);
        setLoaderClasses(false);
      })
      .catch(() => {
        setLoaderClasses(false);
        callAlert({
          type: false,
          text: 'Ooops, server error.' + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
      });
  }, [callAlert, closeAlert, schoolId]);

  // for get classes
  useEffect(() => {
    if (openClasses) {
      setLoaderClasses(true);
      getClasses();
    }
  }, [getClasses, openClasses]);

  // for get classes ids to addTest request
  useEffect(() => {
    if (selectedClasses.length > 0) {
      const ids = selectedClasses.map(({ id }) => id);
      setClassesId(ids);
    }
    if (selectedClasses.length === 0) {
      setClassesId([]);
    }
  }, [selectedClasses]);

  // for get speed number to addTest request
  useEffect(() => {
    const splitForSpeed = /[a-zA-Z]+\s?/iu;
    if (enteredSpeed.length !== '') {
      const speedValue = convertStringTimeToSeconds(enteredSpeed, splitForSpeed);
      setSpeed(speedValue);
    }
    if (enteredSpeed.length === '') {
      setSpeed('');
    }
  }, [enteredSpeed]);

  // console.log('testsData', testsData);

  // ADD
  const onSubmitAddForm = () => {
    if (!classesId) {
      return;
    }
    const labelExist = testsData.find(test => test.label === label)
    const labelExistinADDbtn = testsData.find(test => test.testName === label)
    if (labelExist || labelExistinADDbtn) {
      callAlert({
        type: true,
        text: 'This test label already exists.',
        close: closeAlert,
        show: true,
      });
      return;
    }
    setLoader(true);
    service
      .addTest(label, questions, concept, speed, classesId)
      .then(() => {
        setLoader(false);
        reset();
        closeModal();
        callAlert({
          type: true,
          text: 'Operation Success!',
          close: closeAlert,
          show: true,
        });
        callRerender(true);
      })
      .catch(() => {
        setLoader(false);
        closeModal();
        callAlert({
          type: false,
          text: 'Ooops, server error.' + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
        callRerender(false);
      });
  };

  //EDIT
  const onSubmitEditForm = () => {
    const labelExist = testsData.find((test) => test.testName === testLabel);
    if (labelExist) {
      alert('label already exists');
      return;
    }
    setLoader(true);
    console.log('!!!edit test')
    service
      .updateTest(testId, label, questions, concept, speed, classesId)
      .then(() => {
        setLoader(false);
        reset();
        callAlert({
          type: true,
          text: 'Operation Success!',
          close: closeAlert,
          show: true,
        });
        console.log('!!!edit test finish');
        callRerender(true);
        closeModal();
      })
      .catch(() => {
        setLoader(false);
        callAlert({
          type: false,
          text: 'Ooops, server error.' + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
        callRerender(false);
      });
  };


  
  return (
    <form
      className={styles.form}
      onSubmit={isEditForm ? handleSubmit(onSubmitEditForm) : handleSubmit(onSubmitAddForm)}
    >
      {/* Test label - NAME*/}
      <ErrorBoundary>
        <div className={styles.inputWrapper}>
          <OutlinedTextField
            {...register('testLabel', {
              required: 'Required Field',
            })}
            className={styles.formField}
            label="Test Label"
            placeholder={'Enter Test Label'}
            defaultValue={isEditForm ? testLabel : ''}
            fullWidth
            onBlur={(e) => setLabel(e.target.value)}
          />

          <div className={styles.textHelper}>
            {errors?.testLabel && (
              <>
                <p>{errors?.testLabel?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>

        {/* Select class grade*/}
        <TestInputSelect
          defaultClass={defaultClass}
          className={styles.formField}
          control={control}
          name={'classes'}
          multiple={true}
          freeSolo={false}
          loader={loaderClasses}
          open={openClasses}
          setOpen={setOpenClasses}
          limitTags={2}
          data={classesData}
          defaultData={defaultClass?.class ? [{ className: defaultClass.class, id: defaultClass.classId }].flat() : isEditForm ? testClasses : []}
          inputLabel="Select Class"
          setSelectedValue={setSelectedClasses}
        />

        {/* Quantity of questions*/}
        <div className={styles.inputWrapper}>
          <OutlinedTextField
            className={`${styles.formField} ${styles.questions}`}
            type="number"
            {...register('totalQuestions', {
              required: true,
              min: 1,
            })}
            label="Total Questions"
            fullWidth
            defaultValue={questions}
            onBlur={(e) => setQuestions(parseFloat(e.target.value))}
          />
          <div className={styles.textHelper}>
            {errors?.totalQuestions && (
              <>
                <p>{'Minimum questions quantity is 1'}</p>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>

        {/* Select Concept*/}
        <TestInputSelect
          className={styles.formField}
          control={control}
          name={'concept'}
          multiple={false}
          freeSolo={true}
          loader={false} //loaderConcept if has concept data on backend
          open={openConcept}
          setOpen={setOpenConcept}
          limitTags={1}
          data={conceptsData}
          defaultData={isEditForm ? testConcept : ''}
          inputLabel="Enter or Select a Concept"
          setSelectedValue={setConcept}
        />

        {/* Select speed*/}
        <div className={styles.inputWrapper}>
          <OutlinedTextField
            className={styles.lastFormField}
            {...register('expSpeed', speedRegisterOptions)}
            label="Expected Speed"
            placeholder="XXh XXm XXs"
            fullWidth
            // defaultValue={enteredSpeed}
            value={enteredSpeed}
            onInput={(e) => { setEnteredSpeed(formatTestInput(e, prevEnteredSpeed)); }}
            // onBlur={(e) => setEnteredSpeed(e.target.value)}
          />
          <div className={styles.lastTextHelper}>
            {errors?.expSpeed && (
              <>
                <p>{errors?.expSpeed?.message || 'Error!'}</p>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>

        {isEditForm && (
          <button
            className={styles.archiveBtn}
            type="button"
            onClick={openArchiveModal}
            disabled={testIsArchive}
          >
            <ArchiveTwoToneIcon />
            <span>archive test</span>
          </button>
        )}

        {modalArchive && (
          <TestsModalForArchiving
            archiveData={[testId]}
            modalArchive={modalArchive}
            close={closeArchiveModal}
            callRerender={callRerender}
             closeModal={ closeModal}
          />
        )}
      </ErrorBoundary>
      <ModalFooter>
        <ModalSecondaryButton onClick={closeModal}>Cancel</ModalSecondaryButton>
        <ModalPrimaryButton
          typeBtn="submit"
        >
          {isEditForm ? 'Confirm' : 'Add'}
        </ModalPrimaryButton>
      </ModalFooter>
    </form>
  );
}

export default TestForm;
