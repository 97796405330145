import { useState, useContext, useCallback, useEffect } from 'react';
import axios from 'axios';
import noSearchResults from '../../images/noSearchResults.svg';
import { config } from '../../config';
import { AppContext } from '../../context/AppContext';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import ParentHeader from './ParentPageComponents/ParentHeader/ParentHeader';
import { Alert } from '../../components/Alert/Alert';
import { Button } from '../../components/Button/Button';
import StudentInfoList from './ParentPageComponents/StudentInfoList/StudentInfoList';
import StudentInfoChart from './ParentPageComponents/StudentInfoChart/StudentInfoChart';
import AllTestsAnalyticsTable from './ParentPageComponents/AllTestsAnalyticsTable/AllTestsAnalyticsTable';
import SubmitTestModal from '../../components/SubmitTestModal/SubmitTestModal';
import { DatePickerRange } from '../../components/DatePicker/DatePicker';
import { DropdownSelect } from '../../components/DropdownSelect/DropdownSelect';
import ButtonText from '../../components/buttons/buttonText';
import ExpButton from './ParentPageComponents/ExpButton/ExpButton';
import './ParentPage.scss';
// =============================================================================
// import {defaultTableDataStudents} from "./defaultTableDataStudents"
const allConcepts = ['Arithmetic', 'Multiplication'];
// =============================================================================

export const ParentPage = () => {
  const {
    showLoader,
    setShowLoader,
    students,
    currentStudentId,
    currentStudent,
    studentInfo,
    setStudentInfo,
  } = useContext(AppContext);

  // console.log('students', students);
  // console.log('currentStudentId', currentStudentId);
  // console.log('currentStudent', currentStudent);
  console.log('studentInfo', studentInfo);

  // for grey subHeader
  const [conceptFilter, setConceptFilter] = useState(null);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [startDateVisual, setStartDateVisual] = useState(null);
  const [endDateVisual, setEndDateVisual] = useState(null);

  // for charts
  const [filterAverageScore, setFilterAverageScore] = useState(null);
  const [filterAverageSpeed, setFilterAverageSpeed] = useState(null);
  // console.log('filterAverageScore', filterAverageScore);
  // console.log('filterAverageSpeed', filterAverageSpeed);
  // console.log('setFilterAverageScore', setFilterAverageScore);
  // console.log('setFilterAverageSpeed', setFilterAverageSpeed);

  // for sort
  const [nameSort, setNameSort] = useState(null);
  const [avSpeedSort, setAvSpeedSort] = useState(null);
  const [avScoreSort, setAvScoreSort] = useState(null);

  // for sort (in request)
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  // for pagination
  const [pageSize, setPageSize] = useState(10); // 10, 25, 50
  const [currentPage, setCurrentPage] = useState(0); // 0
  const [totalCount, setTotalCount] = useState(); // 100

  // for Submit New Student Data
  const [submitTestModal, setSubmitTestModal] = useState(false);

  // const [tableDataStudents, setTableDataStudents] = useState(defaultTableDataStudents);
  const [tableDataStudents, setTableDataStudents] = useState([]);
  // console.log('tableDataStudents', tableDataStudents);

  // =============================================================================
  const [tableDataUpdated, setTableDataUpdated] = useState(0);
  console.log('tableDataUpdated', tableDataUpdated);

  // =============================================================================
  const conceptsData = tableDataStudents.map(({ concept }) => concept);
  // console.log('conceptsData', conceptsData);
  const makeUnique = (arr) => [...new Set(arr)];
  // const makeUnique = (arr) => arr.filter((el, id) => arr.indexOf(el) === id);
  const filteredConceptsData = makeUnique(conceptsData);
  // console.log('filteredConceptsData', filteredConceptsData);

  // =============================================================================
  useEffect(() => {
    setConceptFilter(null);
    setStartDateFilter(null);
    setEndDateFilter(null);
    setFilterAverageScore(null);
    setFilterAverageSpeed(null);
    setPageSize(10);
    setCurrentPage(0);
    setTotalCount(undefined);
    setNameSort(null);
    setAvSpeedSort(null);
    setAvScoreSort(null);
    setSort(null);
    setSortDirection(null);
  }, [currentStudentId]);

  async function getStudentInfoData() {
    try {
      setShowLoader(true);
      const bodyRequest = {
        startDateFilter: startDateFilter,
        endDateFilter: endDateFilter,
        studentId: currentStudentId,
        concept: conceptFilter,
      };
      await axios.post(`${config.API_URL}/api/analytics/student`, bodyRequest).then((response) => {
        const studentInfoData = response.data[0];
        console.log('/api/analytics/student ----- studentInfoData');
        console.log('studentInfoData', studentInfoData);
        setStudentInfo(studentInfoData);
        setShowLoader(false);
      });
    } catch (error) {
      console.log('error', error);
      setShowLoader(false);
    }
  }


  // to get student tests info (request) for < AllTestsAnalyticsTable />
  const handleSendRequestForTable = useCallback(
    async (toResetPagination) => {
      if (toResetPagination === true) {
        setCurrentPage(0);
      }
      setShowLoader(true);
      // make correct object to make a request to server
      const sortToSend = sort
        ? { key: sort, direction: sortDirection }
        : { key: 'label', direction: true };
      const requestParamsGetTable = {
        studentId: currentStudentId,
        concept: conceptFilter,
        startDateFilter: startDateFilter,
        endDateFilter: endDateFilter,
        filterAverageScore: filterAverageScore,
        filterAverageSpeed: filterAverageSpeed,
        sort: sortToSend,
        pagination: {
          perPage: pageSize,
          page: currentPage + 1,
        },
      };

      try {
        const response = await axios.post(
          `${config.API_URL}/api/analytics/student/tests`,
          requestParamsGetTable
        );
        console.log('response', response);
        if (response.data) {
          const studentTestsData = response.data;
          console.log('/api/analytics/student/tests ----- studentTestsData');
          console.log('studentTestsData', studentTestsData);
          setTableDataStudents(studentTestsData);
          setTotalCount(studentTestsData.totalCount);
          setShowLoader(false);
        }
      } catch (error) {
        console.log(error);
        setShowLoader(false);
      }
    },
    [
      currentPage,
      endDateFilter,
      filterAverageScore,
      filterAverageSpeed,
      pageSize,
      sort,
      sortDirection,
      startDateFilter,
      currentStudentId,
      conceptFilter,

    ]
  );

  useEffect(() => {
    const handleSetSort = () => {
      if (nameSort !== null) {
        setCurrentPage(0);
        setSort('label');
        let newName = nameSort === true ? true : false;
        setSortDirection(newName);
      }
      if (avSpeedSort !== null) {
        setCurrentPage(0);
        setSort('speed');
        let newSpeed = avSpeedSort === true ? true : false;
        setSortDirection(newSpeed);
      }
      if (avScoreSort !== null) {
        setCurrentPage(0);
        setSort('score');
        let newScore = avScoreSort === true ? true : false;
        setSortDirection(newScore);
      }
    };
    handleSetSort();
  }, [nameSort, avSpeedSort, avScoreSort]);

  
  useEffect(() => {
    const toResetPagination = true;
    getStudentInfoData();
    handleSendRequestForTable(toResetPagination);
  }, [
    conceptFilter,
    endDateFilter,
    filterAverageScore,
    filterAverageSpeed,
    pageSize,
    sort,
    sortDirection,
    currentStudentId,
    students,
    tableDataUpdated,
  ]);

  useEffect(() => {
    const toResetPagination = false;
    getStudentInfoData();
    handleSendRequestForTable(toResetPagination);
  }, [currentPage]);

  // =======================================
  const defaultAnalyticsStudentName = `${students[0]?.firstName} ${students[0]?.lastName}`;
  let analyticsStudentName = defaultAnalyticsStudentName;
  useEffect(() => {
    analyticsStudentName = students
      .filter((student) => student.id === currentStudentId)
      .map(({ firstName, lastName }) => {
        return `${firstName} ${lastName}`;
      });
  }, [currentStudentId]);
  // console.log('analyticsStudentName', analyticsStudentName);

  // =======================================
  // SORT Functions
  const sortFunctionForName = () => {
    if (nameSort === null) {
      setNameSort(true);
      setAvSpeedSort(null);
      setAvScoreSort(null);
      return;
    }
    setNameSort((prevState) => !prevState);
    setAvSpeedSort(null);
    setAvScoreSort(null);
  };

  const sortFunctionForScore = () => {
    if (avScoreSort === null) {
      setAvScoreSort(true);
      setAvSpeedSort(null);
      setNameSort(null);
      return;
    }
    setAvScoreSort((prevState) => !prevState);
    setAvSpeedSort(null);
    setNameSort(null);
  };

  const sortFunctionForSpeed = () => {
    if (avSpeedSort === null) {
      setAvSpeedSort(true);
      setAvScoreSort(null);
      setNameSort(null);
      return;
    }
    setAvSpeedSort((prevState) => !prevState);
    setAvScoreSort(null);
    setNameSort(null);
  };

  const handleClearFilters = () => {
    setConceptFilter(null);
    setStartDateFilter(null);
    setEndDateFilter(null);
    setStartDateVisual(null);
    setEndDateVisual(null);
  };

  // =======================================
  // ALERT
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => {},
    show: false,
  });
  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(
    () =>
      setIsAlert({
        type: false,
        text: '',
        close: () => {},
        show: false,
      }),
    []
  );

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <ParentHeader />
      <div className="parentPage_Header_wrapper">
        <div className="greyDropdownWrapper">
          <DropdownSelect
            dataToSelect={filteredConceptsData ? filteredConceptsData : allConcepts}
            defaultValue={'All Concepts'}
            stateFilter={conceptFilter}
            setStateFilter={setConceptFilter}
            type="concepts"
          />
          <DatePickerRange
            type={'light'}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}
            startDateVisual={startDateVisual}
            setStartDateVisual={setStartDateVisual}
            endDateVisual={endDateVisual}
            setEndDateVisual={setEndDateVisual}
          />
          <ButtonText
            type={`4`}
            text={`Clear all`}
            iconName={`Clear`}
            active={true}
            funtionality={handleClearFilters}
          />
        </div>
      </div>
      <div className="parentPage_wrapper">
        <div className="parentPage_titleAndBtnWrapper">
          <h2 className="parentPage_title">
            Analytics{' '}
            {currentStudent?.firstName ? currentStudent.firstName : students[0]?.firstName}{' '}
            {currentStudent?.lastName ? currentStudent.lastName : students[0]?.lastName}
          </h2>
          <ExpButton
            activeClasses={tableDataStudents}
            fileHeader={'studentData'}
            callAlert={callAlert}
            closeAlert={closeAlert}
          />
        </div>
        <StudentInfoList studentInfo={studentInfo} />
        <div className="parentPage_ChartWrapper">
          {studentInfo ? (
            <StudentInfoChart
              setFilterAverageScore={setFilterAverageScore}
              setFilterAverageSpeed={setFilterAverageSpeed}
            />
          ) : (
            <div className="StudentInfo-noResults">
              <div className="StudentInfo-noResults-text">No Data available</div>
              <img
                className="StudentInfo-noResults-img"
                src={noSearchResults}
                width="250"
                alt="No Data available"
              />
            </div>
          )}
        </div>
        <div className="parentPage_titleAllTestsWrapper">
          <AllTestsAnalyticsTable
            tableDataStudents={tableDataStudents ? tableDataStudents : []}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={totalCount}
            setTotalCount={setTotalCount}
            sortFunctionForName={sortFunctionForName}
            sortFunctionForScore={sortFunctionForScore}
            sortFunctionForSpeed={sortFunctionForSpeed}
            sortName={sort}
            sortDirection={sortDirection}
            filterAverageScore={filterAverageScore}
            filterAverageSpeed={filterAverageSpeed}
            setFilterAverageScore={setFilterAverageScore}
            setFilterAverageSpeed={setFilterAverageSpeed}
          />
          <div style={{ position: 'absolute', top: 0, right: 40 }}>
            <Button onClick={() => setSubmitTestModal(true)}>Submit results</Button>
          </div>
        </div>
      </div>
      {submitTestModal && (
        <SubmitTestModal
          testsData={tableDataStudents.filter((item) => item.score === null && item.speed === null)}
          close={() => setSubmitTestModal(false)}
          setIsAlert={setIsAlert}
          setTableDataUpdated={setTableDataUpdated}
        />
      )}
      {isAlert.show && (
        <Alert
          alertType={isAlert.type}
          text={isAlert.text}
          close={isAlert.close}
          show={isAlert.show}
        />
      )}
    </>
  );
};;
