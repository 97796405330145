import { useCallback, useState } from 'react';
import { Alert } from '../../../../../components/Alert/Alert.jsx';
import { IconButton } from '../../../../../components/buttons/IconButton/IconButton.jsx';

import './StudentInfoPopOver.scss';

export const StudentInfoPopOver = ({ parentsInfo, studentIdSchool, name }) => {
console.log('name', name);
console.log('studentIdSchool', studentIdSchool);
console.log('parentsInfo', parentsInfo);
console.log("=================================")
// console.log('Object.keys(parentsInfo.firstParent)', Object.keys(parentsInfo.firstParent));
  // console.log('Object.values(parentsInfo.firstParent)', Object.values(parentsInfo.firstParent));
  console.log("=================================")
  console.log('parentsInfo.firstParent.name', parentsInfo.firstParent.name);
  console.log('parentsInfo.firstParent.email', parentsInfo.firstParent.email);
  console.log('parentsInfo.firstParent.phone', parentsInfo.firstParent.phone);
  console.log("=================================")
  // console.log('Object.keys(parentsInfo.secondParent)', Object.keys(parentsInfo.secondParent));
  // console.log('Object.values(parentsInfo.secondParent)', Object.values(parentsInfo.secondParent));
  console.log("=================================")
  const handleCopyText = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      // setCopySuccess('Copied!');
    } catch (err) {
      // setCopySuccess('Failed to copy!');
    }
  };

  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { }
  });

  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { }
  }), []);


  return (
    <div className="StudentInfoPopOver_wrapper">
      <div className="StudentInfoPopOver_header">{name}</div>
      <div className="StudentInfoPopOver_id">
        <span>ID</span> {studentIdSchool}
      </div>
      <div className="StudentInfoPopOver_parents">Parents:</div>

      {parentsInfo?.secondParent !== null &&
      parentsInfo?.secondParent !== undefined &&
      Object.values(parentsInfo?.secondParent).length ? (
        <div className="parentInfo_wrapper">
          <div style={{ color: '#424242' }} className="parentInfo_name">
            {parentsInfo.secondParent.name}
          </div>
          <div className="parentInfo_emailWrapper">
            <div className="parentInfo_email">
              Email:
              <span>{parentsInfo.secondParent.email}</span>
            </div>
            <IconButton
              type={'3'}
              active={true}
              iconName={'ContentCopyTwoTone'}
              funtionality={() => {
                callAlert({
                  type: true,
                  text: 'Copied!',
                  close: closeAlert,
                });
                handleCopyText(parentsInfo.secondParent.email);
              }}
            />
          </div>

          <div className="parentInfo_emailWrapper">
            <div className="parentInfo_email">
              Phone:
              <span>{parentsInfo.secondParent.phone}</span>
            </div>
            <IconButton
              type={'3'}
              active={true}
              iconName={'ContentCopyTwoTone'}
              funtionality={() => {
                callAlert({
                  type: true,
                  text: 'Copied!',
                  close: closeAlert,
                });
                handleCopyText(parentsInfo.secondParent.phone);
              }}
            />
          </div>
        </div>
      ) : null}

      {parentsInfo?.firstParent !== null &&
      parentsInfo?.firstParent !== undefined &&
      Object.values(parentsInfo?.firstParent).length ? (
        <div className="parentInfo_wrapper">
          <div style={{ color: '#424242' }} className="parentInfo_name">
            {parentsInfo.firstParent.name}
          </div>
          <div className="parentInfo_emailWrapper">
            <div className="parentInfo_email">
              Email:
              <span>{parentsInfo.firstParent.email}</span>
            </div>
            <IconButton
              type={'3'}
              active={true}
              iconName={'ContentCopyTwoTone'}
              funtionality={() => {
                callAlert({
                  type: true,
                  text: 'Copied!',
                  close: closeAlert,
                });
                handleCopyText(parentsInfo.firstParent.email);
              }}
            />
          </div>

          <div className="parentInfo_emailWrapper">
            <div className="parentInfo_email">
              Phone:
              <span>{parentsInfo.firstParent.phone}</span>
            </div>
            <IconButton
              type={'3'}
              active={true}
              iconName={'ContentCopyTwoTone'}
              funtionality={() => {
                callAlert({
                  type: true,
                  text: 'Copied!',
                  close: closeAlert,
                });
                handleCopyText(parentsInfo.firstParent.phone);
              }}
            />
          </div>
        </div>
      ) : null}

      {isAlert.type && <Alert alertType={isAlert.type} text={isAlert.text} close={isAlert.close} />}
    </div>
  );
};
