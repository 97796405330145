import React, { forwardRef, PropsWithChildren } from 'react';
import styles from './ModalContainerTestResult.module.scss';

export const ModalContainerTestResult = forwardRef<HTMLDivElement, PropsWithChildren<{}>>(
  ({ children }, ref) => (
    <div
      ref={ref}
      className={styles.largeContainer}
    >
      {children}
    </div>
  )
);
