import { useState, useEffect } from 'react';
import { TableCell } from '../TableCell/TableCell';
import { StyledTableHead } from './StyledTableHead';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './TableHead.scss';

export const TableHead = ({
  selectedTeacherType,
  selectedStudentType,
  typeTableHead,
  sortDirection,
  sortName,
  scoreSortDir = 0,
  statusSortDir = 0,
  typeTableRow,
  isSelectAll,
  sortByLetter = () => { },
  sortByScoreFunc = () => { },
  sortByStatusFunc = () => { },
  forPage,
  letterSortDir = 0,
  selectAll = () => { },
  selectedTeacher = null,
  selectedStudent = null }) => {
  
  const [checkBox, setCheckBox] = useState(false);

  useEffect(() => {
    if (selectedStudent) {
      if (selectedStudent.length === 0) {
        setCheckBox(false);
      }
    } else if (selectedTeacher) {
      if (selectedTeacher.length === 0) {
        setCheckBox(false);
      }
    }

    if (!isSelectAll) {
      setCheckBox(false);
    }

  }, [selectedTeacher, selectedStudent, isSelectAll])

  return (
    <StyledTableHead typeTableRow={typeTableRow}>
      {typeTableHead.map(({ fieldName, width, sortByLetters, sortByScore, sortByStatus, functionality = () => { } }, index) => {
        if (fieldName === 'checkBox' && forPage === 'analytics') return <div key={index}></div>;
        if (fieldName === 'checkBox') return (
          <div
            key={index}
            className='checkBox'
            style={{
              cursor: selectedTeacherType === 1 || selectedStudentType === 1 ? 'not-allowed' : 'pointer'
            }}>
            <div
              style={{
                pointerEvents: selectedTeacherType === 1 || selectedStudentType === 1 ? 'none' : 'auto'
              }}
              onClick={() => { setCheckBox(!checkBox); selectAll(checkBox) }}>
              {checkBox ? <CheckBoxTwoToneIcon style={{ color: '#323232' }} /> 
                : < CheckBoxOutlineBlankIcon style={{ color: '#828282' }} />}
            </div>
          </div>
        );
        if (fieldName === 'Actions' && forPage === 'analytics') return;

        return (
          <TableCell key={index}>
            {fieldName}
            {sortByLetters && (
              <div
                className={`
                letterBtnSort 
                ${letterSortDir !== 0 ? (letterSortDir === 1 ? 'sortUp' : 'sortDown') : ''}
                ${sortName === 'Name' ? (sortDirection ? 'sortUp' : 'sortDown') : ''}
                `}
                onClick={() => {
                  sortByLetter();
                  functionality()
                }}>
                <ArrowDropUpIcon />
                <div>AZ</div>
                <ArrowDropDownIcon />
              </div>
            )}
            {sortByScore && (
              <>
                <div
                  className={`
                  unfoldBtnSort 
                  ${scoreSortDir !== 0 ? (scoreSortDir === 1 ? 'sortUp' : 'sortDown') : ''}
                  ${sortName === 'AvScore' ? (sortDirection ? 'sortUp' : 'sortDown') : ''}
                  `}
                  onClick={() => { sortByScoreFunc(); functionality() }}>
                  <ExpandLessIcon fontSize="large" />
                  <ExpandMoreIcon fontSize="large" />
                </div>
              </>
            )}
            {sortByStatus && (
              <>
                <div
                  className={`
                  unfoldBtnSort 
                  ${statusSortDir !== 0 ? (statusSortDir === 1 ? 'sortUp' : 'sortDown') : ''}
                  ${sortName === 'AvSpeed' ? (sortDirection ? 'sortUp' : 'sortDown') : ''}
                  `}
                  onClick={() => { sortByStatusFunc(); functionality() }}>
                  <ExpandLessIcon fontSize="large" />
                  <ExpandMoreIcon fontSize="large" />
                </div>
              </>
            )}
          </TableCell>
        );
      })}
    </StyledTableHead>
  );
};
