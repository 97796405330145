import { FC } from 'react';
import { SignInForm } from './SignInForm/SignInForm.jsx';
import { SignInUpButtons } from '../components/SignInUpButtons/SignInUpButtons';
import { PageContainer } from '../components/PageContainer/PageContainer';
import { VendorSignInButtons } from '../components/VendorSignInButtons/VendorSignInButtons';
import { ContentHead } from '../components/ContentHead/ContentHead';
import { MemoriaLogo } from '../components/MemoriaLogo/MemoriaLogo';
import { FormHeader } from '../components/FormHeader/FormHeader';
import styles from './SignInPage.module.scss';
// import { Link } from 'react-router-dom';

export const SignInPage: FC = () => {
  return (
    <PageContainer>
      <MemoriaLogo />
      <div className={styles.pageBody}>
        <ContentHead />
        <SignInUpButtons />
        <FormHeader>logIn to your account</FormHeader>
        <SignInForm />
        <VendorSignInButtons />
      </div>

    </PageContainer>
  )}
