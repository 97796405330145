import { useState, useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { OutlinedTextField } from '../../../../components/OutlinedTextField/OutlinedTextField';
import { ModalFooter } from "../../../../components/Modal/ModalFooter/ModalFooter";
import { ModalPrimaryButton } from "../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton";
import { ModalSecondaryButton } from "../../../../components/Modal/buttons/ModalSecondaryButton/ModalSecondaryButton";
import SelectClasses from "../../../../components/SelectClasses/SelectClasses";
import TeachersModalArchive from "../TeacherModalArchive/TeacherModalArchive";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import styles from './TeachersFormFields.module.scss';
import { AppContext } from '../../../../context/AppContext';
import { config } from '../../../../config';
import { formatPhoneNumber } from "../../../../model/formatPhoneNumber";

export const TeachersFormFields = ({
  changeModalLoader = () => { },
  defaultValues = {},
  isEdit = false,
  openOnSubmitModal = () => { },
  close,
  setShowAlert = () => { },
  submitForm = () => { } }
) => {
  const { currentSchoolId } = useContext(AppContext);

  const valuesArr = [];
  valuesArr.push(defaultValues);

  const requestFetch = (url, method, body = null) => {
    const headers = {
      'Content-Type': 'application/json'
    }

    return fetch(url, {
      method: method,
      body: JSON.stringify(body),
      headers: headers
    }).then(response => {

      if (response.ok) {
        if (isEdit) {
          return response
        } else {
          return response.json()
        }
      }

      return response.json().then(error => {
        const e = new Error('Smth gone wrong')
        e.data = error
        throw e
      })
    });
  }

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    changeModalLoader();
    const method = 'POST';
    const url = isEdit
      ? `${config.API_URL}/api/teacher/update`
      : `${config.API_URL}/api/teacher/add`;

    data.schoolId = currentSchoolId

    if (isEdit) {
      data.id = defaultValues.teacherId
    }

    const classesArr = [];
    data.classes.forEach(item => {
      classesArr.push(item.id)
    });

    data.classes = classesArr;

    await requestFetch(url, method, data)
      .then(data => {
        if (isEdit) {
          openOnSubmitModal('Teacher Successfully Edited')
          submitForm();
        } else {
          if (data.success) {
            openOnSubmitModal('Teacher Successfully Added')
            submitForm();
          } else {
            openOnSubmitModal(data.message)
          }
        }
      })
      .catch(err => { console.log(err); setShowAlert(true) });
    changeModalLoader();
    reset();
    close();
  }

  const [archiveModal, setArchiveModal] = useState(false);

  const openModalArchive = useCallback(() => setArchiveModal(true), []);
  const closeModalArchive = useCallback(() => setArchiveModal(false), [])

  const [phoneValue, setPhoneValue] = useState(defaultValues ? defaultValues?.phone : formatPhoneNumber(''));

  return (
    <>
      <form className={styles.fieldSet} onSubmit={handleSubmit(onSubmit)}>

        <div className={styles.inputWrapper}>
          <OutlinedTextField
            {...register('firstName', {
              required: 'Required Field',
            })}
            fullWidth
            defaultValue={defaultValues ? defaultValues?.firstName : ''}
            label="First Name"
            placeholder={'Enter First Name'}
            className={styles.formField}
          >
          </OutlinedTextField>

          <div className={styles.textHelper}>
            {errors?.firstName &&
              (
                <>
                  <p>{errors?.firstName?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
          </div>
        </div>

        <div className={styles.inputWrapper}>
          <OutlinedTextField
            {...register('lastName', {
              required: 'Required Field',
            })}
            fullWidth
            defaultValue={defaultValues ? defaultValues?.lastName : ''}
            label="Last Name"
            placeholder={"Enter Last Name"}
            className={styles.formField}
          />

          <div className={styles.textHelper}>
            {errors?.lastName &&
              (
                <>
                  <p>{errors?.lastName?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
          </div>
        </div>


        <div className={styles.inputWrapper}>
          <OutlinedTextField
            {...register('email', {
              required: 'Required Field',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              }
            })}
            fullWidth
            label="Email"
            defaultValue={defaultValues ? defaultValues?.email : ''}
            placeholder={"XXXXXXXX@XXXX.XX"}
            className={styles.formField}
          />

          <div className={styles.textHelper}>
            {errors?.email &&
              (
                <>
                  <p>{errors?.email?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
          </div>
        </div>


        <div className={styles.inputWrapper}>
          <OutlinedTextField
            {...register('phone', {
              required: 'Required Field',
              pattern: {
                value: /[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{4}/,
                message: "Entered value does not match phone format"
              }
            })}
            fullWidth
            label="Phone"
            value={phoneValue}
            onInput={(e) => {setPhoneValue(formatPhoneNumber(e.target.value))}}
            placeholder={"(XXX) XXX-XXXX"}
            className={styles.formField}
          />

          <div className={styles.textHelper}>
            {errors?.phone &&
              (
                <>
                  <p>{errors?.phone?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
          </div>
        </div>

        <SelectClasses
          setShowAlert={setShowAlert}
          control={control}
          defaultClasses={defaultValues ? defaultValues?.classArray : []}
        />

        {isEdit && (<button type="button" className={styles.archiveBtn} onClick={openModalArchive}>
          <ArchiveTwoToneIcon />
          Archive Teacher
        </button>)}

        <ModalFooter>
          <ModalSecondaryButton onClick={close}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton typeBtn='submit'>{isEdit ? 'Confirm' : 'Add'}</ModalPrimaryButton>
        </ModalFooter>
      </form>

      {archiveModal &&
        <TeachersModalArchive
          closeModalEdit={close}
          close={closeModalArchive}
          submitForm={submitForm}
          selectedTeacher={valuesArr}
        />}
    </>
  );
};
