import React, { useContext } from 'react';
import { PieChart } from '../../../../components/PieChart/PieChart';
import { AppContext } from '../../../../context/AppContext';
import './StudentInfoChart.scss'

const StudentInfoChart = (
  { setFilterAverageScore,
    setFilterAverageSpeed }
  ) => {
    const { studentInfo } = useContext(AppContext);
    // console.log('studentInfo', studentInfo);
    // console.log('setFilterAverageScore', setFilterAverageScore);
    // console.log('setFilterAverageSpeed', setFilterAverageSpeed);

    // из значений (объектов) для диаграмм schoolScoreStatistic и schoolSpeedStatistic
    // сортируем по количеству, потом получаем ключи и значения объектов для того,
    // чтобы вывести правильный контент на страницу и в диаграму передать массив цифр
    // для отрисoвки от большего к меньшему, плюс приводим количество к процентам

    // for  AvScore

    const sortableAvScore = {
      from90to100: studentInfo.above90,
      // above90: studentInfo.above90,
      from80to89: studentInfo.from80to89,
      from50to70: studentInfo.from50to79,
      // from50to79: studentInfo.from50to79,
      below50: studentInfo.below50,
    };
    // console.log('sortableAvScore', sortableAvScore);

    const keysSortableAvScore = Object.keys(sortableAvScore);
    //  console.log('keysSortableAvScore', keysSortableAvScore);
    const valuesSortableAvScore = Object.values(sortableAvScore);
    //  console.log('valuesSortableAvScore', valuesSortableAvScore);

    const sumAvScoreChartValues = valuesSortableAvScore.reduce((sum, num) => sum + num);
    //  console.log('sumAvScoreChartValues', sumAvScoreChartValues);
    const studentsToOnePrAvScore = +sumAvScoreChartValues / 100;
    //  console.log('studentsToOnePrAvScore', studentsToOnePrAvScore);

    // for  AvSpeed

    const sortableAvSpeed = {
      aboveExpected: studentInfo.aboveExpected,
      asExpected: studentInfo.asExpected,
      belowExpected: studentInfo.belowExpected,
    };
    // console.log('sortableAvSpeed', sortableAvSpeed);

    const keysSortableAvSpeed = Object.keys(sortableAvSpeed);
    const valuesSortableAvSpeed = Object.values(sortableAvSpeed);

    const sumAvSpeedChartValues = valuesSortableAvSpeed.reduce((sum, num) => sum + num);
    const studentsToOnePrAvSpeed = +sumAvSpeedChartValues / 100;

    return (
      <>
        <div className="analiticsContainer_wrapper">
          <div className="analiticsContainer_chartsWrapper">
            <div className="analiticsContainer_singleChartWrapper">
              <div className="analiticsContainer_chartHeader"> SCORE</div>
              <div className="analiticsContainer_chartAndLegend_wrapper">
                <div className="analiticsContainer_chartWrapper">
                  <PieChart
                    dataToChart={sortableAvScore}
                    setStateFilter={setFilterAverageScore}
                    type={'score'}
                  />
                </div>
                <div className="analiticsContainer_legendWrapper">
                  {keysSortableAvScore.map((score, index) => {
                    return (
                      <div style={{ textTransform: 'uppercase' }} key={index} className={score}>
                        <span>
                          {!valuesSortableAvScore[index] / studentsToOnePrAvScore
                            ? 0
                            : (valuesSortableAvScore[index] / studentsToOnePrAvScore).toFixed(
                                1
                              )}{' '}
                          %
                        </span>
                        -
                        <span>
                          {score === 'below50'
                            ? 'below 50% accuracy  '
                            : score === 'from50to70'
                            ? '50 - 79% accuracy  '
                            : score === 'from80to89'
                            ? '80 - 89% accuracy  '
                            : '90%+ accuracy   '}
                          ({valuesSortableAvScore[index]}{' '}
                          {valuesSortableAvScore[index] === 1 ? 'Test' : 'Tests'})
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="analiticsContainer_tooltipFofClick">
                *Click on the pie chart to open more info
              </div>
            </div>

            <div className="analiticsContainer_singleChartWrapper">
              <div className="analiticsContainer_chartHeader"> SPEED </div>
              <div className="analiticsContainer_chartAndLegend_wrapper">
                <div className="analiticsContainer_chartWrapper">
                  <PieChart
                    dataToChart={sortableAvSpeed}
                    setStateFilter={setFilterAverageSpeed}
                    type={'speed'}
                  />
                </div>

                <div className="analiticsContainer_legendWrapper">
                  {keysSortableAvSpeed.map((speed, index) => {
                    return (
                      <div style={{ textTransform: 'uppercase' }} key={index} className={speed}>
                        <span>
                          {!valuesSortableAvSpeed[index] / studentsToOnePrAvSpeed
                            ? 0
                            : (valuesSortableAvSpeed[index] / studentsToOnePrAvSpeed).toFixed(
                                1
                              )}{' '}
                          %
                        </span>
                        -
                        <span>
                          {speed === 'belowExpected'
                            ? 'Below expected  '
                            : speed === 'asExpected'
                            ? ' As expected  '
                            : speed === 'aboveExpected'
                            ? 'Above expected  '
                            : null}
                          ({valuesSortableAvSpeed[index]}{' '}
                          {valuesSortableAvSpeed[index] === 1 ? 'Test' : 'Tests'})
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="analiticsContainer_tooltipFofClick">
                *Click on the pie chart to open more info
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

export default StudentInfoChart