import React, { useCallback, useEffect, useState } from 'react';
import { useSort } from '../../../hooks/sort';
import ErrorBoundary from '../../ErrorBaundary';
import CheckboxTwoTone from '../../CheckboxTwoTone';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TestRow from './TestRow';
import { Pagination } from '../../Pagination/Pagination';
import TestsModalForArchiving from '../TestsModalForArchiving';
import styles from './TestsTable.module.scss';

function TestsTable({
  data,
  selectedTests,
  setSelectedTests,
  setRerender,
  callAlert,
  closeAlert,
}) {
  //convert data to table logic
  const [archivedTests, setArchivedTests] = useState([]);
  const [notArchivedTests, setNotArchivedTests] = useState([]);
  //for sort
  const [byAlpha, setByAlpha] = useState(false);
  const [sortedByAlpha, setSortedByAlpha] = useState();
  const [sortByAlpha, setSortByAlpha] = useState('');
  const [byScore, setByScore] = useState(false);
  const [bySpeed, setBySpeed] = useState(false);
  //for pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [showCurrentPageFrom, setShowCurrentPageFrom] = useState();
  const [showCurrentPageTo, setShowCurrentPageTo] = useState();
  //for checkbox and modal unarchive
  const [disabledSelectAll, setDisabledSelectAll] = useState(false);
  const [modalUnarchive, setModalUnarchive] = useState(false);
  const [testForUnarchiving, setTestForUnarchiving] = useState([]);

  const openUnarchiveModal = useCallback(() =>
    setModalUnarchive(true), []);
  const closeUnarchiveModal = useCallback(() =>
    setModalUnarchive(false), []);

  //if data change - update table logic - important!
  useEffect(() => {
    const notArchived = data
      .filter((test) => test.isArchive === 0);
    const archived = data
      .filter((test) => test.isArchive === 1);
    setNotArchivedTests(notArchived);
    setArchivedTests(archived);
  }, [data]);

  //for pagination
  useEffect(() => {
    if (data.length < pageSize) {
      setCurrentPage(0);
    }
  }, [data.length, pageSize]);
  //for pagination
  useEffect(() => {
    setShowCurrentPageFrom((currentPage * pageSize));
    setShowCurrentPageTo((currentPage + 1) * pageSize);
    setSelectedTests([]);
  }, [currentPage, pageSize, setSelectedTests]);

  // for checkbox
  let selectAll = false;
  notArchivedTests.forEach(test => {
    if (selectedTests.includes(test)) {
      selectAll = true;
    }
  });

  const onChangeCheckbox = () => {
    selectAll = !selectAll;
    if (selectAll === true) {
      const selectedOnly = currentTests
        .filter((test) => notArchivedTests.includes(test));
      setSelectedTests(selectedOnly);
    }
    if (selectAll === false) {
      const removeSelect = selectedTests
        .filter((item) => !currentTests
          .includes(item));
      setSelectedTests([...removeSelect]);
    }
  };

  //all for sort
  const {
    sortMode: sortByScore,
    setSortMode: setSortByScore,
    sortedItems: sortedByScore,
  } = useSort(notArchivedTests, 'score', 'score');
  const {
    sortMode: sortBySpeed,
    setSortMode: setSortBySpeed,
    sortedItems: sortedBySpeed,
  } = useSort(notArchivedTests, 'speed', 'speed');

  const onSortByAlpha = (sortStr) => {
    let arr;
    if (sortStr === 'desc') {
      setSortByAlpha('desc');
      arr = notArchivedTests.sort((a, b) =>
        b.label.localeCompare(a.label));
    }
    if (sortStr === 'asc') {
      setSortByAlpha('asc');
      arr = notArchivedTests.sort((a, b) =>
        a.label.localeCompare(b.label));
    }
    setSortedByAlpha(arr);
    setByAlpha(true);
    setByScore(false);
    setBySpeed(false);
  };
  const onSortByScore = (sortStr) => {
    setSortByScore(sortStr);
    setByScore(true);
    setByAlpha(false);
    setBySpeed(false);
  };
  const onSortBySpeed = (sortStr) => {
    setSortBySpeed(sortStr);
    setBySpeed(true);
    setByAlpha(false);
    setByScore(false);
  };

  // correct data for table after filters/sort/pagination
  const sortTests = () => {
    switch (true) {
      case byAlpha:
        return [...sortedByAlpha, ...archivedTests];
      case byScore:
        return [...sortedByScore, ...archivedTests];
      case bySpeed:
        return [...sortedBySpeed, ...archivedTests];
      default:
        return [...notArchivedTests, ...archivedTests];
    }
  };
  const sortedTests = sortTests();
  const currentTests = sortedTests.slice(showCurrentPageFrom, showCurrentPageTo);

  //for correct work checkboxes
  const notArchivedSelectedTests = selectedTests
    .filter((test) => test.isArchive === 0);

  //logic moved from table return value
  const tableBody = currentTests.map((test) => {
    let testReadOnly = false;
    if (test.isArchive
      && notArchivedSelectedTests.length > 0
      && selectedTests.length > 0) {
      testReadOnly = true;
    }
    if (!test.isArchive
      && !notArchivedSelectedTests.length > 0
      && selectedTests.length > 0) {
      testReadOnly = true;
    }

    return (
      <TestRow
        key={test.id}
        test={test}
        selectedTests={selectedTests}
        setSelectedTests={setSelectedTests}
        testReadOnly={testReadOnly}
        notArchivedTests={notArchivedTests}
        setDisabledSelectAll={setDisabledSelectAll}
        setTestForUnarchiving={setTestForUnarchiving}
        openUnarchiveModal={openUnarchiveModal}
        setRerender={setRerender}
        callAlert={callAlert}
        closeAlert={closeAlert}
      />
    );
  });

  // only for styles
  const alphaText = byAlpha ? styles.active : '';
  const alphaDesc = (byAlpha && (sortByAlpha === 'desc')) ? styles.active : '';
  const alphaAsc = (byAlpha && (sortByAlpha === 'asc')) ? styles.active : '';
  const scoreDesc = (byScore && (sortByScore === 'desc')) ? styles.active : '';
  const scoreAsc = (byScore && (sortByScore === 'asc')) ? styles.active : '';
  const speedDesc = (bySpeed && (sortBySpeed === 'desc')) ? styles.active : '';
  const speedAsc = (bySpeed && (sortBySpeed === 'asc')) ? styles.active : '';
  const stylesForCheckbox = `${styles.theadCol} ${styles.checkbox} ${disabledSelectAll
    && styles.disabled}`;

  return (
    <div className={styles.inner}>
      <ErrorBoundary>
        <table className={styles.testsTable}>
          <thead className={styles.tableHead}>
            <tr className={styles.theadRow}>
              <th className={stylesForCheckbox}>
                <CheckboxTwoTone
                  disabled={disabledSelectAll}
                  checked={selectAll}
                  onChange={onChangeCheckbox}
                />
              </th>
              <th className={`${styles.theadCol} ${styles.label}`}>
                <div className={styles.theadColInner}>
                  <span className="thead-col__text">Label</span>
                  <span className={styles.alpha}>
                    <ArrowDropUpIcon
                      className={`${styles.arrowUp} ${alphaDesc}`}
                      onClick={() => onSortByAlpha('desc')}
                    />
                    <span className={`${styles.alphaText} ${alphaText}`}>AZ</span>
                    <ArrowDropDownIcon
                      className={`${styles.arrowDown} ${alphaAsc}`}
                      onClick={() => onSortByAlpha('asc')}
                    />
                  </span>
                </div>
              </th>
              <th className={`${styles.theadCol} ${styles.concept}`}>
                <span className="thead-col__text">Concept</span>
              </th>
              <th className={`${styles.theadCol} ${styles.status}`}>
                <span className="thead-col__text">Status</span>
              </th>
              <th className={`${styles.theadCol} ${styles.teachers}`}>
                <span className="thead-col__text">Teachers</span>
              </th>
              <th className={`${styles.theadCol} ${styles.score}`}>
                <div className={styles.theadColInner}>
                  <span className="thead-col__text">Av. Score</span>
                  <span className={styles.filter}>
                    <ExpandLessIcon
                      className={`${styles.arrowUp} ${scoreDesc}`}
                      onClick={() => onSortByScore('desc')}
                    />
                    <ExpandMoreIcon
                      className={`${styles.arrowDown} ${scoreAsc}`}
                      onClick={() => onSortByScore('asc')}
                    />
                  </span>
                </div>
              </th>
              <th className={`${styles.theadCol} ${styles.speed}`}>
                <div className={styles.theadColInner}>
                  <span className="thead-col__text">Av. Speed</span>
                  <span className={styles.filter}>
                    <ExpandLessIcon
                      className={`${styles.arrowUp} ${speedDesc}`}
                      onClick={() => onSortBySpeed('desc')}
                    />
                    <ExpandMoreIcon
                      className={`${styles.arrowDown} ${speedAsc}`}
                      onClick={() => onSortBySpeed('asc')}
                    />
                  </span>
                </div>
              </th>
              <th className={`${styles.theadCol} ${styles.classes}`}>
                <span className="thead-col__text">Classes</span>
              </th>
              <th className={`${styles.theadCol} ${styles.actions}`}>
                <span className="thead-col__text">Actions</span>
              </th>
            </tr>
          </thead>
          <ErrorBoundary>
            <tbody className={styles.tableBody}>
              {tableBody}
            </tbody>
          </ErrorBoundary>
        </table>
      </ErrorBoundary>
      <Pagination
        totalCount={sortedTests.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {(modalUnarchive) && (
        <TestsModalForArchiving
          archiveData={testForUnarchiving}
          modalArchive={!modalUnarchive}
          close={closeUnarchiveModal}
          callRerender={setRerender}
        />)}
    </div>
  );
}

export default TestsTable;
