export const getClassesForSelect = (data, selectedYears) => {
  const allClasses = Object.values(data)
    .flatMap((grade) => Object.values(grade))
    .flatMap((classes) => classes);

  const dataClasses = allClasses.filter((arhClass) =>
    selectedYears.length
    ? selectedYears.includes(`${arhClass.year}`)
    : allClasses);
  return dataClasses;
};
