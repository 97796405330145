export const formatTestInput = (e, prevEnteredSpeed) => {
  const { value } = e.target;
  if (prevEnteredSpeed.current.length > value.length) {
    prevEnteredSpeed.current = value;
    return value;
  }
  if (!value) return `${value}`;
  const testInput = value.replace(/[^\d]/g, '');
  // console.log('value', value);
  const testInputLength = testInput.length;
  console.log('testInput.length', testInput.length);
  let newValue = "";
  
  if (testInputLength === 1) newValue = `${testInput.slice(0, 1)}`;
  if (testInputLength === 2) newValue =`${testInput.slice(0, 2)}h`;
  if (testInputLength === 3)  newValue = `${testInput.slice(0, 2)}h ${testInput.slice(2, 3)}`;
  if (testInputLength === 4)  newValue = `${testInput.slice(0, 2)}h ${testInput.slice(2, 4)}m`;
  if (testInputLength === 5)  newValue = `${testInput.slice(0, 2)}h ${testInput.slice(2, 4)}m ${testInput.slice(4, 5)}`;
  if (testInputLength >= 6)  newValue = `${testInput.slice(0, 2)}h ${testInput.slice(2, 4)}m ${testInput.slice(4, 6)}s`;
  prevEnteredSpeed.current = newValue;
  return newValue;
}

