import React, { useEffect, useState } from 'react';
import { getFilteredTestsData } from '../../../model';
import ButtonText from '../../buttons/buttonText';
import TestsDropdownSelect from './TestsDropdownSelect';

import './TestsFilters.scss';

function TestsFilters({ testsData, setFilteredTestData, reset, setReset }) {
  const [byConcept, setByConcept] = useState('');
  const [byTeacher, setByTeacher] = useState('');
  const [byStatus, setByStatus] = useState('');
  const [byScore, setByScore] = useState('');
  const [bySpeed, setBySpeed] = useState('');
  const [byClasses, setByClasses] = useState('');

  const conceptsData = testsData.map(({ concept }) => concept.toLowerCase());
  const teachersData = testsData
    .map(({ teachers }) => teachers
      .filter(item => item.isArchive !== 1)
      .map(({ firstName, lastName }) =>
        `${firstName} ${lastName}`)).flat();
  const statusesData = testsData.map(({ status }) => status.toLowerCase());
  const scoresData = ['90%+', '80 - 89%', '50 - 79%', 'BELOW 50%', 'NO DATA YET'];
  const speedData = ['ABOVE EXP.', 'AS EXP.', 'BELOW EXP.', 'NO DATA YET'];
  const classesData = testsData
    .map(({ classes }) => classes
      .map(({ grade, className }) =>
        `${grade}${className}`)).flat();

  useEffect(() =>
    (byConcept === '' && byTeacher === '' && byStatus === '' &&
      byScore === '' && bySpeed === '' && byClasses === '')
      ? setFilteredTestData(testsData)
      : setFilteredTestData(getFilteredTestsData(
        testsData, byConcept, byTeacher, byStatus,
        byScore, bySpeed, byClasses)),
    [
      byClasses,
      byConcept,
      byScore,
      bySpeed,
      byStatus,
      byTeacher,
      setFilteredTestData,
      testsData]);

  return (
    <div className="filters">
      <TestsDropdownSelect
        arrayOfItems={conceptsData}
        defaultTitle="all concepts"
        setFilterKey={setByConcept}
        reset={reset}
        setReset={setReset}
      />
      <TestsDropdownSelect
        arrayOfItems={teachersData}
        defaultTitle="all teachers"
        setFilterKey={setByTeacher}
        reset={reset}
        setReset={setReset}
      />
      <TestsDropdownSelect
        arrayOfItems={statusesData}
        defaultTitle="all statuses"
        setFilterKey={setByStatus}
        reset={reset}
        setReset={setReset}
      />
      <TestsDropdownSelect
        arrayOfItems={scoresData}
        defaultTitle="Av.Score"
        setFilterKey={setByScore}
        reset={reset}
        setReset={setReset}
      />
      <TestsDropdownSelect
        arrayOfItems={speedData}
        defaultTitle="Av.Speed"
        setFilterKey={setBySpeed}
        reset={reset}
        setReset={setReset}
      />
      <TestsDropdownSelect
        arrayOfItems={classesData}
        defaultTitle="All Classes"
        setFilterKey={setByClasses}
        reset={reset}
        setReset={setReset}
      />
      <ButtonText
        type={`4`}
        text={`Clear all`}
        iconName={`Clear`}
        active={true}
        funtionality={() => {
          setByConcept('');
          setByTeacher('');
          setByStatus('');
          setByScore('');
          setBySpeed('');
          setByClasses('');
          setReset(true);
        }}
      />
    </div>
  );
}

export default TestsFilters;
