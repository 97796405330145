import React, { useState, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import styles from './StudentsParentFormFields.module.scss';
import { OutlinedTextField } from '../../../../../components/OutlinedTextField/OutlinedTextField';
import { SelectTypeahead } from '../../../../../components/SelectTypeahead/SelectTypeahead';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SelectParents from '../../../../../components/SelectParents/SelectParents';
import { formatPhoneNumber } from '../../../../../model/formatPhoneNumber';


export const StudentsParentFormFields = ({
  register,
  errors,
  control,
  getValues,
}) => {
  const [parentsCounter, setParentsCounter] = useState(getValues('parents') ? getValues('parents') : []);
  const [phoneValue, setPhoneValue] = useState(formatPhoneNumber(''));

  const [newParent, setNewParent] = useState({
    firstName: getValues('newParentFirstName'),
    lastName: getValues('newParentLastName'),
    email: getValues('newParentEmail'),
    phone: getValues('newParentPhone'),
  });

  const [isNewParentEmpty, setIsNewParentEmpty] = useState(0)

  useEffect(() => {
    if (parentsCounter.length === 2){
      setIsNewParentEmpty(0);
    } else {
      setIsNewParentEmpty(Object.values(newParent).filter(item => item !== '' && item !== undefined).length)
    }
  }, [newParent, parentsCounter])

  return (
    <div className={styles.fieldSet}>
      <Typography
        className={styles.text}
        gutterBottom
        paragraph
      >
        2/2 Parents information (optional)
      </Typography>

      <SelectParents
        className={styles.formField}
        control={control}
        setParentsCounter={setParentsCounter}
        defaultClasses={getValues('parents') ? getValues('parents') : []}
      />

      <Typography
        className={styles.newText}
        gutterBottom
        paragraph
        disabled={parentsCounter?.length === 2}
      >
        Create new
      </Typography>

      <div
        className={styles.inputWrapper}
        key={parentsCounter.length + 1}
        disabled={parentsCounter?.length === 2}>
        <OutlinedTextField
          {...register('newParentFirstName', {
            onChange: (e) => {
              const newObj = Object.assign({}, newParent)
              newObj.firstName = e.target.value
              setNewParent(newObj)
            },
            required: parentsCounter.length === 0 || !!isNewParentEmpty ? 'Required Field' : false,
          })}
          fullWidth
          // defaultValue={defaultValues ? defaultValues?.firstName : ''}
          label="First Name"
          placeholder={'Enter First Name'}
          className={styles.formField}
        >
        </OutlinedTextField>

        {!!isNewParentEmpty && (
          <div className={styles.textHelper}>
            {errors?.newParentFirstName &&
              (
                <>
                  <p>{errors?.newParentFirstName?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
          </div>
        )}
      </div>

      <div
        key={parentsCounter.length + 2}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 2}>
        <OutlinedTextField
          {...register('newParentLastName', {
            onChange: (e) => {
              const newObj = Object.assign({}, newParent)
              newObj.lastName = e.target.value
              setNewParent(newObj)
            },
            required: parentsCounter.length === 0 || !!isNewParentEmpty ? 'Required Field' : false,
          })}
          fullWidth
          // defaultValue={defaultValues ? defaultValues?.firstName : ''}
          label="Last Name"
          placeholder={'Enter Last Name'}
          className={styles.formField}
        >
        </OutlinedTextField>

        {!!isNewParentEmpty && (
          <div className={styles.textHelper}>
            {errors?.newParentLastName &&
              (
                <>
                  <p>{errors?.newParentLastName?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
          </div>
        )}

      </div>

      <div
        key={parentsCounter.length + 3}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 2}>
        <OutlinedTextField
          key={parentsCounter.length}
          {...register('newParentEmail', {
            onChange: (e) => {
              const newObj = Object.assign({}, newParent)
              newObj.email = e.target.value
              setNewParent(newObj)
            },
            required: parentsCounter.length === 0 || !!isNewParentEmpty ? 'Required Field' : false,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "Entered value does not match email format"
            }
          })}
          fullWidth
          // defaultValue={defaultValues ? defaultValues?.firstName : ''}
          label="Email"
          placeholder={'XXXXXXX@XXXX.XX'}
          className={styles.formField}
        >
        </OutlinedTextField>
        {!!isNewParentEmpty && (
          <div className={styles.textHelper} disabled={parentsCounter?.length === 2}>
            {errors?.newParentEmail &&
              (
                <>
                  <p>{errors?.newParentEmail?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
          </div>
        )}

      </div>

      <div
        key={parentsCounter.length + 4}
        className={styles.inputWrapper}
        disabled={parentsCounter?.length === 2}>
        <OutlinedTextField
          key={parentsCounter.length}
          {...register('newParentPhone', {
            onChange: (e) => {
              const newObj = Object.assign({}, newParent)
              newObj.phone = e.target.value
              setNewParent(newObj)
            },
            required: parentsCounter.length === 0 || !!isNewParentEmpty ? 'Required Field' : false,
            pattern: {
              value: /[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{4}/,
              message: "Entered value does not match phone format"
            }
          })}
          fullWidth
          // defaultValue={defaultValues ? defaultValues?.firstName : ''}
          value={phoneValue}
          onInput={(e) => { setPhoneValue(formatPhoneNumber(e.target.value)) }}
          label="Phone"
          placeholder={'(XXX) XXX-XXXX'}
        >
        </OutlinedTextField>
        {!!isNewParentEmpty && (
          <div
            style={{ bottom: '5px' }}
            className={styles.textHelper}>
            {errors?.newParentPhone &&
              (
                <>
                  <p>{errors?.newParentPhone?.message || 'Error!'}</p>
                  <InfoOutlinedIcon />
                </>
              )}
          </div>
        )}

      </div>

      {/* <Typography
          className={styles.createNewText}
          gutterBottom
          paragraph
          onClick={nextStep}
        >
          One More Parent
        </Typography>  
       */}
    </div>
  );
};
