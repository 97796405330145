import { TableHeadLine } from '../../components/TableHeadLine/TableHeadLine';
import { Table } from '../../components/Table/Table';
import { Pagination } from '../../components/Pagination/Pagination';

import './TableForAnalyticPage.scss';

// const typeTableRow = {
//   tableFor: 'allStudentsAnalytics',
//   tableCellWidth: `20px 300px 96px 40px 100px 112px 384px 104px `,
//   tableWidth: '1360px',
//   // gridTemplateColum: '12px',
//   gridGap: '23px',
//   rowPaddingLeft: '20px',
//   //  background: '#F2F2F2' ,
//   height: '48px',
//   boxShadow: 'none',
//   fontSize: '1.6rem',
// };

export const TableForAnalyticPage = ({
  filterAverageScore,
  filterAverageSpeed,
  pageSize,
  sortName,
  sortDirection,
  setPageSize,
  currentPage,
  setCurrentPage,
  totalCount,
  setTotalCount,
  tableDataStudents,
  sortFunctionForName,
  sortFunctionForId,
  sortFunctionForScore,
  sortFunctionForSpeed,
  setFilterAverageScore,
  setFilterAverageSpeed,
  callAlert,
  closeAlert,
}) => {
  //  if we select filterAverageScore filterAverageSpeed, above table appear prompt, which filter do we choose with appropriate color
  // code bellow make variables, which we share with <TableHeadLine /> to show the prompt
  const headerForAvScore =
    filterAverageScore === '0-50'
      ? ' below 50% accuracy  '
      : filterAverageScore === '50-79'
        ? ' 50 - 79% accuracy '
        : filterAverageScore === '80-89'
          ? ' 80 - 89% accuracy'
          : filterAverageScore === '90-100'
            ? ' 90%+ accuracy'
            : null;
  const headerForSpeed =
    filterAverageSpeed === -1
      ? ' Below expected speed  '
      : filterAverageSpeed === 0
        ? ' As expected speed '
        : filterAverageSpeed === 1
          ? ' Above expected speed'
          : null;
  const headerColorForAvScore =
    filterAverageScore === '0-50'
      ? '#FB463B '
      : filterAverageScore === '50-79'
        ? '#E2B534'
        : filterAverageScore === '80-89'
          ? '#0F9D58'
          : filterAverageScore === '90-100'
            ? '#4285F4'
            : null;
  const headerColorForAvSpeed =
    filterAverageSpeed === -1
      ? '#FB463B'
      : filterAverageSpeed === 0
        ? '#0F9D58'
        : filterAverageSpeed === 1
          ? '#4285F4'
          : null;

  // make object that we share with  <TableHeadLine /> aas props
  const headlineType = {
    titleName: 'students',
    speedTitle: headerForSpeed,
    speedColor: headerColorForAvSpeed,
    scoreTitle: headerForAvScore,
    scoreColor: headerColorForAvScore,
    resetButton: () => {
      setFilterAverageScore(null);
      setFilterAverageSpeed(null);
    },
    // input : false
    inputPlaceHolder: 'Enter Student Name, Teacher Name or Test Label',
    buttonText: 'Export csv',

    isInput: false,
    isExportButton: false,
    isExportCsv: false,
  };
  // make object that we share with  <TableHead /> aas props
  const tableHeadForAnalitics = [
    {
      fieldName: '',
      width: '20px',
      sortByLetters: false,
      sortByScore: false,
      sortByStatus: false,
    },
    {
      fieldName: 'Name',
      width: '300px',
      sortByLetters: true,
      sortByNum: false,
      functionality: sortFunctionForName,
    },
    {
      fieldName: 'ID',
      width: '96px',
      sortByLetters: false,
      sortByNum: true,
      functionality: sortFunctionForId,
    },
    {
      fieldName: 'Class',
      width: '41px',
      sortByLetters: false,
      sortByScore: false,
      sortByStatus: false,
    },
    {
      fieldName: 'Av. score, %',
      width: '103px',
      sortByScore: true,
      sortByStatus: false,
      sortByLetters: false,
      functionality: sortFunctionForScore,
    },
    {
      fieldName: 'Av. speed',
      width: '118px',
      sortByScore: false,
      sortByStatus: true,
      sortByLetters: false,
      functionality: sortFunctionForSpeed,
    },
    {
      fieldName: 'Parents',
      width: '400px',
      sortByLetters: false,
      sortByScore: false,
      sortByStatus: false,
    },
  ];
  const showHeadLine = filterAverageSpeed || filterAverageSpeed === 0 || filterAverageScore ? true : false;
  return (
    <div className="ableForAnalyticPage_wrapper">
      {showHeadLine ?
        <TableHeadLine headlineType={headlineType} />
        :
      <div className='analyticsTableTitle'>
        ALL STUDENTS
      </div>
       }

      <Table
        callAlert={callAlert}
        closeAlert={closeAlert}
        sortName={sortName}
        sortDirection={sortDirection}
        tableRowData={tableDataStudents}
        typeTableHead={tableHeadForAnalitics}
        forPage={'analytics'} />

      <Pagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalCount={totalCount}
        setTotalCount={setTotalCount}
      />
    </div>
  );
};
