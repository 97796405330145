import { useState, useEffect } from 'react';
import { TableRow } from '../TableRow/TableRow';
import { TableCell } from '../TableCell/TableCell';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TeacherInfoPopOver } from '../TeacherInfoPopOver/TeacherInfoPopOver';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { convertSecondsToString } from '../../model/convertSecondsToString';

import './TableRowAllTestsAnalytics.scss';
import LightTooltip from '../LightTooltip';

const colors = ['#4285F4', '#0F9D58', '#E2B534', '#DB4437'];
export const TableRowAllTestsAnalytics = ({ typeTableRow, test, index, forPage }) => {
  // console.log('test', test);

  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openTeacherInfo = Boolean(iconAnchorEl);
  const handleOpenInfoTeacher = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setIconAnchorEl(null);

  const join = test.teachers
    .filter((item) => item.isArchive !== 1)
    .map((teacher) => {
      return teacher.name;
    })
    .join(', ');
  // console.log('join', join);

  const [speedColor, setSpeedColor] = useState('');
  useEffect(() => {
    if (test.speed > test.maxSpeed) {
      setSpeedColor(colors[3]);
    } else if (test.speed === test.maxSpeed) {
      setSpeedColor(colors[1]);
    } else if (test.speed < test.maxSpeed) {
      setSpeedColor(colors[0]);
    } else {
      setSpeedColor('');
    }
  }, [test.speed, test.maxScore]);

  const [scoreColor, setScoreColor] = useState('');
  useEffect(() => {
    if (test.score >= test.maxScore * 0.9) {
      setScoreColor(colors[0]);
    } else if (test.score >= test.maxScore * 0.8) {
      setScoreColor(colors[1]);
    } else if (test.score >= test.maxScore * 0.5) {
      setScoreColor(colors[2]);
    } else if (test.score < test.maxScore * 0.5) {
      setScoreColor(colors[3]);
    } else {
      setScoreColor('');
    }
  }, [test.score, test.maxScore]);

  const tooltipInfo = `Exp.Score: ${test.maxScore} / Exp.Speed: ${convertSecondsToString(
    test.maxSpeed
  )}`;

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <TableRow typeTableRow={typeTableRow} index={index}>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LightTooltip title={tooltipInfo} placement="top">
              <div className="tableRowStudentAnalytics_svg">
                <InfoOutlinedIcon style={{ cursor: 'pointer', marginRight: 5 }} />
              </div>
            </LightTooltip>
            {test.label}
          </div>
        </TableCell>
        <TableCell>{test.concept}</TableCell>
        {/* TEACHERS */}
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }} className="">
            <div className="tableRowStudentAnalytics_svg">
              <InfoOutlinedIcon onClick={handleOpenInfoTeacher} />
            </div>
            <Popover
              open={openTeacherInfo}
              onClose={handleCloseInfoTeacher}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorEl={iconAnchorEl}
              elevation={2}
            >
              <Typography component={'span'} variant={'body2'}>
                <TeacherInfoPopOver teachers={test.teachers} />
              </Typography>
            </Popover>

            {join.length >= 30 ? join.slice(0, 25) + '...' : join}
          </div>
        </TableCell>
        {/* SCORE & SPEED */}
        {test?.absent === 0 && (
          <>
            <TableCell color={test?.score ? scoreColor : ''}>
              {test?.score ? test.score : 'No Results'}
            </TableCell>
            <TableCell color={test?.speed ? speedColor : ''}>
              {test?.speed ? convertSecondsToString(test.speed) : 'No Results'}
            </TableCell>
          </>
        )}
        {test?.absent === 1 && (
          <>
            <TableCell>
              <span style={{ color: '#AAABB3' }}>Absent</span>
            </TableCell>
            <TableCell>
              <span style={{ color: '#AAABB3' }}>Absent</span>
            </TableCell>
          </>
        )}
        {test?.absent === null && (
          <>
            <TableCell>
              <span style={{ color: '#AAABB3' }}>No Results</span>
            </TableCell>
            <TableCell>
              <span style={{ color: '#AAABB3' }}>No Results</span>
            </TableCell>
          </>
        )}
      </TableRow>
    </div>
  );
};
