import { useState } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { SecondaryButton } from '../../../../../components/buttons/SecondaryButton/SecondaryButton';
import { ButtonPrimary } from '../../../../../components/buttons/buttonPrimary/ButtonPrimary';
import { ModalWrapper } from '../../../../../components/Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../components/Modal/ModalHeader/ModalHeader';
import { routes } from '../../../../../routes'
import { config } from '../../../../../config';
import './ArchiveClassModal.scss';


export const ArchiveClassModal = ({
  onClose,
  activeClasses,
  loadDataForGradeBook,
  unSetAllGradesActive,
  closeEditModal
  }) => {
  const history = useHistory();

  const [successfulRequest, setSuccessfulRequest] = useState(null);

  const handleSendToArchiveClasses = async () => {
    const dataToSend = {
      ids: activeClasses,
    };
    try {
      const data = await axios.post(`${config.API_URL}/api/gradebook/archive`, dataToSend);
      if (data) {
        setSuccessfulRequest(true);
      }
    } catch (e) {
      console.log(e);
      setSuccessfulRequest(false);
    }
  };

  const handleSendToArchiveTrue = () => {
      onClose();
      setSuccessfulRequest(null);
      loadDataForGradeBook();
      closeEditModal();
      history.push(routes.gradebooks());
      unSetAllGradesActive();
    };
  

  return (
    <>
      {successfulRequest === null && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <ModalHeader header="Archive Class(es)" color="darkHeader" onClose={onClose} />
              <div className="upgradeClassModal_tooltipWrapper">
                Are you sure you want to archive <span>class(es)</span> ?
              </div>
              <div className="upgradeClassModal_smallTooltipWrapper">
                *All students and test results will be archived.
              </div>
              <div style={{ width: 250 }} className="upgradeClassModal_buttonsWrapper">
                <div className="">
                  <SecondaryButton status={'cancel'} active={true} functionality={onClose} />
                </div>

                <div className="">
                  <ButtonPrimary
                    status={'archive'}
                    active={true}
                    functionality={handleSendToArchiveClasses}
                  />
                </div>
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
      {successfulRequest === false && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <ModalHeader
                header="Archivation Error"
                color="darkHeader"
                onClose={() => {
                  onClose();
                  setSuccessfulRequest(null);
                }}
              />
              <div className="upgradeClassModal_tooltipWrapper">
                Something went wrong. Please try again later.
              </div>
              <div style={{ height: 40, marginTop: 20, marginLeft: 80 }} className="">
                <ButtonPrimary
                  status={'ok'}
                  active={true}
                  functionality={() => {
                    onClose();
                    setSuccessfulRequest(null);
                  }}
                />
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
      {successfulRequest === true && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <div className="upgradeClassModal_headerWrapper">
                <div
                  style={{ marginLeft: 37, color: '#A6192E' }}
                  className="upgradeClassModal_header"
                >
                  Successfully Archived
                </div>
              </div>

              <div style={{ height: 40, marginTop: 30, marginLeft: 80 }} className="">
                <ButtonPrimary
                  status={'okRed'}
                  active={true}
                  functionality={handleSendToArchiveTrue}
                />
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
    </>
  );
};
