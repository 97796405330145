import './TableColorLegend.scss';

export const TableColorLegend = () => {
  return (
    <div className="classGradeBook_gradeBook_colorLegendWrapper_container">
      <div className="classGradeBook_gradeBook_colorLegendWrapper">
        <div className="tableColorLegend_wrapper">
          <div className="tableColorLegend_scoreWrapper">
            <span style={{ fontSize: 14 }}>
              <b>*SCORE</b>
            </span>
            <div style={{ display: 'flex' }}>
              <div className="tableColorLegend_colorBlock blueLegend"></div>
              <span className="tableColorLegend_blueText">90%+ ACCURACY</span>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="tableColorLegend_colorBlock greenLegend"></div>
              <span className="tableColorLegend_greenText">80 - 89% ACCURACY</span>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="tableColorLegend_colorBlock yellowLegend"></div>
              <span className="tableColorLegend_yellowText">50 - 79% ACCURACY</span>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="tableColorLegend_colorBlock redLegend"></div>
              <span className="tableColorLegend_redText">BELOW 50% ACCURACY</span>
            </div>
          </div>

          <div className="tableColorLegend_speedWrapper">
            <span style={{ fontSize: 14 }}>
              <b>*SPEED</b>
            </span>
            <div style={{ display: 'flex' }}>
              <div className="tableColorLegend_colorBlock blueLegend"></div>
              <span className="tableColorLegend_speed_blueText">ABOVE EXPECTED</span>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="tableColorLegend_colorBlock greenLegend"></div>
              <span className="tableColorLegend_speed_greenText">AS EXPECTED</span>
            </div>
            <div style={{ display: 'flex' }}>
              <div className="tableColorLegend_colorBlock redLegend"></div>
              <span className="tableColorLegend_speed_redText">BELOW EXPECTED</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
