import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    color: 'rgba(91, 91, 91, 1)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export default LightTooltip;
