import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useEffect, useState, useRef } from 'react';
import { ModalFooter } from '../../Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { formatSubmitTestInput } from '../../../model/formatSubmitTestInput';
import { config } from '../../../config';

const colors = ['#4285F4', '#0F9D58', '#E2B534', '#DB4437']
const requestFetch = (url, method, body = null) => {
  const headers = {
    'Content-Type': 'application/json'
  }

  return fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: headers
  }).then(response => {
    if (response.ok) {
      return response
    }

    return response.json().then(error => {
      const e = new Error('Smth gone wrong')
      e.data = error
      throw e
    })
  });
}

export default function SubmitTestForm({
  close,
  testsData,
  setLoader,
  setIsAlert,
  setTableDataUpdated,
}) {
  const prevEnteredHours = useRef('');
  const prevEnteredMinutes = useRef('');
  const prevEnteredSeconds = useRef('');

  const [selectedTest, setSelectedTest] = useState('');

  const handleChange = (event) => {
    setSelectedTest(event.target.value);
  };

  const [filledAll, setFilledAll] = useState(false);

  const [checkBox, setCheckBox] = useState(false);
  const [scoreValue, setScoreValue] = useState('');
  const [scoreColor, setScoreColor] = useState('');

  const [speedValue, setSpeedValue] = useState();
  const [speedColor, setSpeedColor] = useState('');

  const [hoursValue, setHoursValue] = useState('');
  const [minutesValue, setMinutesValue] = useState('');
  const [secondsValue, setSecondsValue] = useState('');

  useEffect(() => {
    if (hoursValue.length === 3 && minutesValue.length === 3 && secondsValue.length === 3) {
      const hours = +hoursValue.replace(/[^\d]/g, '') * 3600;
      const minutes = +minutesValue.replace(/[^\d]/g, '') * 60;
      const seconds = +secondsValue.replace(/[^\d]/g, '');

      const totalSpeed = hours + minutes + seconds;
      setSpeedValue(totalSpeed);

      if (totalSpeed > selectedTest.maxSpeed) {
        setSpeedColor(colors[3]);
      } else if (totalSpeed === selectedTest.maxSpeed) {
        setSpeedColor(colors[1]);
      } else if (totalSpeed < selectedTest.maxSpeed) {
        setSpeedColor(colors[0]);
      } else {
        setSpeedColor('');
      }
    }

    if (
      hoursValue.length === 3 &&
      minutesValue.length === 3 &&
      secondsValue.length === 3 &&
      scoreValue.length !== 0
    ) {
      setFilledAll(true);
      setCheckBox(false);
    } else {
      setFilledAll(false);
    }
  }, [
    hoursValue,
    hoursValue.length,
    minutesValue,
    minutesValue.length,
    scoreValue.length,
    secondsValue,
    secondsValue.length,
    selectedTest.maxSpeed,
  ]);

  useEffect(() => {
    if (scoreValue >= selectedTest.maxScore * 0.9) {
      setScoreColor(colors[0]);
    } else if (scoreValue >= selectedTest.maxScore * 0.8) {
      setScoreColor(colors[1]);
    } else if (scoreValue >= selectedTest.maxScore * 0.5) {
      setScoreColor(colors[2]);
    } else if (scoreValue < selectedTest.maxScore * 0.5) {
      setScoreColor(colors[3]);
    } else {
      setScoreColor('');
    }
  }, [scoreValue, selectedTest.maxScore]);

  const onHandleScoreInput = (e) => {
    if (e.target.value <= selectedTest.maxScore) {
      setScoreValue(formatSubmitTestInput(e.target.value));
    } else {
      setIsAlert({
        type: false,
        text: `Max Expected Score result is ${selectedTest.maxScore}. Student can't earn more points.`,
        close: () =>
          setIsAlert({
            type: false,
            text: '',
            close: () => {},
            show: false,
          }),
        show: true,
      });
      return;
    }
  };

  const onSubmit = async () => {
    setLoader(true);
    const url = `${config.API_URL}/api/testresult/submit`;
    const method = 'PATCH';
    const data = {
      testId: selectedTest.testId,
      studentId: selectedTest.studentId,
      score: checkBox ? 0 : +scoreValue,
      speed: checkBox ? 0 : speedValue,
      absent: checkBox ? 1 : 0,
    };

    await requestFetch(url, method, data)
      .then((data) => {
        console.log(data);
        setIsAlert({
          type: true,
          text: 'Results successfully submited',
          close: () =>
            setIsAlert({
              type: false,
              text: '',
              close: () => {},
              show: false,
            }),
          show: true,
        });
        setTableDataUpdated(Date.now());
      })
      .catch((err) => {
        console.log(err);
        setIsAlert({
          type: false,
          text: 'Oops, server error. Try again later',
          close: () =>
            setIsAlert({
              type: false,
              text: '',
              close: () => {},
              show: false,
            }),
          show: true,
        });
      });

    setLoader(false);
    close();
  };

  return (
    <form
      className="submit-test__form"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Enter or Select Test</InputLabel>
          <Select
            className="submit-test__select"
            sx={{ borderRadius: 0 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedTest}
            label="Enter or Select Test"
            onChange={handleChange}
          >
            {testsData.map((item, index) => {
              const date = new Date(item.date);
              return (
                <MenuItem value={item}>
                  {item.label +
                    ` (${date.toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })})`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {!selectedTest ? (
          <div className="input-noData" />
        ) : (
          <div className={`input-group ${filledAll && 'filledAll'}`}>
            <div className="input-group__score">
              <label>Score</label>
              <input
                value={scoreValue}
                style={{ color: scoreColor }}
                onInput={onHandleScoreInput}
              />
            </div>

            <div className="input-group__speed">
              <label>Speed</label>
              <div className="input-group__speedBox">
                <input
                  style={{ color: speedColor }}
                  value={hoursValue}
                  onInput={(e) => {
                    setHoursValue(formatSubmitTestInput(e.target.value, 'H', prevEnteredHours));
                  }}
                />
                :
                <input
                  style={{ color: speedColor }}
                  value={minutesValue}
                  onInput={(e) => {
                    setMinutesValue(formatSubmitTestInput(e.target.value, 'M', prevEnteredMinutes));
                  }}
                />
                :
                <input
                  style={{ color: speedColor }}
                  value={secondsValue}
                  onInput={(e) => {
                    setSecondsValue(formatSubmitTestInput(e.target.value, 'S', prevEnteredSeconds));
                  }}
                />
              </div>
            </div>

            <div className="input-group__absent">
              <label>Absent</label>
              <div
                className={`checkBox input-group__checkbox ${filledAll && 'disabled'}`}
                onClick={() => setCheckBox(!checkBox)}
              >
                {checkBox ? (
                  <CheckBoxTwoToneIcon style={{ color: '#323232' }} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={{ color: !filledAll ? '#5B5B5B' : '#f2f2f2' }} />
                )}
              </div>
            </div>
          </div>
        )}

        <ModalFooter>
          <ModalSecondaryButton onClick={close}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton typeBtn="submit" disabled={!checkBox && !filledAll}>
            Submit
          </ModalPrimaryButton>
        </ModalFooter>
      </Box>
    </form>
  );
}