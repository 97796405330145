// import noTestsImage from '../../../images/noTestsContent.svg';
// import noStudentsImg from '../../../images/noStudentsImg.png';
// import arrowImage from '../../../images/arrow.svg';
import './NoStudentsFirstColumn.scss';

const NoStudentsFirstColumn = () => {
  return (
    <>
      {/* first student column */}
      <div style={{ display: 'flex', flexDirection: "column" }}>
      <div className="classGradeBook_gradeBook_studentsInfo_container">
        <div className="classGradeBook_gradeBook_table_headerForStudent">Students (0)</div>
      </div>

        <div className="classGradeBook_NoData_gradeBook_table_Wrapper_281">
          <p className="classGradeBook_NoData_gradeBook_table_Row_white_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_grey_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_white_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_grey_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_white_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_grey_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_white_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_grey_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_white_281"></p>
          <p className="classGradeBook_NoData_gradeBook_table_Row_grey_281"></p>
        </div>
      </div>
    </>
  );
};

export default NoStudentsFirstColumn;
