import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SchoolInfoPopover } from './SchoolInfoPopover/SchoolInfoPopover';
import { ReactComponent as PlusIcon } from './plus-icon.svg';
import { LoaderFullBook } from '../Loaders/LoaderFullBook/LoaderFullBook';
import { AppContext } from '../../context/AppContext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddSchoolModal from './SchoolModal/AddSchoolModal/AddSchoolModal';
import EditSchoolModal from './SchoolModal/EditSchoolModal/EditSchoolModal';
import RemoveSchoolModal from './SchoolModal/RemoveSchoolModal/RemoveSchoolModal';
import classNames from 'classnames';
import styles from './SchoolSelect.module.scss';
import { Alert } from '../Alert/Alert';
import { useLocation, useHistory } from 'react-router-dom';


export const SchoolSelect = () => {
  const {
    showLoader,
    setShowLoader,
    schools = [],
    updateSchools,
    currentSchool,
    currentSchoolId,
    setCurrentSchoolId,
    updateCurrentSchoolId,
    getSchoolsData,
    updateSchoolName,
    setUpdateSchoolInfo,
  } = useContext(AppContext);

  let location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  const [mouseOverSchoolId, setMouseOverSchoolId] = useState(null);
  // console.log('mouseOverSchoolId', mouseOverSchoolId);
  // console.log('currentSchoolId', currentSchoolId)
  // console.log('currentSchool', currentSchool)
  const mouseOverSchool = schools.find((school) => school.Id === mouseOverSchoolId);
  const [selectedSchool, setSelectedSchool] = useState({});

  useEffect(() => {
    if ( mouseOverSchool !== undefined) {
      setSelectedSchool(mouseOverSchool);
    }
  }, [mouseOverSchool]);
// console.log('selectedSchool', selectedSchool);

  const handleSchoolMouseOver = useCallback((schoolId) => () => setMouseOverSchoolId(schoolId), []);
  const handleSchoolMouseOut = useCallback(() => setMouseOverSchoolId(null), []);

  const handleChooseSchool = (schoolId) => () => {
    schools.find(({ Id }) => Id === schoolId);
    setCurrentSchoolId(schoolId);
    if (location.pathname === '/gradebook/class') {
      history.push('/gradebooks');
    }
    if (location.pathname === '/archive/class') {
      history.push('/archive');
    }
    if (location.pathname === '/analytics') {
      if (queryParams.has('classId')) {
        queryParams.delete('classId');
        queryParams.delete('class');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
    localStorage.setItem('currentSchoolId', JSON.stringify(schoolId));
  };

  // === ADD SCHOOL ===
  const [addSchoolModal, setAddSchoolModal] = useState(false);
  const handleAddSchoolModal = () => setAddSchoolModal(true);
  const handleAddSchoolModalClose = () => setAddSchoolModal(false);

  // === EDIT SCHOOL ===
  const [editSchoolModal, setEditSchoolModal] = useState(false);
    
  const handleEditSchoolModal = () => {
    setEditSchoolModal(true);
    setCurrentSchoolId(mouseOverSchoolId);
    if (location.pathname === '/gradebook/class') {
      history.push('/gradebooks');
    }
    if (location.pathname === '/archive/class') {
      history.push('/archive');
    }
    if (location.pathname === '/analytics') {
      if (queryParams.has('classId')) {
        queryParams.delete('classId');
        queryParams.delete('class');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
    localStorage.setItem('currentSchoolId', JSON.stringify(mouseOverSchoolId));
  };

  const handleEditSchoolModalClose = () => {
    setEditSchoolModal(false);
    setMouseOverSchoolId(null);
  };

  // === REMOVE SCHOOL ===
  const [removeSchoolModal, setRemoveSchoolModal] = useState(false);
  
  const handleRemoveSchoolModal = () => {
    setRemoveSchoolModal(true);
    setCurrentSchoolId(mouseOverSchoolId);
    if (location.pathname === '/gradebook/class') {
      history.push('/gradebooks');
    }
    if (location.pathname === '/archive/class') {
      history.push('/archive');
    }
    if (location.pathname === '/analytics') {
      if (queryParams.has('classId')) {
        queryParams.delete('classId');
        queryParams.delete('class');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
  };
  
  const handleRemoveSchoolModalClose = () => {
    setRemoveSchoolModal(false);
    setMouseOverSchoolId(null);
  };

  // === ALERT ===
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => {},
    show: false,
  });

  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(
    () =>
      setIsAlert({
        type: false,
        text: '',
        close: () => {},
        show: false,
      }),
    []
  );

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <div
        id="SchoolSelectContainer"
        className={styles.container}
        onMouseLeave={handleSchoolMouseOut}
      >
        <div className={styles.currentSchoolName}>
          {currentSchool?.Name ? currentSchool.Name : 'School'}
        </div>

        <div className={styles.optionsContainer}>
          <div
            className={classNames(styles.option, styles.addSchoolOption)}
            onClick={() => handleAddSchoolModal()}
          >
            <PlusIcon />
            <span className={styles.addSchoolOptionLabel}>add new</span>
          </div>

          {schools.map(({ Id = 1, Name }) => (
            <div
              key={Id}
              onClick={handleChooseSchool(Id)}
              onMouseOver={handleSchoolMouseOver(Id)}
              className={styles.option}
            >
              {Name}
            </div>
          ))}
        </div>

        <ArrowDropDownIcon color="disabled" fontSize="large" className={styles.arrowDown} />

        {mouseOverSchool && (
          <SchoolInfoPopover
            school={mouseOverSchool}
            mouseOverSchoolId={mouseOverSchoolId}
            setCurrentSchoolId={setCurrentSchoolId}
            handleEditSchool={handleEditSchoolModal}
            handleRemoveSchool={handleRemoveSchoolModal}
          />
        )}
      </div>
      {addSchoolModal && (
        <AddSchoolModal
          onClose={handleAddSchoolModalClose}
          schools={schools}
          updateSchools={updateSchools}
          setShowLoader={setShowLoader}
          callAlert={callAlert}
          closeAlert={closeAlert}
        />
      )}
      {editSchoolModal && (
        <EditSchoolModal
          onClose={handleEditSchoolModalClose}
          // selectedSchool={selectedSchool}
          currentSchool={selectedSchool}
          schools={schools}
          updateSchools={updateSchools}
          setShowLoader={setShowLoader}
          setCurrentSchoolId={setCurrentSchoolId}
          // mouseOverSchool={mouseOverSchool}
          // mouseOverSchoolId={mouseOverSchoolId}
          setUpdateSchoolInfo={setUpdateSchoolInfo}
          callAlert={callAlert}
          closeAlert={closeAlert}
        />
      )}
      {removeSchoolModal && (
        <RemoveSchoolModal
          onClose={handleRemoveSchoolModalClose}
          currentSchool={currentSchool}
          setCurrentSchoolId={setCurrentSchoolId}
          schools={schools}
          updateSchools={updateSchools}
          setShowLoader={setShowLoader}
          callAlert={callAlert}
          closeAlert={closeAlert}
        />
      )}

      {isAlert.show && <Alert alertType={isAlert.type} text={isAlert.text} close={isAlert.close} />}
    </>
  );
};


