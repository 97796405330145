import { useCallback, useContext, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { OutlinedTextField } from '../../../../components/OutlinedTextField/OutlinedTextField';
import { IncorrectPasswordPopover } from '../../components/IncorrectPasswordPopover/IncorrectPasswordPopover';
import { AppContext } from '../../../../context/AppContext';
import { LoaderFullBook } from '../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import styles from './SignInForm.module.scss';
import { IncorrectEmailPopover } from '../../components/IncorrectEmailPopover/IncorrectEmailPopover';
import { config } from "../../../../config"
import { Alert } from '../../../../components/Alert/Alert.jsx';

export const SignInForm = () => {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const {showLoader, setShowLoader, setIsAuth, updateUser, setUploadedPhoto}= useContext(AppContext);
  const history = useHistory();

  // open email tooltip
  const [iconAnchorElConfirmEmail, setIconAnchorElConfirmEmail] = useState(null);
  const openConfirmEmail = Boolean(iconAnchorElConfirmEmail);
  const handleOpenConfirmEmail = (event) => setIconAnchorElConfirmEmail(event.currentTarget);
  const handleCloseConfirmEmail = () => setIconAnchorElConfirmEmail(null);

  // open password tooltip
  const [iconAnchorElConfirmPassword, setIconAnchorElConfirmPassword] = useState(null);
  const openConfirmPassword = Boolean(iconAnchorElConfirmPassword);
  const handleOpenConfirmPassword = (event) => setIconAnchorElConfirmPassword(event.currentTarget);
  const handleCloseConfirmPassword = () => setIconAnchorElConfirmPassword(null);

  const togglePasswordVisibility = useCallback(() => {
    setPasswordIsVisible(!passwordIsVisible);
  }, [setPasswordIsVisible, passwordIsVisible]);

  const {
    formState: { errors },
    handleSubmit,
    control
  } = useForm({
    mode: 'onBlur',
  });

  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => {},
    show: false,
  });
  const callAlert = (obj) => setIsAlert(obj)
  const closeAlert = () => setIsAlert({
    type: false,
    text: '',
    close: () => { },
    show: false,
  })

  const defaultUser = {
    contactName: '',
    firstName: 'No Name',
    lastName: '',
    phone: '',
    photo: '', // IN BASE64
    role: 'School Manager',
    email: '',
    isArchive: false,
    id: 0,
  }

  const onSubmit = async (values) => {
    // console.log('errors', errors)    
    // console.log('values', values)    
    
    const dataToSend = {
      email: values.email,
      password: values.password,
    };
    
    setShowLoader(true);
    await axios.post(`${config.API_URL}/api/auth/login`, dataToSend)
    .then((response) => {
      console.log(response)
      const userData = response.data.user;
      console.log('response.data', response.data.user);
      updateUser({
        contactName: `${userData.LastName}, ${userData.FirstName}`,
        firstName: userData.FirstName,
        lastName: userData.LastName,
        phone: userData.Phone,
        photo: userData.Photo, // IN BASE64
        role: userData.Role,
        email: userData.Email,
        isArchive: userData.isArchive,
        id: userData.Id,
        authType:  userData.AuthType
      });
      // history.push('/gradebooks');
      setUploadedPhoto(userData.Photo);
      setIsAuth(true);
      setShowLoader(false);
      if (!userData?.Role) {
        return;
      }
      if (userData.Role === 4) {
        history.push('/parent');
      } else {
        history.push('/gradebooks');
      }
      
    })
    .catch(function (error) {
      if (error.response && error.response.status === 400) {
        callAlert({
          type: false,
          text: 'User or password incorrect',
          close: closeAlert,
          show: true
        });
      }
      console.log(error)
      setIsAuth(false);
      setShowLoader(false);
      updateUser({...defaultUser})
      setUploadedPhoto('');
      history.push('/sign-in');
    });
    // console.log('loginResult', loginResult);
    // console.log('loginResult.data', loginResult.data);
    // console.log('loginResult.data.message', loginResult.data.message);
    // if (loginResult.status === 404) {
    //         alert('Error');
    //         return;
    // }
    // // 
    // if (loginResult.data.message === "Login success") {
    //   // updateUser(loginResult.data.user)
    //   setIsAuth(true);
    //   history.push('/gradebooks');
    // } else{
    // // if (loginResult.status !== 200) {
    // // if (loginResult.data.message === 'User or password incorrect') {
    //   alert('Error');
    //   return;
    // }
  }

  return (
    <div className={styles.root}>
      {showLoader && <LoaderFullBook />}
      <form className={styles.form} onSubmit={handleSubmit((values) => onSubmit(values))}>
        <div className={styles.inputContainer}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
              pattern: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g,
            }}
            render={({ field }) => (
              <OutlinedTextField
                fullWidth
                // id="outlined-basic"
                label="Email"
                style={{ width: 302 }}
                autoComplete="off"
                placeholder="XXXX@XXXX.XX"
                name="email"
                {...field}
                error={Boolean(errors?.email)}
                helperText={errors.email?.message}
              />
            )}
          />
          {errors.email && (
            <span className={styles.singIn_errorInInput} style={{ color: '#DB4437' }}>
              {' '}
              Incorrect email
              <ReportProblemOutlinedIcon
                style={{ cursor: 'pointer', marginLeft: 5 }}
                onClick={(e) => handleOpenConfirmEmail(e)}
              />
              <IncorrectEmailPopover
                visible={openConfirmEmail}
                onClose={handleCloseConfirmEmail}
                anchorElement={iconAnchorElConfirmEmail}
              />
            </span>
          )}
        </div>

        <div className={styles.inputContainer}>
          <Controller
            control={control}
            name="password"
            rules={{
              required: true,
              pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,255}$/,
            }}
            render={({ field }) => (
              <OutlinedTextField
                fullWidth
                type={passwordIsVisible ? 'text' : 'password'}
                id="password"
                autoComplete="off"
                label="Password"
                name="password"
                {...field}
                error={Boolean(errors?.password)}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordIsVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {errors.password && (
            <span className={styles.singIn_errorInInput} style={{ color: '#DB4437' }}>
              Incorrect Password
              <InfoOutlinedIcon
                style={{ cursor: 'pointer', marginLeft: 5 }}
                onClick={(e) => handleOpenConfirmPassword(e)}
              />
              <IncorrectPasswordPopover
                visible={openConfirmPassword}
                onClose={handleCloseConfirmPassword}
                anchorElement={iconAnchorElConfirmPassword}
              />
            </span>
          )}
        </div>

        <div className={styles.actions}>
          <Link
            to="/recoverPassword"
            className={styles.forgotPasswordButton}
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            Forgot password?
          </Link>
          <button type="submit" className={styles.loginButton} onClick={onSubmit}>
            LOGIN
          </button>
        </div>
      </form>

      {isAlert.show && <Alert alertType={isAlert.type} text={isAlert.text} close={isAlert.close} />}
    </div>
  );
};