import { useState } from 'react';
import { TableRow } from '../TableRow/TableRow';
import { TableCell } from '../TableCell/TableCell';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TeacherInfoPopOver } from '../TeacherInfoPopOver/TeacherInfoPopOver';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import './TableRowStudentAnalytics.scss';

export const TableRowStudentAnalytics = ({ typeTableRow, test, index, forPage }) => {
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openTeacherInfo = Boolean(iconAnchorEl);
  const handleOpenInfoTeacher = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setIconAnchorEl(null);

  const join = test.teachers
    .map((teacher) => {
      return teacher.name + '';
    })
    .join(', ');

  return (
    <TableRow typeTableRow={typeTableRow} index={index}>
      <TableCell>{test.label}</TableCell>
      <TableCell>{test.concept}</TableCell>
      <TableCell>{test.status}</TableCell>
      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }} className="">
          <div className="tableRowStudentAnalytics_svg">
            <InfoOutlinedIcon onClick={handleOpenInfoTeacher} />
          </div>
          <Popover
            open={openTeacherInfo}
            onClose={handleCloseInfoTeacher}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            anchorEl={iconAnchorEl}
            elevation={2}
          >
            <Typography component={'span'} variant={'body2'}>
              <TeacherInfoPopOver teachers={test.teachers} />
            </Typography>
          </Popover>

          {join.length >= 52 ? join.slice(0, 5) + '. . .' : join}
        </div>
      </TableCell>
      <TableCell>{test.date}</TableCell>
      <TableCell color={test.score.color}>{test.score.score}</TableCell>
      <TableCell color={test.speed.color}>{test.speed.speed}</TableCell>
    </TableRow>
  );
};
