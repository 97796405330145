import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { ParentHeaderProfilePopUp } from '../ParentHeaderProfilePopUp/ParentHeaderProfilePopUp';

const ParentHeaderProfilePopover = ({
  open,
  anchorEl,
  handleClose,
  handleParentEditProfileModalOpen,
}) => {
  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
      elevation={2}
    >
      <Typography component={'span'} variant={'body2'}>
        <ParentHeaderProfilePopUp
          handleParentEditProfileModalOpen={handleParentEditProfileModalOpen}
        />
      </Typography>
    </Popover>
  );
};

export default ParentHeaderProfilePopover;
