import { useHistory } from "react-router-dom";
import { routes } from '../../../../routes'
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import UpgradeTwoToneIcon from '@mui/icons-material/UpgradeTwoTone';
import './SelectGradeBook.scss';
import ExportBtn from "../../../../components/buttons/exportBtn";

export const SelectGradeBook = ({
  classes,
  setClassInfo = () => { },
  onClose,
  handleOpenEditClassModal,
  handleOpenUpgradeModal,
  // handleOpenArchiveModal,
  classId,
  classTitle,
  setActiveClasses,
  setSelectedClass
}) => {
  const history = useHistory();
  // console.log('classId from select', classId);
  // console.log(classes)
  setClassInfo(classes)
  return (
    <>
      <div className="SelectGradeBookWrapper">
        <div className="classGradeBookHeader_dropDownSelectionsWrapper">
          <div className="classGradeBookHeader_dropDownSelectionContainer">
            <div
              onClick={() => {
                setSelectedClass([classId]);
                handleOpenUpgradeModal();
                onClose();
              }}
              style={{ height: 32 }}
              className="classGradeBookHeader_dropDownSelectionContainer_greyitems"
            >
              <UpgradeTwoToneIcon style={{ marginRight: 5 }} />
              Upgrade
            </div>
            <div
              // onClick={handleOpenEditClassModal([classId])}
              onClick={() => { handleOpenEditClassModal(); onClose() }}
              className="classGradeBookHeader_dropDownSelectionContainer_greyitems"
            >
              <EditTwoToneIcon style={{ marginRight: 5 }} />
              Edit class
            </div>
            <div className="classGradeBookHeader_dropDownSelectionContainer_greyitems">
              <ExportBtn activeClasses={[classes].flat()}>
                <FileUploadTwoToneIcon style={{ marginRight: 5 }} />
                Export CSV
              </ExportBtn>
            </div>
            <div
              className="classGradeBookHeader_dropDownSelectionContainer_greyitems"
              onClick={() => {
                history.push(`${routes.analytics()}?class=${classTitle}&classId=${classId}`);
              }}
            >
              <TrendingUpIcon style={{ marginRight: 5 }} />
              Analytics
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
