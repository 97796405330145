export const formatSubmitTestInput = (value, type, prevEnteredValue) => {

  if (prevEnteredValue?.current.length > value.length) {
    prevEnteredValue.current = value;
    return value;
  }

  if (!value) return '';
  const testInput = value.replace(/[^\d]/g, '');

  let newValue = testInput;

  if (type) {
    if (testInput.length >= 2) newValue = `${testInput.slice(0, 2) + type}`;
  }

  if (prevEnteredValue !== undefined) {
    prevEnteredValue.current = newValue;
  }

  return newValue;
}

