import noTestsImage from '../../../images/noTestsContent.svg';
import arrowImage from '../../../images/arrow.svg';
import "./NoTestDataClassGradebook.scss"

const NoTestDataClassGradebook = () => {
  return (
    <>
      <div
        className="NoTestDataClassGradebookWrapper"
        // style={{ position: 'relative', paddingTop: 97 }}
      >
        {/* pencil image */}
        <div className="NoTestDataImgWrapper">
          <img
            className="noTestsDataImage"
            src={noTestsImage}
            width="331"
            height="317"
            alt="Smart pencil"
          />
          <img
            className="noTestsDataArrow"
            src={arrowImage}
            width="266"
            height="148"
            alt="Navigate arrow"
          />
        </div>

        {/* first student column */}
        {/* <div className="classGradeBook_gradeBook_studentsInfo_container">
          <div className="classGradeBook_gradeBook_table_headerForStudent">Students (0)</div>
        </div> */}

        {/* second test column */}
        <div className="classGradeBook_NoTestData_gradeBook_tablecontainer">
          <div className="classGradeBook_NoTestData_gradeBook_table_Wrapper">
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_white"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_grey"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_white"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_grey"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_white"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_grey"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_white"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_grey"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_white"></p>
            <p className="classGradeBook_NoTestData_gradeBook_table_Row_grey"></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoTestDataClassGradebook;
