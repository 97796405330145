import { Pie } from 'react-chartjs-2';

// import { StyledPieChartCenter } from './StyledPieChartCenter';

export const PieChart = ({ dataToChart, setStateFilter, type }) => {
  // console.log('setStateFilter', setStateFilter); 
  const PieChartDataKeys = Object.keys(dataToChart);

  const PieChartDataValues = Object.values(dataToChart);

  const colors = [];
  if (type === 'speed') {
    for (let i = 0; i < PieChartDataKeys.length; i++) {
      const colorToPush =
        PieChartDataKeys[i] === 'belowExpected'
          ? '#FB463B '
          : PieChartDataKeys[i] === 'asExpected'
          ? '#0F9D58'
          : '#4285F4';
      colors.push(colorToPush);
    }
  }
  if (type === 'score') {
    const colorsForScore = [];

    for (let i = 0; i < PieChartDataKeys.length; i++) {
      const colorToPush =
        PieChartDataKeys[i] === 'below50'
          ? '#FB463B '
          : PieChartDataKeys[i] === 'from50to70'
          ? '#FBE949'
          : PieChartDataKeys[i] === 'from80to89'
          ? '#0F9D58'
          : '#4285F4';
      colors.push(colorToPush);
    }
  }

  const colorsForChart = colors;

  const data = {
    labels: PieChartDataKeys,
    datasets: [
      {
        label: PieChartDataKeys,
        data: PieChartDataValues,
        hoverBorderWidth: 3,
        hoverBorderColor: colorsForChart,
        backgroundColor: colorsForChart,
        hoverBackgroundColor: colorsForChart,
      },
    ],
  };

  const config = {
    options: {
      pieceLabel: {
        render: 'label',
        fontColor: '#000',
        position: 'outside',
        segment: true,
      },
      onClick: (e, item) => {
        if (item[0]) {
          // console.log('item[0]', PieChartDataKeys[item[0].index]);
          setStateFilter((prevState) =>
            prevState === -1 && PieChartDataKeys[item[0].index] === 'belowExpected'
              ? null
              : prevState === 0 && PieChartDataKeys[item[0].index] === 'asExpected'
              ? null
              : prevState === 1 && PieChartDataKeys[item[0].index] === 'aboveExpected'
              ? null
              : prevState === '90-100' && PieChartDataKeys[item[0].index] === 'from90to100'
              ? null
              : prevState === '80-89' && PieChartDataKeys[item[0].index] === 'from80to89'
              ? null
              : prevState === '50-79' && PieChartDataKeys[item[0].index] === 'from50to70'
              ? null
              : prevState === '0-50' && PieChartDataKeys[item[0].index] === 'below50'
              ? null
              : PieChartDataKeys[item[0].index] === 'belowExpected'
              ? -1
              : PieChartDataKeys[item[0].index] === 'asExpected'
              ? 0
              : PieChartDataKeys[item[0].index] === 'aboveExpected'
              ? 1
              : PieChartDataKeys[item[0].index] === 'from90to100'
              ? '90-100'
              : PieChartDataKeys[item[0].index] === 'from80to89'
              ? '80-89'
              : PieChartDataKeys[item[0].index] === 'from50to70'
              ? '50-79'
              : PieChartDataKeys[item[0].index] === 'below50'
              ? '0-49'
              : null
          );
        } else {
          return;
        }
      },
      plugins: {
        tooltip: {
          enabled: false,
        },

        legend: {
          display: false,
        },
      },
      transitions: {
        active: {
          animation: {
            duration: 0,
          },
        },
      },
    },
  };

  config.options.transitions.active.animation.duration = 0;
  config.options.animation = false;

  return (
    <>
      <Pie data={data} options={config.options} />
    </>
  );
};
