import { useState, useContext } from 'react';
import axios from 'axios';
import { SecondaryButton } from '../../../../../components/buttons/SecondaryButton/SecondaryButton';
import { ButtonPrimary } from '../../../../../components/buttons/buttonPrimary/ButtonPrimary';
import { ModalWrapper } from '../../../../../components/Modal/ModalWrapper/ModalWrapper';
import { ModalContainer } from '../../../../../components/Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../../../components/Modal/ModalHeader/ModalHeader';
import { config } from '../../../../../config';
import { AppContext } from '../../../../../context/AppContext';
import './UpgradeClassModal.scss';

export const UpgradeClassModal = ({
  onClose,
  activeClasses,
  setActiveClasses,
  loadDataForGradeBook,
  openGradebooksPage,
}) => {
  const {classInfo } = useContext(AppContext);
  const [successfulRequest, setSuccessfulRequest] = useState(null);

  console.log('classInfo', classInfo)

  const handleSendToArchiveClasses = async () => {
    const dataToSend = {
      ids: activeClasses,
      isUpdate: true,
    };
    try {
      const data = await axios.post(`${config.API_URL}/api/gradebook/archive`, dataToSend);
      console.log('data', data);
      console.log('data.data.success', data.data.success);
      if (data.data.success) {
        setSuccessfulRequest(true);
        setActiveClasses([]);
      } else{
      setSuccessfulRequest(false);
      }
    } catch (e) {
      console.log(e);
      setSuccessfulRequest(false);
    }
  };

  return (
    <>
      <ModalWrapper>
        <ModalContainer>
          <ModalHeader header="Upgrade Class(es)" color="darkHeader" onClose={onClose} />
          <div className="upgradeClassModal_tooltipWrapper">
            Do you want to upgrade <span>class(es)</span> ?
          </div>
          <div className="upgradeClassModal_smallTooltipWrapper">
            *All students will be duplicated without test results. Test results will be archived.
          </div>
          <div style={{ width: 250 }} className="upgradeClassModal_buttonsWrapper">
            <div className="">
              <SecondaryButton status={'cancel'} active={true} functionality={onClose} />
            </div>

            <div className="">
              <ButtonPrimary
                status={'upgrade'}
                active={true}
                functionality={handleSendToArchiveClasses}
              />
            </div>
          </div>
        </ModalContainer>
      </ModalWrapper>

      {successfulRequest === false && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <ModalHeader
                header="Upgrade Error"
                color="darkHeader"
                onClose={() => {
                  setSuccessfulRequest(null);
                  onClose();
                }}
              />
              <div className="upgradeClassModal_tooltipWrapper">
                Something went wrong. Please try again later.
              </div>
              <div style={{ height: 40, marginTop: 20, marginLeft: 80 }} className="">
                <ButtonPrimary
                  status={'ok'}
                  active={true}
                  functionality={() => {
                    setSuccessfulRequest(null);
                    onClose();
                  }}
                />
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}

      {successfulRequest === true && (
        <>
          <ModalWrapper>
            <ModalContainer>
              <div className="upgradeClassModal_headerWrapper">
                <div
                  style={{ marginLeft: 37, color: '#A6192E' }}
                  className="upgradeClassModal_header"
                >
                  Successfully Upgraded
                </div>
              </div>
              <div style={{ height: 40, marginTop: 30, marginLeft: 80 }} className="">
                <ButtonPrimary
                  status={'okRed'}
                  active={true}
                  functionality={() => {
                    setSuccessfulRequest(null);
                    onClose();
                    { loadDataForGradeBook ? loadDataForGradeBook() : openGradebooksPage() }
                    }}
                />
              </div>
            </ModalContainer>
          </ModalWrapper>
        </>
      )}
    </>
  );
};
