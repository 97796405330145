// import {WidthLimiter} from '../widthLimiter/WidthLimiter'
// import {Button} from '../Button/Button'
import ExportButton from '../buttons/exportBtn/ExportButton';
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import ButtonText from '../buttons/buttonText/ButtonText.jsx';

import './SubTableHeadLine.scss';

const propsToSubTableHeadLine = [
  {
    subHeadLine: 'tests',
    titleName: 'test selected',
    isCancelButton: true,
    isExportButton: true,
    isarchiveButton: true,
    isUpgrade: true,
  },
  {
    subHeadLine: 'students',
    titleName: 'student selected',
    isCancelButton: true,
    isExportButton: true,
    isarchiveButton: true,
    isUpgrade: false,
  },
  {
    subHeadLine: 'grade selected',
    titleName: 'tests selected',
    isCancelButton: true,
    isExportButton: true,
    isarchiveButton: false,
    isUpgrade: false,
  },
  {
    subHeadLine: 'class selected',
    titleName: 'tests selected',
    isCancelButton: true,
    isExportButton: true,
    isarchiveButton: false,
    isUpgrade: true,
  },
];
export const SubTableHeadLine = ({
  subHeadlineType = 'tests',
  activeClasses,
  setActiveClasses,
  tableRowData,
  fileHeader
}) => {
  let currentSubHeadline = {};

  for (let i = 0; i < propsToSubTableHeadLine.length; i++) {
    if (propsToSubTableHeadLine[i].subHeadLine === subHeadlineType) {
      currentSubHeadline = propsToSubTableHeadLine[i];
    }
  }

// console.log('activeClasses', activeClasses)

  return (
    <div className="subTableHeadLine_WrapperContainer">
      <div className="subTableHeadLine_Wrapper">
        {typeof subHeadlineType === 'string' && (
          <>
            <div className="subTableHeadLine_title">{currentSubHeadline.titleName}</div>
            <div className="subTableHeadLine_buttonsWrapper">
              {currentSubHeadline.isCancelButton && (
                <ButtonText
                  type={`4`}
                  text={`Cancel selection`}
                  iconName={`Clear`}
                  active={true}
                  funtionality={currentSubHeadline.isCancelButton}
                />
              )}
              {currentSubHeadline.isExportButton && (
                // <ButtonText
                //   type={`4`}
                //   text={`Export CSV`}
                //   iconName={`PublishOutlined`}
                //   active={true}
                //   funtionality={() => {
                //     console.log('123546879');
                //   }}
                // />
                <ExportButton
                  //  additionalData={}
                  // activeClasses={tableRowData}
                  btnStyle="white"
                  fileHeader="gradebook"
                />
              )}
              {currentSubHeadline.isarchiveButton && (
                <ButtonText
                  type={`3`}
                  text={` Archive selected `}
                  iconName={`ArchiveTwoTone`}
                  active={true}
                  funtionality={() => {
                    console.log('123546879');
                  }}
                />
              )}
              {currentSubHeadline.isUpgrade && (
                <ButtonText
                  type={`1`}
                  text={` Archive selected `}
                  iconName={`VerticalAlignTop`}
                  active={true}
                  funtionality={() => {
                    console.log('123546879');
                  }}
                />
              )}
            </div>
          </>
        )}
        {typeof subHeadlineType === 'object' && (
          <>
            <div className="subTableHeadLine_title">{subHeadlineType.titleName}</div>
            <div className="subTableHeadLine_buttonsWrapper">
              {subHeadlineType.isCancelButton && (
                <ButtonText
                  type={`4`}
                  text={`Cancel selection`}
                  iconName={`Clear`}
                  active={true}
                  funtionality={() => {
                    subHeadlineType.isCancelButton();
                  }}
                />
              )}
              {subHeadlineType.isExportButton && (

                <ExportButton
                  // additionalData={}
                  // activeClasses={activeClasses}
                  activeClasses={tableRowData}
                  btnStyle="white"
                  fileHeader={fileHeader || "gradebook"}
                >
                  <FileUploadTwoToneIcon />
                  Export CSV
                </ExportButton>
              )}
              {subHeadlineType.isarchiveButton && (
                <ButtonText
                  type={`3`}
                  text={` Archive selected `}
                  iconName={`ArchiveTwoTone`}
                  active={true}
                  funtionality={() => {
                    subHeadlineType.isarchiveButton();
                  }}
                />
              )}
              {subHeadlineType.isUpgradeButton && (
                <ButtonText
                  type={`1`}
                  text={` upgrade selected`}
                  iconName={`VerticalAlignTop`}
                  active={true}
                  funtionality={() => {
                    console.log(subHeadlineType.isUpgradeButton());
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
