import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CreateIcon from '@material-ui/icons/Create';
import styles from './ParentSchoolSelectPopover.module.scss';

export const ParentSchoolSelectPopover = ({ school }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        {school.Name}
      </div>

      <div>
        <div className={styles.addressLabel}>{school.Address}</div>
        <div className={styles.addressValue}>Address</div>
      </div>


    </div>
  );
};
