import axios from 'axios';
import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { AnalyticsPageHeader } from '../../components/AnalyticsPageComponents/AnalyticsPageHeader/AnalyticsPageHeader';
import { AnaliticsContainer } from '../../containers/AnaliticsContainer/AnaliticsContainer';
import { TableForAnalyticPage } from '../../containers/TableForAnalyticPage/TableForAnalyticPage';
import { LoaderFullBook } from '../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { Alert } from '../../components/Alert/Alert';
import { AppContext } from '../../context/AppContext';
import { config } from '../../config';
import img from '../../images/noSearchResults.svg';
import { useLocation } from "react-router-dom";
import "./AnalyticsPage.scss"

// import { getAllSchools } from '../../services/schools';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
// export const AnalyticsPage = ({ school = defaultSchoolInfo }) => {
export const AnalyticsPage = () => {
  let query = useQuery();
  const { showLoader, setShowLoader, currentSchoolId } = useContext(AppContext);

  const [generalSchoolAnalytic, setGeneralSchoolAnalytic] = useState();
  const [tableDataStudents, setTableDataStudents] = useState();

  // for grey subHeader
  const [conceptFilter, setConceptFilter] = useState(null);
  const [classFilter, setClassFilter] = useState(query.get("classId") ? [query.get("classId")] : []);
  const sortByClass = (query.get("classId") ? 'class' : undefined);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  // for charts
  const [filterAverageScore, setFilterAverageScore] = useState(null);
  const [filterAverageSpeed, setFilterAverageSpeed] = useState(null);
  // for pagination
  const [pageSize, setPageSize] = useState(10); // 10, 25, 50
  const [currentPage, setCurrentPage] = useState(0); // 0
  const [totalCount, setTotalCount] = useState(); // 100
  // for sort
  const [nameSort, setNameSort] = useState(null);
  const [idSort, setIdSort] = useState(null);
  const [avSpeedSort, setAvSpeedSort] = useState(null);
  const [avScoreSort, setAvScoreSort] = useState(null);
  // for sort (in request)
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  // for input to get students table
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setConceptFilter(null)
    setClassFilter(query.get("classId") ? [query.get("classId")] : [])
    setStartDateFilter(null)
    setEndDateFilter(null)
    setFilterAverageScore(null)
    setFilterAverageSpeed(null)
    setPageSize(10)
    setCurrentPage(0)
    setTotalCount(undefined)
    setNameSort(null)
    setIdSort(null)
    setAvSpeedSort(null)
    setAvScoreSort(null)
    setSort(null)
    setSortDirection(null)
    setSearchValue('')
  }, [currentSchoolId])
  
  // to get school info (request) for < AnalyticContainer />
  const handleSendRequestGetGeneralSchoolAnalytic = useCallback(async () => {
    setShowLoader(true);
    const requestParamsGetSchoolAnalytic = {
      classFilter: classFilter,
      startDateFilter: startDateFilter,
      endDateFilter: endDateFilter,
      schoolId: currentSchoolId,
    };
    try {
      const data = await axios.post(
        `${config.API_URL}/api/analytics/school`,
        requestParamsGetSchoolAnalytic
      );
      console.log('data.data', data.data);
      if (data) {
        setGeneralSchoolAnalytic({
          schoolId: data.data[0].id,
          schoolName: data.data[0].schoolName,
          totalClasses: data.data[0].classesAmount,
          totalStudents: data.data[0].studentsAmount,
          totalTeachers: data.data[0].teachersAmount,
          totalTests: data.data[0].testsCompleted,
          schoolAddress: data.data[0].schoolAddress,
          schoolScoreStatistic: data.data[0].schoolScoreStatistic,
          schoolSpeedStatistic: data.data[0].schoolSpeedStatistic,
        });
        setShowLoader(false);
      }
    } catch (e) {
      console.log(e);
      setShowLoader(false);
    }
  }, [classFilter, endDateFilter, currentSchoolId, startDateFilter]);

  // to get students info (request) for < TableForAnalyticPage />
  const handleSendRequestForTable = useCallback(async (toResetPagination) => {
    let cancelToken; // cancelToken to cancel request
    if (toResetPagination === true) {
      setCurrentPage(0);
    }
    setShowLoader(true);
    // make correct object to make a request to server
    const sortToSend = sort ? { key: sort, direction: sortDirection } : null;
    const requestParamsGetTable = {
      classFilter: classFilter,
      startDateFilter: startDateFilter,
      endDateFilter: endDateFilter,
      filterAverageScore: filterAverageScore,
      filterAverageSpeed: filterAverageSpeed,
      studentName: searchValue,
      schoolId: currentSchoolId,
      sort: sortToSend,
      pagination: {
        perPage: pageSize,
        page: currentPage + 1,
      },
    };

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = axios.CancelToken.source();

    try {
      const data = await axios.post(
        `${config.API_URL}/api/analytics/students`,
        requestParamsGetTable,
        { cancelToken: cancelToken.token }
      );
      console.log('data', data);
      // console.log('data.data.data', data.data.data);
      if (data.data) {
        setTableDataStudents(data.data.data);
        setTotalCount(data.data.totalCount);
        cancelToken = undefined;
        setShowLoader(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    classFilter,
    currentPage,
    endDateFilter,
    filterAverageScore,
    filterAverageSpeed,
    pageSize,
    searchValue,
    sort,
    sortDirection,
    startDateFilter,
    currentSchoolId
  ]);

  // to get CSV table info (request)
  const handleGetCSVTableData = async (args = 'download') => {
    setShowLoader(true);

    const requestParamsGetCsvTable = {
      classFilter: classFilter,
      conceptFilter: conceptFilter,

      startDateFilter: startDateFilter,
      endDateFilter: endDateFilter,
      filterAverageScore: filterAverageScore,
      filterAverageSpeed: filterAverageSpeed,
      studentName: searchValue,
      schoolId: currentSchoolId,
    };
    try {
      var data = await axios.post(
        `${config.API_URL}/api/analytics/students`,
        requestParamsGetCsvTable
      );
      if (data.data) {
        const arrOfObj = data.data.data.reduce(
          (acc, student) => [
            ...acc,
            {
              Name: student.name,
              Id: student.id,
              Class: student.class,
              Score: student.avScore.score.toFixed(0),
              Speed: student.avSpeed.speed,
              Parents: `${student?.parentsDetailInfo?.firstParent?.name || ''} ${student?.parentsDetailInfo?.secondParent?.name ?
              '| ' + student.parentsDetailInfo.secondParent.name : ''}`,
            },
          ],
          []
        );

        function convertArrayOfObjectsToCSV(args) {
          var result, ctr, keys, columnDelimiter, lineDelimiter, data;

          data = args.data || null;
          if (data == null || !data.length) {
            return null;
          }

          columnDelimiter = args.columnDelimiter || ',';
          lineDelimiter = args.lineDelimiter || '\n';

          keys = Object.keys(data[0]);
          result = '';
          result += keys.join(columnDelimiter);
          result += lineDelimiter;

          data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
              if (ctr > 0) result += columnDelimiter;

              result += item[key];
              ctr++;
            });
            result += lineDelimiter;
          });

          return result;
        }
        // function downloadCSV(args) {
        var filename, link;
        var csv = convertArrayOfObjectsToCSV({
          data: arrOfObj,
        });
        if (csv == null) return;

        filename = args.filename || 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
          csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        data = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
      }
      setShowLoader(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const handleSetSort = () => {
      if (nameSort !== null) {
        setCurrentPage(0);
        setSort('Name');
        let newName = nameSort === true ? true : false;
        setSortDirection(newName);
      }
      if (idSort !== null) {
        setCurrentPage(0);
        setSort('StudentId');
        let newId = idSort === true ? true : false;
        setSortDirection(newId);
      }
      if (avSpeedSort !== null) {
        setCurrentPage(0);
        setSort('AvSpeed');
        let newSpeed = avSpeedSort === true ? true : false;
        setSortDirection(newSpeed);
      }
      if (avScoreSort !== null) {
        setCurrentPage(0);
        setSort('AvScore');
        let newScore = avScoreSort === true ? true : false;
        setSortDirection(newScore);
      }
    }
    handleSetSort();
  }, [nameSort, idSort, avSpeedSort, avScoreSort]);

  useEffect(() => {
    const toResetPagination = true;
    handleSendRequestGetGeneralSchoolAnalytic();
    handleSendRequestForTable(toResetPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classFilter,
    conceptFilter,
    endDateFilter,
    filterAverageScore,
    filterAverageSpeed,
    pageSize,
    sort,
    sortDirection,
    currentSchoolId
  ]);

  useEffect(() => {
    const toResetPagination = false;
    handleSendRequestGetGeneralSchoolAnalytic();
    handleSendRequestForTable(toResetPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      // Send Axios request here
      handleSendRequestForTable();
    }, 1500);
    return () => clearTimeout(delayTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const sortFunctionForName = () => {
    if (nameSort === null) {
      setNameSort(true);
      setIdSort(null);
      setAvSpeedSort(null);
      setAvScoreSort(null);
      return;
    }
    setNameSort((prevState) => !prevState);
    setIdSort(null);
    setAvSpeedSort(null);
    setAvScoreSort(null);
  };

  const sortFunctionForId = () => {
    if (idSort === null) {
      setIdSort(true);
      setNameSort(null);
      setAvSpeedSort(null);
      setAvScoreSort(null);
      return;
    }
    setIdSort((prevState) => !prevState);
    setNameSort(null);
    setAvSpeedSort(null);
    setAvScoreSort(null);
  };

  const sortFunctionForScore = () => {
    if (avScoreSort === null) {
      setAvScoreSort(true);
      setIdSort(null);
      setAvSpeedSort(null);
      setNameSort(null);
      return;
    }
    setAvScoreSort((prevState) => !prevState);
    setIdSort(null);
    setAvSpeedSort(null);
    setNameSort(null);
  };

  const sortFunctionForSpeed = () => {
    if (avSpeedSort === null) {
      setAvSpeedSort(true);
      setAvScoreSort(null);
      setIdSort(null);
      setNameSort(null);
      return;
    }
    setAvScoreSort(null);
    setIdSort(null);
    setNameSort(null);
    setAvSpeedSort((prevState) => !prevState);
  };

  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => { }
  });

  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(() => setIsAlert({
    type: false,
    text: '',
    close: () => { }
  }), []);

  // console.log('tableDataStudents', tableDataStudents)
  // console.log('generalSchoolAnalytic', generalSchoolAnalytic)

  return (
    <div className="analyticsPage">
      <InternalPageContainer />
      <AnalyticsPageHeader
        sortByClass={sortByClass}
        classFilter={classFilter}
        setClassFilter={setClassFilter}
        conceptFilter={conceptFilter}
        setConceptFilter={setConceptFilter}
        startDateFilter={startDateFilter}
        setStartDateFilter={setStartDateFilter}
        endDateFilter={endDateFilter}
        setEndDateFilter={setEndDateFilter}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleOnClickDownloadCsv={handleGetCSVTableData}
        tableDataStudents={tableDataStudents}
      />
      {showLoader && <LoaderFullBook />}
      {generalSchoolAnalytic && tableDataStudents?.length ? (
        <>
          <AnaliticsContainer
            filterAverageScore={filterAverageScore}
            setFilterAverageScore={setFilterAverageScore}
            filterAverageSpeed={filterAverageSpeed}
            setFilterAverageSpeed={setFilterAverageSpeed}
            schoolInfo={generalSchoolAnalytic}
          />

          <TableForAnalyticPage
            sortName={sort}
            filterAverageScore={filterAverageScore}
            filterAverageSpeed={filterAverageSpeed}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={totalCount}
            setTotalCount={setTotalCount}
            tableDataStudents={tableDataStudents}
            sortFunctionForName={sortFunctionForName}
            sortFunctionForId={sortFunctionForId}
            sortFunctionForScore={sortFunctionForScore}
            sortFunctionForSpeed={sortFunctionForSpeed}
            setFilterAverageScore={setFilterAverageScore}
            setFilterAverageSpeed={setFilterAverageSpeed}
            sortDirection={sortDirection}
            callAlert={callAlert}
            closeAlert={closeAlert}
          />
        </>
      ) : (
        <div className="noResults">
          <div className="noResults-text">No Data available</div>
          <img
            className="noResults-img"
            src={img}
            width="250"
            alt="The search has not given any results, try again!"
          />
        </div>
      )}
      {isAlert.type && <Alert alertType={isAlert.type} text={isAlert.text} close={isAlert.close} />}
    </div>
  );
};
