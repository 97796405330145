import { createContext, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { getAllSchools } from '../services/schools';
import { config } from '../config';
import axios from 'axios';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(false);

  //  ===== auth ======
  const [isAuth, setIsAuth] = useState(false);

  //  ===== socialLinks ======
  const [socialLinks, setSocialLinks] = useState({
    googleLink: '',
    microsoftLink: '',
    cleverLink: '',
  });

  // useEffect(() => {
  //   getSocialLinks();
  // }, []);

  async function getSocialLinks() {
    try {
      await axios.get(`${config.API_URL}/api/auth/links`).then((response) => {
        // console.log('response', response);
        setSocialLinks({ ...response.data });
      });
    } catch (e) {
      console.log(e);
    }
  }

  //  ===== user ======
  const defaultUser = {
    contactName: '',
    firstName: 'No Name',
    lastName: '',
    phone: '',
    photo: '', // IN BASE64
    role: 'School Manager',
    email: '',
    isArchive: false,
    id: 1,
  };
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    getSocialLinks();
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuth) {
      getSchoolsData();
      getAllStudentInfo(currentStudentId);
    }
  }, [isAuth]);

  async function getUserData() {
    setShowLoader(true);
    await axios
      .get(`${config.API_URL}/api/user/info`)
      .then((response) => {
        const userData = response.data;
        console.log('response.data', response.data);
        if (response.status === 401) {
          setIsAuth(false);
          setShowLoader(false);
          history.push('/sign-in');
        } else {
          setUser({
            ...user,
            contactName: `${userData.LastName}, ${userData.FirstName}`,
            firstName: userData.FirstName,
            lastName: userData.LastName,
            phone: userData.Phone,
            photo: userData.Photo, // IN BASE64
            role: userData.Role,
            email: userData.Email,
            isArchive: userData.isArchive,
            id: userData.Id,
            authType: userData.AuthType,
          });
          setUploadedPhoto(userData.Photo);
          setIsAuth(true);
          setShowLoader(false);

          // console.log('userData', userData);
          // console.log('userData.Role', userData.Role);
          console.log('userData', userData)
          if (!userData?.Role) {
            return;
          }
          if (userData.Role === 4) {
            history.push('/parent');
          } else {
            history.push('/gradebooks');
          }
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setIsAuth(false);
        setShowLoader(false);
        history.push('/sign-in');
      });
  }

  const updateUser = (data) => {
    setUser(data);
  };

  //  ===== photo & files ======
  const [uploadedPhoto, setUploadedPhoto] = useState('');
  const [fileToSave, setFileToSave] = useState({
    photo: null,
  });

  //  ===== schools === currentSchoolId ===
  const [updateSchoolInfo, setUpdateSchoolInfo] = useState(false);

  const [schools, setSchools] = useState([]);
  const updateSchools = (data) => {
    setSchools(data);
  };

  const [currentSchoolId, setCurrentSchoolId] = useState(+localStorage.getItem('currentSchoolId'));
  const updateCurrentSchoolId = (id) => {
    setCurrentSchoolId(id);
  };
  const [currentSchool, setCurrentSchool] = useState({});
  useEffect(() => {
    setCurrentSchool(schools.find(({ Id }) => Id === currentSchoolId));
  }, [currentSchoolId, schools]);
  // console.log('currentSchool', currentSchool);
  // console.log('currentSchoolId', currentSchoolId);

  function getSchoolsData() {
    setShowLoader(true);
    getAllSchools()
      .then((res) => {
        updateSchools(res.data);
        // console.log(res.data)
        const localDataID = Number(localStorage.getItem('currentSchoolId'));
        if (!localDataID) {
          setCurrentSchoolId(res.data?.[0]?.Id);
          localStorage.setItem('currentSchoolId', JSON.stringify(res.data?.[0]?.Id));
        } else {
          setCurrentSchoolId(localDataID);
        }
        setCurrentSchool(res.data.find(({ Id }) => Id === localDataID));
        setShowLoader(false);
      })
      .catch(function (error) {
        console.log('error', error);
        setShowLoader(false);
      });
  }

  //  ===== classInfo === currentClassId ======
  const [classInfo, setClassInfo] = useState({});
  const [currentClassId, setCurrentClassId] = useState(null);
  const updateCurrentClassId = (id) => {
    setCurrentClassId(id);
  };

  //  ===== STUDENTS === currentStudentId ======
  const [students, setStudents] = useState([]);
  const [currentStudentId, setCurrentStudentId] = useState();
  const [currentStudent, setCurrentStudent] = useState({});
  const [studentInfo, setStudentInfo] = useState([]);

  // console.log('students', students);
  // console.log('currentStudentId', currentStudentId);
  // console.log('currentStudent', currentStudent);

  // see 61 string
  // useEffect(() => {
  //   getAllStudentInfo(currentStudentId);
  // }, [user]);

  async function getAllStudentInfo() {
    try {
      setShowLoader(true);
      await axios
        .get(
          // `${config.API_URL}/api/student/find?userId=227&schoolId=1` example (random parent)
          // `${config.API_URL}/api/student/find?userId=639&schoolId=130` example (Catelyn Stark)
          `${config.API_URL}/api/student/find?userId=${user.id}&schoolId=${currentSchoolId}`
        )
        .then((response) => {
          const userData = response.data;
          // console.log('response.data', response.data);
          setStudents(response.data);
          if (response.data) {
            setCurrentStudentId(response.data[0].id);
          }
          setShowLoader(false);
        });
    } catch (error) {
      console.log('error', error);
      setShowLoader(false);
    }
  }

  // console.log('students', students);
  // console.log('currentStudentId', currentStudentId);
  useEffect(() => {
    setCurrentStudent(students.find(({ id }) => id === currentStudentId));
  }, [currentStudentId]);

  return (
    <AppContext.Provider
      value={{
        isAuth,
        setIsAuth,
        showLoader,
        setShowLoader,
        user,
        updateUser,
        defaultUser,
        schools,
        updateSchools,
        currentSchoolId,
        setCurrentSchoolId,
        updateCurrentSchoolId,
        getSchoolsData,
        uploadedPhoto,
        setUploadedPhoto,
        fileToSave,
        setFileToSave,
        getUserData,
        socialLinks,
        currentClassId,
        updateCurrentClassId,
        classInfo,
        setClassInfo,
        setUpdateSchoolInfo,
        currentSchool,
        students,
        setStudents,
        currentStudentId,
        setCurrentStudentId,
        currentStudent,
        setCurrentStudent,
        studentInfo,
        setStudentInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
