import React, { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { LockOpen } from '@mui/icons-material';
import { AppContext } from '../../../../../context/AppContext';
import { OutlinedTextField } from '../../../../OutlinedTextField/OutlinedTextField';
import { IncorrectEmailPopover } from '../../../../../pages/auth/components/IncorrectEmailPopover/IncorrectEmailPopover';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import styles from "./EditProfileInputs.module.scss"
import { formatPhoneNumber } from '../../../../../model/formatPhoneNumber';


const EditProfileInputs = ({ nextStep, control, errors, formData, onChange, register }) => {
  // open email tooltip
  const [iconAnchorElConfirmEmail, setIconAnchorElConfirmEmail] = useState(null);
  const openConfirmEmail = Boolean(iconAnchorElConfirmEmail);
  const handleOpenConfirmEmail = (event) => setIconAnchorElConfirmEmail(event.currentTarget);
  const handleCloseConfirmEmail = () => setIconAnchorElConfirmEmail(null);
  const [phoneValue, setPhoneValue] = useState(formatPhoneNumber(formData?.phone));


  return (
    <div className={styles.EditProfileInputs_infoContainer}>
      <div className={styles.EditProfileInputs_textFieldWrapper}>
        <div
          style={{ position: 'relative', marginBottom: 22 }}
          className={styles.EditProfileInputs_textField}
        >
          <OutlinedTextField
            {...register('firstName', {
              required: 'Required Field',
              minLength: 1,
            })}
            fullWidth
            label="First Name"
            style={{ width: 302 }}
            autoComplete="off"
            defaultValue={formData.firstName}
            onChange={onChange}
          />
          {errors.firstName && (
            <span className={styles.EditProfileInputs_errorInInput}>
              First name is required{' '}
              <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
            </span>
          )}
        </div>

        <div
          style={{ position: 'relative', marginBottom: 22 }}
          className={styles.EditProfileInputs_textField}
        >
          <OutlinedTextField
            {...register('lastName', {
              required: 'Required Field',
              minLength: 1,
            })}
            fullWidth
            label="Last Name"
            style={{ width: 302 }}
            autoComplete="off"
            defaultValue={formData.lastName}
            onChange={onChange}
          />
          {errors.lastName && (
            <span className={styles.EditProfileInputs_errorInInput}>
              Last name is required{' '}
              <ReportProblemOutlinedIcon style={{ cursor: 'default', marginLeft: 5 }} />
            </span>
          )}
        </div>
        <div
          style={{ position: 'relative', marginBottom: 22 }}
          className={styles.EditProfileInputs_textField}
        >
          <OutlinedTextField
            {...register('phone', {
              // required: 'Required Field',
              pattern: {
                // value: ^\+?\d{1,3}?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$,
                value: /[(][0-9]{3}[)][ ][0-9]{3}[-][0-9]{4}/,
                message: 'Entered value does not match phone format',
              },
            })}
            fullWidth
            label="Phone"
            placeholder={formData.phone ? null : '(XXX)-XXX-XXXX'}
            style={{ width: 302, margin: 0 }}
            autoComplete="off"
            value={phoneValue}
            onInput={(e) => { setPhoneValue(formatPhoneNumber(e.target.value)) }}
            onChange={onChange}
          />
          <div className={styles.EditProfileInputs_errorInInput}>
            {errors?.phone && (
              <>
                <span style={{marginRight: 5}}>{errors?.phone?.message || 'Required Field'}</span>
                <InfoOutlinedIcon />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles.EditProfileInputs_textField}>
        <OutlinedTextField
          {...register('email', {
            required: {
              value: true,
              message: 'Required Field',
            },
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g,
              message: 'Please, enter valid e-mail',
            },
          })}
          fullWidth
          label="Email"
          style={{ width: 302 }}
          autoComplete="off"
          placeholder={formData.email ? null : 'XXXX@XXXX.XX'}
          defaultValue={formData.email}
          onChange={onChange}
        />
        {errors.email && (
          <span className={styles.EditProfileInputs_errorInInput}>
            {errors.email?.message}
            <ReportProblemOutlinedIcon
              style={{ cursor: 'pointer', marginLeft: 5 }}
              onClick={(e) => handleOpenConfirmEmail(e)}
            />
            <IncorrectEmailPopover
              visibl={openConfirmEmail}
              onClose={handleCloseConfirmEmail}
              anchorElement={iconAnchorElConfirmEmail}
            />
          </span>
        )}
      </div>
      {formData.authType === 0 && (
        <div className={styles.changePasswordBtnWrapper} onClick={nextStep}>
          <LockOpen style={{ marginRight: 5 }} />
          Change Password
        </div>
      )}

    </div>
  );
};

export default EditProfileInputs;