import React from 'react';
import { CSVLink } from 'react-csv';
// import { getFileCSVarchive, getFileCSVtests } from '../../../../model';
import { ExpButtonStyled } from './ExpButtonStyled';

const ExpButton = ({
  activeClasses = [],
  fileHeader = 'studentData',
  btnStyle = 'white',
  callAlert = () => {},
  closeAlert = () => {},
}) => {
  // console.log('activeClasses', activeClasses);

  const onExportCSV = () => {
    if (!activeClasses.length) {
      callAlert({
        type: false,
        text: 'Ooops, something went wrong!' + ' Please, try again.',
        close: closeAlert,
        show: true,
      });
      return false;
    }
    callAlert({
      type: true,
      text: 'Export CSV Success!',
      close: closeAlert,
      show: true,
    });
    return true;
  };

  let data;
  let headers;
  if (fileHeader === 'studentData') {
    headers = [
      { label: 'Test', key: 'name' },
      { label: 'Concept', key: 'concept' },
      { label: 'Teacher', key: 'teacher' },
      { label: 'Score', key: 'score' },
      { label: 'Speed', key: 'speed' },
    ];
    const newArr = [];
    activeClasses.forEach((item) => {
      // console.log('item', item);
      const newDataCSV = {
        name: item.label,
      };
      if (item?.concept) {
        newDataCSV.concept = item.concept;
      } else {
        newDataCSV.concept = '-';
      }

      if (item?.teachers) {
        const teachersNames = [];
        teachersNames.push(item.teachers.map((teacher) => teacher.name));
        // console.log('teachersNames', teachersNames);
        newDataCSV.teacher = teachersNames;
      } else {
        newDataCSV.teacher = '-';
      }

      if (item?.absent === 1) {
        newDataCSV.score = 'Absent';
        newDataCSV.speed = 'Absent';
      } else if (item?.absent === null) {
        newDataCSV.score = '-';
        newDataCSV.speed = '-';
      } else {
        try {
          const time = new Date(item?.speed * 1000).toISOString().slice(11, 19);
          newDataCSV.score = item?.score;
          newDataCSV.speed = time;
        } catch (error) {
          newDataCSV.score = '-';
          newDataCSV.speed = '-';
        }
      }
      newArr.push(newDataCSV);
    });
    data = newArr;
  }

  const csvReport = {
    filename: 'Report.csv',
    headers: headers,
    data: data,
  };

  return (
    <>
      <ExpButtonStyled>
        <button className="ExpBtn">
          <CSVLink onClick={onExportCSV} {...csvReport} className={btnStyle}>
            Export CSV
          </CSVLink>
        </button>
      </ExpButtonStyled>
    </>
  );
};

export default ExpButton;
