import React, { useState } from 'react';
import { ModalWrapper } from '../../../Modal/ModalWrapper/ModalWrapper';
import { ModalHeader } from '../../../Modal/ModalHeader/ModalHeader';
import { ModalFooter } from '../../../Modal/ModalFooter/ModalFooter';
import { ModalPrimaryButton } from '../../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { ModalSecondaryButton } from '../../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { Controller, useForm } from 'react-hook-form';
import { OutlinedTextField } from '../../../OutlinedTextField/OutlinedTextField';
import { removeSchool } from '../../../../services/schools';
import styles from './RemoveSchoolModal.module.scss';
import { useLocation, useHistory } from 'react-router-dom';

const RemoveSchoolModal = ({
  onClose,
  currentSchool,
  schools,
  updateSchools,
  setShowLoader,
  setCurrentSchoolId,
  callAlert,
  closeAlert,
}) => {
  let location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search)

  const [schoolToRemove, setSchoolToRemove] = useState({});

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = () => {
    if (schoolToRemove.schoolName !== currentSchool.Name) {
      return;
    }
    try {
      setShowLoader(true);
      removeSchool(currentSchool.Id).then((response) => {
        // console.log("RemoveSchool-response", response);
        const filtredSchools = schools.filter((sch) => {
          return sch.Id !== currentSchool.Id;
        });
        updateSchools([...filtredSchools]);
        setCurrentSchoolId(schools?.[0]?.Id);
        if (location.pathname === '/gradebook/class') {
          history.push('/gradebooks')
        }
        if (location.pathname === '/archive/class') {
          history.push('/archive')
        }
        if (location.pathname === '/analytics') {
          if (queryParams.has('classId')) {
            queryParams.delete('classId')
            queryParams.delete('class')
            history.replace({
              search: queryParams.toString(),
            })
          }
        }
        localStorage.setItem('currentSchoolId', JSON.stringify(schools?.[0]?.Id));
        setShowLoader(false);
        callAlert({
          type: true,
          text: 'School was successfully removed.',
          close: closeAlert,
          show: true,
        });
        onClose();
      });
    } catch (error) {
      console.log('error', error);
      setShowLoader(false);
      callAlert({
        type: false,
        text: 'Something went wrong. Try again.',
        close: closeAlert,
        show: true,
      });
    }
    onClose();
  };

  const onChange = ({ target }) => {
    const { name, value } = target;
    setSchoolToRemove({ [name]: value });
    // console.log('SchoolToRemoveOnChange', schoolToRemove)
  };

  return (
    <ModalWrapper>
      <form className={styles.RemoveSchoolModal_wrapper} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader header="Remove School" color="darkHeader" onClose={onClose} />
        <p className={styles.RemoveSchoolModal_greyText}>
          Are you sure you want to delete the school "{currentSchool?.Name}"?
        </p>
        <p className={styles.RemoveSchoolModal_redText}>
          Please, enter the school name "{currentSchool?.Name}" to confirm.
        </p>
        <p className={styles.RemoveSchoolModal_smallText}>*all data will be removed</p>
        <div className={styles.RemoveSchoolModal_InputWrapper}>
          <Controller
            control={control}
            name="schoolName"
            rules={{ required: true, minLength: 2 }}
            render={({ field }) => (
              <OutlinedTextField
                variant="outlined"
                margin="normal"
                id="schoolName"
                color="primary"
                label="School name"
                name="schoolName"
                style={{ width: 302, margin: 0 }}
                autoComplete="off"
                error={Boolean(errors?.schoolName)}
                helperText={errors.schoolName?.message}
                {...field}
                onChange={onChange}
              />
            )}
          />
        </div>
        <ModalFooter>
          <ModalSecondaryButton onClick={onClose}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton onClick={onSubmit} classStyle="dark">
            Remove
          </ModalPrimaryButton>
        </ModalFooter>
      </form>
    </ModalWrapper>
  );
};

export default RemoveSchoolModal;

// ===== SCHOOL ENDPOINTS TO BACKEND =====
// Remove school
// base_url/api/school/:id
// type DELETE
