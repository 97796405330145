import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ModalWrapper } from '../../../../../components/Modal/ModalWrapper/ModalWrapper';
import { ModalFooter } from '../../../../../components/Modal/ModalFooter/ModalFooter';
import { ModalSecondaryButton } from '../../../../../components/Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalPrimaryButton } from '../../../../../components/Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { LoaderFullBook } from '../../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { AppContext } from '../../../../../context/AppContext';
import { config } from '../../../../../config';
import ContainChangePassword from './ParentEditProfileModalChangePassword/ParentEditProfileModalChangePassword';
import styles from './ParentEditProfileModal.module.scss';

const ParentEditProfileModal = ({ onClose }) => {
  const { user, updateUser, uploadedPhoto, getUserData, showLoader, setShowLoader } =
    useContext(AppContext);

  const [errorInConfirm, setErrorInConfirm] = useState(false);
  const [formDataPassword, setFormDataPassword] = useState({
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  // console.log('formData', formData)

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (values) => {
    const passwordDataToSend = {
      password: formDataPassword.password,
      newPassword: formDataPassword.newPassword,
    };

    // console.log('requestAddress - /api/auth/password')
    // console.log('formDataPassword.newPassword', formDataPassword.newPassword)
    // console.log('formDataPassword.confirmNewPassword', formDataPassword.confirmNewPassword)
    if (formDataPassword.newPassword !== formDataPassword.confirmNewPassword) {
      setErrorInConfirm(true);
      return;
    }
    try {
      setShowLoader(true);
      const response = await axios.patch(`${config.API_URL}/api/auth/password`, passwordDataToSend);
      if (response.status === 200) {
        setShowLoader(false);
      }
    } catch (e) {
      alert('Current password incorrect. Try again.');
      setShowLoader(false);
    }

    getUserData();
    onClose();
  };

  const onPasswordChange = ({ target }) => {
    const { name, value } = target;
    setFormDataPassword((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <ModalWrapper>
      {showLoader && <LoaderFullBook />}
      <form
        className={styles.ParentEditProfileModal_wrapper}
        onSubmit={handleSubmit((values) => onSubmit(values))}
        // onSubmit={handleSubmit(onSubmit)}
      >
        <ContainChangePassword
          onClose={onClose}
          control={control}
          errors={errors}
          onChange={onPasswordChange}
          errorInConfirm={errorInConfirm}
          setErrorInConfirm={setErrorInConfirm}
        />
        <ModalFooter>
          <ModalSecondaryButton onClick={onClose}>Cancel</ModalSecondaryButton>
          <ModalPrimaryButton onClick={onSubmit}>Save Changes</ModalPrimaryButton>
        </ModalFooter>
      </form>
    </ModalWrapper>
  );
};

export default ParentEditProfileModal;
