import { Pie } from 'react-chartjs-2';

import { PieChart } from '../../components/PieChart/PieChart';
import { TableColorLegend } from '../../components/TableColorLegend/TableColorLegend';

import { useState } from 'react';

import './AnaliticsContainer.scss';

export const AnaliticsContainer = ({
  schoolInfo,
  filterAverageScore,
  setFilterAverageScore,
  filterAverageSpeed,
  setFilterAverageSpeed,
}) => {
  console.log('schoolInfo', schoolInfo)
  // тут из значений (объектов) для диаграмм schoolScoreStatistic и schoolSpeedStatistic мы сортируем по количеству, потом
  // получаем ключи и значения объектов для того, что бы вывести правильный контент на страницу и в диаграму передать массив цифр
  // для отрисрвки от большего к меньшему, плюс приводим количество к процентам
  
  // for  AvScore
  console.log('======SCORE=====');
  const sortableAvScore = Object.fromEntries(
    Object.entries(schoolInfo.schoolScoreStatistic).sort(([, a], [, b]) => b - a)
    );
  console.log('sortableAvScore', sortableAvScore);
  
  const keysSortableAvScore = Object.keys(sortableAvScore);
  console.log('keysSortableAvScore', keysSortableAvScore);
  const valuesSortableAvScore = Object.values(sortableAvScore);
  console.log('valuesSortableAvScore', valuesSortableAvScore);
  
  const sumAvScoreChartValues = valuesSortableAvScore.reduce((sum, num) => sum + num); //+valuesSortableAvScore[0]+ valuesSortableAvScore[1]+ valuesSortableAvScore[2]+ valuesSortableAvScore[3]
  console.log('sumAvScoreChartValues', sumAvScoreChartValues);
  const studentsToOnePrAvScore = +sumAvScoreChartValues / 100;
  console.log('studentsToOnePrAvScore', studentsToOnePrAvScore);

  // for  AvSpeed
    console.log('======SPEED=====');
  const sortableAvSpeed = Object.fromEntries(
    Object.entries(schoolInfo.schoolSpeedStatistic).sort(([, a], [, b]) => b - a)
  );
  console.log('sortableAvSpeed', sortableAvSpeed);
  
  const keysSortableAvSpeed = Object.keys(sortableAvSpeed);
  console.log('keysSortableAvSpeed', keysSortableAvSpeed);
  const valuesSortableAvSpeed = Object.values(sortableAvSpeed);
  console.log('valuesSortableAvSpeed', valuesSortableAvSpeed);
  
  const sumAvSpeedChartValues = valuesSortableAvSpeed.reduce((sum, num) => sum + num);
  console.log('sumAvSpeedChartValues', sumAvSpeedChartValues);
  const studentsToOnePrAvSpeed = +sumAvSpeedChartValues / 100;
  console.log('studentsToOnePrAvSpeed', studentsToOnePrAvSpeed);

  return (
    <div className="analiticsContainer_wrapper">
      <div className="analiticsContainer_chartsWrapper">
        <div className="analiticsContainer_singleChartWrapper">
          <div className="analiticsContainer_chartHeader">AVERAGE SCORE</div>
          <div className="analiticsContainer_chartAndLegend_wrapper">
            <div className="analiticsContainer_chartWrapper">
              <PieChart
                dataToChart={sortableAvScore}
                setStateFilter={setFilterAverageScore}
                type={'score'}
              />
            </div>
            <div className="analiticsContainer_legendWrapper">
              {keysSortableAvScore.map((score, index) => {
                return (
                  <div style={{ textTransform: 'uppercase' }} key={index} className={score}>
                    <span>
                      {!valuesSortableAvScore[index] / studentsToOnePrAvScore
                        ? 0
                        : (valuesSortableAvScore[index] / studentsToOnePrAvScore).toFixed(1)}{' '}
                      %
                    </span>
                    -
                    <span>
                      {score === 'below50'
                        ? 'below 50% accuracy  '
                        : score === 'from50to70'
                        ? '50 - 79% accuracy  '
                        : score === 'from80to89'
                        ? '80 - 89% accuracy  '
                        : '90%+ accuracy   '}
                      ({valuesSortableAvScore[index]} Students)
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="analiticsContainer_tooltipFofClick">
            *Click on the pie chart to open more info
          </div>
        </div>

        <div className="analiticsContainer_singleChartWrapper">
          <div className="analiticsContainer_chartHeader">AVERAGE SPEED </div>
          <div className="analiticsContainer_chartAndLegend_wrapper">
            <div className="analiticsContainer_chartWrapper">
              <PieChart
                dataToChart={sortableAvSpeed}
                setStateFilter={setFilterAverageSpeed}
                type={'speed'}
              />
            </div>

            <div className="analiticsContainer_legendWrapper">
              {keysSortableAvSpeed.map((speed, index) => {
                return (
                  <div style={{ textTransform: 'uppercase' }} key={index} className={speed}>
                    <span>
                      {!valuesSortableAvSpeed[index] / studentsToOnePrAvSpeed
                        ? 0
                        : (valuesSortableAvSpeed[index] / studentsToOnePrAvSpeed).toFixed(1)}{' '}
                      %
                    </span>
                    -
                    <span>
                      {speed === 'belowExpected'
                        ? 'Below expected  '
                        : speed === 'asExpected'
                        ? ' As expected  '
                        : speed === 'aboveExpected'
                        ? 'Above expected  '
                        : null}
                      ({valuesSortableAvSpeed[index]} Students)
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="analiticsContainer_tooltipFofClick">
            *Click on the pie chart to open more info
          </div>
        </div>
      </div>
    </div>
  );
};
