// import { red } from '@material-ui/core/colors';
import styled from 'styled-components/macro';

export const StyledClassGradeBook = styled.div`
  overflow: scroll;
  /* display: flex; */
  /* width: 1360px; */
  max-width: 1360px;
  margin: auto;
  border: 2px solid #f2f2f2;
  margin-top: 8px;
  /* height: 640px; */
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f2f2f2;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 10px;
  }

  .classGradeBook_gradeBook_studentsInfo_container {
    position: sticky;
    left: 0;
    background-color: white;
    margin-right: 24px;
    z-index: 2;
  }
  .classGradeBook_gradeBook_studentsInfo_container.containerMargin{
    width: 260px;
    margin-right: 0;
  }

  .classGradeBook_gradeBook_table_header {
    height: 97px;
    box-sizing: border-box;
    /* margin: auto; */
    border-bottom: 2px solid #f2f2f2;
    flex-wrap: nowrap;
    display: grid;
    width: ${(props) =>
      props.rowLength.length * 262 + /*216*/ +(24 * props.rowLength.length) - 24}px;
    grid-template-columns:
         /* 216px */ ${(props) => props.gridTemplate};
    gap: 24px;
    // overflow: scroll;
  }

  .classGradeBook_gradeBook_table_Wrapper {
    // width: 1360px;
    height: 540px;
    // background: rgb(241, 240, 236);
    /* border-top: 2px solid #F2F2F2; */
    width: 100%;
    box-sizing: border-box;
    // border: 2px solid #F2F2F2;
    /* border-top: none; */
    margin: auto;
    // overflow: scroll;
  }


  .classGradeBook_gradeBook_averageDataColumn {
    display: flex;
    height: 48px;
    box-sizing: border-box;
    border-top: 1px solid #f2f2f2;

    position: relative; // correct
    background-color: white;
    z-index: 3;

    .classGradeBook_gradeBook_averageDataColumn_header {
      /* min-width: 236px; */
      min-width: 260px;
      position: sticky; // correct
      left: 0; // correct
      background-color: white;
      font-family: Rubik;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: right;
      line-height: 48px;
      color: #828282;
      margin-right: 24px;
    }
  }
  .classGradeBook_gradeBook_averageDataColumn_allTestsWrapper {
    display: grid;
    width: ${(props) => props.rowLength.length * 262 + /*216*/ +(24 * props.rowLength.length)}px;
    grid-template-columns:
         /* 216px */ ${(props) => props.gridTemplate};
    gap: 24px;
  }
  .classGradeBook_gradeBook_averageDataColumn_testData {
    box-sizing: border-box;
    border: 1px solid #f2f2f2;
    border-bottom: none;
    display: flex;
  }
  .classGradeBook_gradeBook_averageDataColumn_testScore {
    width: 55px;
    margin-left: 37px;
    //styleName: Header Menu Categories Active;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    line-height: 48px;
  }
  .classGradeBook_gradeBook_averageDataColumn_testSpeed {
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    line-height: 48px;
  }
`;
