import React from 'react';
import notFoundPageImage from '../../images/sleepImg.png';
import roundBGImg from '../../images/roundBGImage.png';
import { InternalPageContainer } from '../../components/InternalPageContainer/InternalPageContainer';
import { StyledNotFoundPage } from "./StyledNotFoundPage"

function NotFoundPage({ text }) {
  return (
    <StyledNotFoundPage>
      <InternalPageContainer />
      <p className="NotFoundPageText">
        {text ? text : 'Oops! Page not found. Please, try again later'}
      </p>
      <div className="NotFoundPageImgWrapper">
        <img src={notFoundPageImage} className="NotFoundPageImage" alt="Not found image" />
        <img src={roundBGImg} className="roundBGImg" alt="roundBGImg" />
      </div>
    </StyledNotFoundPage>
  );
}

export default NotFoundPage;
