import { FC, memo, useContext } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { ReactComponent as MicrosoftLogo } from './microsoft-logo.svg';
import { ReactComponent as CleverLogo } from './clever-logo.svg';
import styles from './VendorSignInButtons.module.scss';

export const VendorSignInButtons: FC = memo(() => {
  const {socialLinks}= useContext(AppContext);
  const {googleLink, microsoftLink} = socialLinks;
  // console.log('socialLinks', socialLinks)

  return(
    <>
      <div className={styles.heading}>
        <div className={styles.line} />
        <span className={styles.text}>or sign in with</span>
        <div className={styles.line} />
      </div>

      <div className={styles.root}>
        <div className={styles.item}>
          <a href={googleLink}>
            <GoogleLogo />
          </a> 
        </div>
        {/* <div className={styles.item}>
          <a href={microsoftLink}>
          <MicrosoftLogo />
          </a> 
        </div> */}
        {/* <div className={styles.item}>
          <CleverLogo />
        </div> */}
      </div>
    </>
  )
})
