import { useState, useEffect, useContext } from 'react';
import ButtonText from '../../buttons/buttonText/ButtonText';
import { DropdownSelect } from '../../DropdownSelect/DropdownSelect';
import { DatePickerRange } from '../../DatePicker/DatePicker';
import { TableHeadLine } from '../../TableHeadLine/TableHeadLine';
import { config } from "../../../config"
import { AppContext } from '../../../context/AppContext';


import './CommonSubHeader.scss';


// const allConcepts = ['Arithmetic', 'Multiplication'];

const responseForGreyHeader = {
  teachers: [
    {
      teacherName: 'Amanda Biets',
      teacherId: 123445678,
      classes: {
        2: [
          { class: '2A', id: 22345 },
          { class: '2B', id: 32345 },
          { class: '2C', id: 42345 },
          { class: '2D', id: 52345 },
          { class: '2E', id: 62345 },
        ],
        3: [
          { class: '3A', id: 72345 },
          { class: '3B', id: 82345 },
        ],
      },
    },
    {
      teacherName: 'Nancy Fisher-Alda-Heik-Abram',
      teacherId: 34567890,
      classes: {
        1: [{ class: '1A', id: 12345 }],
        2: [
          { class: '2A', id: 22345 },
          { class: '2B', id: 32345 },
          { class: '2C', id: 42345 },
          { class: '2D', id: 52345 },
          { class: '2E', id: 62345 },
        ],
        3: [
          { class: '3A', id: 72345 },
          { class: '3B', id: 82345 },
        ],
      },
    },
    {
      teacherName: 'Tom Fort',
      teacherId: 76543223,
      classes: {
        4: [
          { class: '4A', id: 92345 },
          { class: '4B', id: 10345 },
          { class: '4C', id: 11345 },
          { class: '4D', id: 13345 },
          { class: '4E', id: 14345 },
        ],
      },
    },
    {
      teacherName: 'Henry Robson',
      teacherId: 3456787654,
      classes: {
        5: [
          { class: '5A', id: 15345 },
          { class: '5B', id: 16345 },
          { class: '5C', id: 17345 },
          { class: '5D', id: 18345 },
          { class: '5E', id: 19345 },
        ],
        6: [
          { class: '6A', id: 23345 },
          { class: '6B', id: 24345 },
          { class: '6C', id: 25345 },
          { class: '6D', id: 26345 },
          { class: '6E', id: 27345 },
        ],
      },
    },
  ],

  classes: {
    1: [{ class: '1A', id: 12345 }],
    2: [
      { class: '2A', id: 22345 },
      { class: '2B', id: 32345 },
      { class: '2C', id: 42345 },
      { class: '2D', id: 52345 },
      { class: '2E', id: 62345 },
    ],
    3: [
      { class: '3A', id: 72345 },
      { class: '3B', id: 82345 },
    ],
    4: [
      { class: '4A', id: 92345 },
      { class: '4B', id: 10345 },
      { class: '4C', id: 11345 },
      { class: '4D', id: 13345 },
      { class: '4E', id: 14345 },
    ],
    5: [
      { class: '5A', id: 15345 },
      { class: '5B', id: 16345 },
      { class: '5C', id: 17345 },
      { class: '5D', id: 18345 },
      { class: '5E', id: 19345 },
    ],
    6: [
      { class: '6A', id: 23345 },
      { class: '6B', id: 24345 },
      { class: '6C', id: 25345 },
      { class: '6D', id: 26345 },
      { class: '6E', id: 27345 },
    ],
  },
};
const sortByDefault = ['grade', 'class', 'teachers'];

export const AnalyticsPageHeader = ({
  classFilter,
  setClassFilter,
  sortByClass,
  // conceptFilter,
  setConceptFilter,
  startDateFilter,
  setStartDateFilter,
  endDateFilter,
  setEndDateFilter,
  searchValue,
  setSearchValue,
  handleOnClickDownloadCsv,
  tableDataStudents
}) => {
  console.log('classFilter', classFilter)
  const {showLoader, setShowLoader, currentSchoolId}= useContext(AppContext);

  const [forGreyHeader, setForGreyHeader] = useState(responseForGreyHeader);
  const [sortBy, setSortBy] = useState(sortByClass);
  const [startDateVisual, setStartDateVisual] = useState(null);
  const [endDateVisual, setEndDateVisual] = useState(null);

  console.log('sortBy', sortBy)

  useEffect(() => {
    setSortBy(sortByClass || undefined)
    setStartDateVisual(null)
    setEndDateVisual(null)
  }, [currentSchoolId])
  // console.log(forGreyHeader)

  useEffect(() => {
    fetch(`${config.API_URL}/api/header/info?schoolId=${currentSchoolId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
        credentials: 'include',
      },
    })
      .then(async (res) => await res.json())
      .then((json) => {
        setForGreyHeader(json);
      });
  }, [currentSchoolId]);

  const handleClearFilters = () => {
    setClassFilter([]);
    setConceptFilter(null);
    setStartDateFilter(null);
    setEndDateFilter(null);
    setStartDateVisual(null);
    setEndDateVisual(null);
    // setGradeFilter(null)
  };

  return (
    <>
      <div className="analyticsPageHeader_wrapper">
        <div className="greyDropdownWrapper">
          <div className="all_selectWrapper">
            {/* простой селект без вложеностей */}
            {/* < DropdownSelect
              dataToSelect={allConcepts}
              defaultValue={'All Concepts'}
              stateFilter={conceptFilter}
              setStateFilter={setConceptFilter}
            /> */}
            <DropdownSelect
              dataToSelect={sortByDefault}
              defaultValue={'Filter By ...'}
              stateFilter={sortBy}
              setStateFilter={setSortBy}
              type={'sortBy'}
              setClassFilter={setClassFilter}
            />
            {sortBy === 'grade' && (
              <DropdownSelect
                dataToSelect={forGreyHeader.classes}
                defaultValue={'All Grades'}
                setStateFilter={setClassFilter}
                stateFilter={classFilter}
                type={'grade'}
              />
            )}
            {sortBy === 'class' && (
              <DropdownSelect
                dataToSelect={forGreyHeader.classes}
                defaultValue={'All Classes'}
                setStateFilter={setClassFilter}
                stateFilter={classFilter}
                sortByClass={sortByClass}
                type={'class'}
              />
            )}
            {sortBy === 'teachers' && (
              <DropdownSelect
                dataToSelect={forGreyHeader.teachers.filter(teacher => teacher.isArchive === 0)}
                defaultValue={'All Teachers'}
                setStateFilter={setClassFilter}
                stateFilter={classFilter}
                type={'teachers'}
              />
            )}
          </div>
          <DatePickerRange
            type={'light'}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}
            startDateVisual={startDateVisual}
            setStartDateVisual={setStartDateVisual}
            endDateVisual={endDateVisual}
            setEndDateVisual={setEndDateVisual}
          />
          <ButtonText
            type={`4`}
            text={`Clear all`}
            iconName={`Clear`}
            active={true}
            funtionality={() => {
              handleClearFilters();
              setSortBy('');
            }}
          />
        </div>
      </div>

      <TableHeadLine
        headlineType="analytics"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleOnClickDownloadCsv={handleOnClickDownloadCsv}
        tableDataStudents={tableDataStudents}
      />
    </>
  );
};
