import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { GreyArrow } from '../../../../images/SvgElements/GreyArrow';
import { ReadingBoyAndGirl } from '../../../../images/SvgElements/ReadingBoyAndGirl';
import { CircleReadingBoyAndGirl } from '../../../../images/SvgElements/ReadingBoyAndGirl';
// import { CheckBox } from '../../../../components/CheckBox/CheckBox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useState, useContext } from 'react';
import { TableCell } from '../../../../components/TableCell/TableCell';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { TeacherInfoPopOver } from '../../../../components/TeacherInfoPopOver/TeacherInfoPopOver';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import './GradebooksRow.scss';
// import { FormatAlignJustify } from '@material-ui/icons';
import { SelectGradeBook } from '../SelectGradeBook/SelectGradeBook';
import { AppContext } from '../../../../context/AppContext';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    color: 'rgba(91, 91, 91, 1)',
    boxShadow: theme.shadows[1],
    // fontSize: 12,
    fontSize: 14,
  },
}));

export const GradebooksRow = ({
  setClassInfo,
  checkBoxActive,
  setCheckBoxActive,
  grade,
  allClasses,
  index,
  activeClasses,
  setActiveClasses,
  handleOpenEditClassModal,
  handleOpenUpgradeModal,
  handleOpenArchiveModal,
  setSelectedClass,
}) => {
  // console.log('index', index)
  // const [ gradeActive, setGradeActive ] = useState(null)
  let gradeActive = null;
  const [showClasses, setShowClasses] = useState(false);
  // let allCllassesId = allClasses.reduce((sum, current)=> [ ...sum, current.classId], [] );

  let numOfActiveClassesInGrade = 0;
  for (let i = 0; i < allClasses.length; i++) {
    for (let k = 0; k < activeClasses.length; k++) {
      if (allClasses[i].classId === activeClasses[k]) {
        numOfActiveClassesInGrade += 1;
      }
    }
  }
  if (numOfActiveClassesInGrade > 0) {
    gradeActive = false;
    // console.log('at least one class selected');
  }

  if (allClasses.length !== 0 && numOfActiveClassesInGrade === allClasses.length) {
    gradeActive = true;
    // console.log('gradeActive');
  }

  const handleActiveGrade = () => {
    let result = allClasses.reduce((sum, current) => [...sum, current.classId], []);
    // console.log('result', result);
    const concatStateAndResult = [...result, ...activeClasses];
    const someUniqueClasses = [...new Set(concatStateAndResult)];
    setActiveClasses(someUniqueClasses);
    //    setGradeCheckboxActive
  };

  const handleUnActiveGrade = () => {
    function search(a, b) {
      var result = [];
      a.forEach((v) => {
        if (b.indexOf(v) === -1) {
          result.push(v);
        }
      });
      return result;
    }
    let result = allClasses.reduce((sum, current) => [...sum, current.classId], []);
    setActiveClasses((prevState) => [...search(prevState, result)]);
  };
  // 1) найти есть ли в это грейте как минимум классАйди как и в активном классе тогда сделать фон грейда другой   \YES
  // 2) проверитьт возможно все классы в грейде активные тогда сделать грейд активным   YES
  // 3) при нажатии чекбокса грейда помешать все кклассы грейда в активные активные, при нажатти снова, удалять из активных

  return (
    <>
      <div
        className="gradeBookPage_gradeRowWrapper"
        style={{
          border: gradeActive !== null ? '1px solid #C0C0C0' : null,
          background: gradeActive !== null ? '#F2F2F2' : null,
        }}
      >
        {allClasses && allClasses.length > 0 ? (
          <div className="checkbox_wrapper">
            {gradeActive ? (
              <CheckBoxTwoToneIcon
                onClick={() => {
                  handleUnActiveGrade();
                }}
                className="checkBoxOutlineBlank"
              />
            ) : (
              <CheckBoxOutlineBlankTwoToneIcon
                onClick={() => {
                  handleActiveGrade();
                }}
              />
            )}
          </div>
        ) : (
          <div style={{ cursor: 'not-allowed' }} className="checkbox_wrapper">
            {
              <CheckBoxOutlineBlankTwoToneIcon style={{ cursor: 'not-allowed' }} /> // неактивный чекбокс, добавляем
            }
          </div>
        )}
        <div
          className="gradeBookPage_gradeTextRowWrapper"
          onClick={() => {
            setShowClasses((prevState) => !prevState);
          }}
        >
          <div style={{ cursor: 'pointer' }} className="gradeBookPage_gradeRowText">
            {' '}
            {grade} Grade{' '}
          </div>
          <ArrowDropDownIcon />
        </div>
      </div>

      <div className="">
        {!allClasses ||
          (allClasses.length === 0 && (
            <div
              style={{ height: showClasses ? '296px' : '0px' }}
              className="gradeBookPage_noClassesInGradeWrapper"
            >
              <div className="gradeBookPage_noClassesText">
                You have no classes yet. Please add class to continue
              </div>
              <div className="gradeBookPage_readingBoyAndGirlWrapper">
                <ReadingBoyAndGirl />
                <CircleReadingBoyAndGirl />
              </div>
            </div>
          ))}
        {allClasses && allClasses.length > 0 && (
          <div
            style={{ maxHeight: showClasses ? '496px' : '0px' }}
            className="gradeBookPage_allClassesWrapper"
          >
            {allClasses.map((classes, idx) => {
              return (
                <TableRowOfClassInGradebooks
                  key={idx}
                  setClassInfo={setClassInfo}
                  classes={classes}
                  activeClasses={activeClasses}
                  setActiveClasses={setActiveClasses}
                  handleOpenEditClassModal={handleOpenEditClassModal}
                  handleOpenUpgradeModal={handleOpenUpgradeModal}
                  handleOpenArchiveModal={handleOpenArchiveModal}
                  setSelectedClass={setSelectedClass}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

const TableRowOfClassInGradebooks = ({
  classes,
  activeClasses,
  setActiveClasses,
  handleOpenEditClassModal,
  handleOpenUpgradeModal,
  handleOpenArchiveModal,
  setSelectedClass
}) => {
  const { setClassInfo } = useContext(AppContext);

  let localStateToShowCheckbox = false;
  for (let i = 0; i < activeClasses.length; i++) {
    // console.log( 'activeClasses[i]',  activeClasses[i])
    // console.log( 'classes.id',  classes.classId)
    if (activeClasses[i] === classes.classId) {
      // console.log('its active', activeClasses[i] )
      localStateToShowCheckbox = true;
    }
  }

  // to show more teacher info popover
  const [iconAnchorEl, setIconAnchorEl] = useState(null);
  const openTeacherInfo = Boolean(iconAnchorEl);
  const handleOpenInfoTeacher = (event) => setIconAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setIconAnchorEl(null);
  // to show more actions popover
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const join = classes.teachers
    .filter((item) => item.isArchive !== 1)
    .map((teacher) => {
      return teacher.name + '';
    })
    .join(', ');

  const deleteActiveClassHandler = (activeClassId) => {
    const dataToSetAsActiveClass = [...activeClasses];
    const index = dataToSetAsActiveClass.indexOf(activeClassId);
    if (index > -1) {
      dataToSetAsActiveClass.splice(index, 1);
    }
    setActiveClasses(dataToSetAsActiveClass);
  };
  // console.log('classes', classes);

  return (
    <div
      style={{
        background: localStateToShowCheckbox ? '#F2F2F2' : '#F9F9F9',
        border: localStateToShowCheckbox ? '1px solid #C0C0C0' : null,
      }}
      className="gradeBookPage_classRowWrapper"
    >
      <div className="checkbox_wrapper">
        {localStateToShowCheckbox ? (
          <CheckBoxTwoToneIcon
            onClick={() => {
              deleteActiveClassHandler(classes.classId);
            }}
            className="checkBoxOutlineBlank"
          />
        ) : (
          <CheckBoxOutlineBlankTwoToneIcon
            onClick={() => {
              setActiveClasses((prevState) => [...prevState, classes.classId]);
            }}
          />
        )}
      </div>

      <TableCell className="gradeBookPage_cell_classWrapper" style={{ width: 30 }}>
        {classes?.className ? classes.className : classes.class}
        {/* {classes(Both-base)?.className(TESTING-base) ? classes.className(TESTING-base) : classes.class(Both-base)} */}
        {/* class --> in prod-base returns: grade+className - in testing-base returns: only grade */}
      </TableCell>
      <TableCell className="gradeBookPage_cell_classWrapper">{classes.year}</TableCell>
      <div style={{ display: 'flex' }} className="">
        TEACHERS:
        {classes.teachers.filter((item) => item.isArchive !== 1).length > 0 ? (
          <div
            // style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
            className="teacherNames"
          >
            <div className="tableRowStudentAnalytics_svg">
              <InfoOutlinedIcon onClick={handleOpenInfoTeacher} />
            </div>
            <Popover
              open={openTeacherInfo}
              onClose={handleCloseInfoTeacher}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorEl={iconAnchorEl}
              elevation={2}
            >
              <Typography component={'span'} variant={'body2'}>
                <TeacherInfoPopOver
                  teachers={classes.teachers.filter((item) => item.isArchive !== 1)}
                />
              </Typography>
            </Popover>

            {join.length >= 32 ? join.slice(0, 30) + '. . .' : join}
          </div>
        ) : (
          <>
            <span style={{ width: '10px' }}></span>
            <span>No data</span>
          </>
        )}
      </div>
      <div style={{ display: 'flex' }} className="">
        <TableCell className="gradeBookPage_cell_classWrapper">
          AV. SCORE:
          {classes.avScore ? (
            <>
              <span style={{ width: '10px' }}></span>

              <span style={{ color: classes.avScore.color }}>
                {classes.avScore.score.toFixed(0)}{' '}
              </span>
            </>
          ) : (
            <>
              <span style={{ width: '10px' }}></span>
              <span>No data</span>
            </>
          )}
        </TableCell>
      </div>
      <div style={{ display: 'flex' }} className="">
        <TableCell className="gradeBookPage_cell_classWrapper">
          AV. SPEED:
          {classes.avSpeed && classes.avScore ? (
            <>
              <span style={{ width: '10px' }}></span>
              <span style={{ color: classes.avSpeed.color }}>{classes.avSpeed.speed} </span>
            </>
          ) : (
            <>
              <span style={{ width: '10px' }}></span>
              <span>No data</span>
            </>
          )}
        </TableCell>
      </div>
      <TableCell className="gradeBookPage_cell_classWrapper">
        <LightTooltip title="More Actions" placement="top">
          <MoreVertIcon style={{ cursor: 'pointer', marginRight: 5 }} onClick={handleClick} />
        </LightTooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          elevation={2}
        >
          <SelectGradeBook
            classes={classes}
            setClassInfo={setClassInfo}
            classId={classes.classId}
            classTitle={`${classes.class}${classes.className}`}
            handleOpenEditClassModal={handleOpenEditClassModal}
            handleOpenUpgradeModal={handleOpenUpgradeModal}
            handleOpenArchiveModal={handleOpenArchiveModal}
            onClose={handleClose}
            setActiveClasses={setActiveClasses}
            setSelectedClass={setSelectedClass}
          />
        </Popover>
        <LightTooltip title="Open Gradebook in the New Tab" placement="top">
          <Link
            target="_blank"
            className="movieLink"
            to={`/gradebook/class?class=${classes.class}&classId=${classes.classId}`}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <OpenInNewIcon
              style={{ cursor: 'pointer' }}
              onClick={() => {
                console.log('open in new');
              }}
            />
          </Link>
        </LightTooltip>
      </TableCell>
    </div>
  );
};
