import noTestsImage from '../../../../images/noTestsContent.svg';
import arrowImage from '../../../../images/arrow.svg';
import styles from './NoTestsIndicator.module.scss';

function NoTestsIndicator() {
  return (
    <section className={styles.inner}>
      <p className={styles.description}>You have no tests yet. Please, add test to continue</p>
      <div className={styles.content}>
        <img
          className={styles.image}
          src={noTestsImage}
          width="331"
          height="317"
          alt="Smart pencil"
        />
        <img
          className={styles.arrow}
          src={arrowImage}
          width="266"
          height="148"
          alt="Navigate arrow"
        />
      </div>
    </section>
  );
}

export default NoTestsIndicator;
