import React, { useCallback, useEffect, useState } from 'react';
import { ModalPrimaryButton } from '../../Modal/buttons/ModalPrimaryButton/ModalPrimaryButton';
import { ModalSecondaryButton } from '../../Modal/buttons/ModalSecondaryButton/ModalSecondaryButton';
import { ModalContainer } from '../../Modal/ModalContainer/ModalContainer';
import { ModalFooter } from '../../Modal/ModalFooter/ModalFooter';
import { ModalHeader } from '../../Modal/ModalHeader/ModalHeader';
import { ModalWrapper } from '../../Modal/ModalWrapper/ModalWrapper';
import { Alert } from '../../Alert/Alert';

import styles from './TestsModalForArchiving.module.scss';

import { TestsService } from '../../../pages/TestsPage/TestsService';
const service = new TestsService();

function TestsModalForArchiving({
  archiveData,
  modalArchive,
  close,
  closeModal,
  callRerender,
  setSelectedTests = () => {},
}) {
  const [loader, setLoader] = useState(false);
  const [isAlert, setIsAlert] = useState({
    type: false,
    text: '',
    close: () => {},
    show: false,
  });

  const callAlert = useCallback((obj) => setIsAlert(obj), []);
  const closeAlert = useCallback(
    () =>
      setIsAlert({
        type: false,
        text: '',
        close: () => {},
        show: false,
      }),
    []
  );

  const delayedClosure = useCallback(
    () =>
      setTimeout(() => {
        close();
        closeModal();
      }, 1000),
    [close]
  );

  const onArchiveTests = useCallback(() => {
    setLoader(true);
    service
      .archiveTests(archiveData)
      .then(() => {
        setLoader(false);
        callRerender(true);
        setSelectedTests([]);
        callAlert({
          type: true,
          text: 'Operation Success!',
          close: closeAlert,
          show: true,
        });
        delayedClosure();
      })
      .catch(() => {
        setLoader(false);
        callAlert({
          type: false,
          text: 'Ooops, server error.' + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
        callRerender(false);
      });
  }, [archiveData, callAlert, callRerender, closeAlert, delayedClosure, setSelectedTests]);

  const onUnarchiveTests = useCallback(() => {
    setLoader(true);
    service
      .unarchiveTests(archiveData)
      .then(() => {
        setLoader(false);
        callRerender(true);
        setSelectedTests([]);
        callAlert({
          type: true,
          text: 'Operation Success!',
          close: closeAlert,
          show: true,
        });
        delayedClosure();
      })
      .catch(() => {
        setLoader(false);
        callAlert({
          type: false,
          text: 'Ooops, server error.' + ' Please, contact the admin of the website.',
          close: closeAlert,
          show: true,
        });
        callRerender(false);
      });
  }, [archiveData, callAlert, callRerender, closeAlert, delayedClosure, setSelectedTests]);

  useEffect(() => {
    return () => {
      clearTimeout(delayedClosure);
    };
  }, [delayedClosure]);

  const header = `${modalArchive ? 'Archive' : 'Unarchive'} Test(s)`;
  const text = `Are you sure you want to ${
    modalArchive ? 'archive' : 'unarchive'
  } selected test(s)?`;
  const btnText = modalArchive ? 'Archive' : 'Unarchive';
  const btnAction = modalArchive ? onArchiveTests : onUnarchiveTests;

  return (
    <>
      <ModalWrapper loader={loader}>
        <ModalContainer>
          <ModalHeader header={header} onClose={close} color="darkHeader" />
          <div className={styles.text}>{text}</div>
          <ModalFooter>
            <ModalSecondaryButton onClick={close}>Cancel</ModalSecondaryButton>
            <ModalPrimaryButton classStyle={'dark'} onClick={btnAction}>
              {btnText}
            </ModalPrimaryButton>
          </ModalFooter>
        </ModalContainer>
      </ModalWrapper>
      {isAlert.show && <Alert alertType={isAlert.type} text={isAlert.text} close={isAlert.close} />}
    </>
  );
}

export default TestsModalForArchiving;
