import { useEffect, useState, useCallback } from "react";
import { Pagination } from "../../../components/Pagination/Pagination";
import { Table } from "../../../components/Table/Table";
import { TableHeadLine } from "../../../components/TableHeadLine/TableHeadLine";
import SubHeaderCollapse from "../../../components/SubHeaderCollapse";
import StudentsModalArchive from "../StudentModalInfo/StudentsModalArchive/StudentsModalArchive";
import NoSearchResultsIndicator from "../../../components/NoSearchResults/NoSearchResultsIndicator";

const StudentsContent = ({
  setShowAlert,
  openModal,
  tableData,
  submitForm,
  callAlert,
  fillDefaultEditValues,
  closeAlert,
  openModalEdit,

  classesFilterId,
  scoreFilterId,
  speedFilterId,

  setClassesFilterId,
  setSpeedFilterId,
  setScoreFilterId,
}) => {
  const [noResultPage, setNoResultPage] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [paginationData, setPaginationData] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [isSelectAll, setIsSelectedAll] = useState(false);
  const [modalArchive, setOpenModalArchive] = useState(false);
  const [selectedStudentType, setSelectedStudentType] = useState(2);
  const [modalUnArchive, setModalUnArchive] = useState(false);

  const closeModalUnArchive = useCallback(() => {
    setModalUnArchive(false);
    setOpenModalArchive(false);
    setSelectedStudent([]);
  }, []);

  const openModalUnArchive = useCallback((student) => {
    if (student?.studentId) {
      const newArr = []
      newArr.push(student)
      setSelectedStudent(newArr);
    }
    setModalUnArchive(true);
    setOpenModalArchive(true);
  }, []);

  const [tableDataClone, setTableDataClone] = useState([...tableData]);
  const [secondTableClone, setSecondTableClone] = useState([]);
  useEffect(() => {
    setSecondTableClone(tableDataClone);
  }, [tableDataClone])

  useEffect(() => {
    let newArr = [...tableData]

    if (classesFilterId !== null) {
      // tableData.forEach(item => {
      //   if (item.classId === classesFilterId) {
      //     newArr.push(item)
      //   }
      // });

      newArr = newArr.filter(item => item.classId === classesFilterId)

      if (newArr.length) {
        setTableDataClone(newArr);
        setNoResultPage(false);
      } else {
        setNoResultPage(true);
      }
    }
    if (scoreFilterId !== null) {
      switch (scoreFilterId) {
        case (1):
          // tableData.forEach(item => {
          //   if (item?.avScore?.score >= 90) {
          //     newArr.push(item)
          //   }
          // });
          newArr = newArr.filter(item => item?.avScore?.score >= 90)
          break;
        case (2):
          // tableData.forEach(item => {
          //   if (item?.avScore?.score >= 80 && item?.avScore?.score < 90) {
          //     newArr.push(item)
          //   }
          // });
          newArr = newArr.filter(item => item?.avScore?.score >= 80 && item?.avScore?.score < 90)
          break;
        case (3):
          // tableData.forEach(item => {
          //   if (item?.avScore?.score >= 50 && item?.avScore?.score <= 79) {
          //     newArr.push(item)
          //   }
          // });
          newArr = newArr.filter(item => item?.avScore?.score >= 50 && item?.avScore?.score <= 79)
          break;
        case (4):
          // tableData.forEach(item => {
          //   if (item?.avScore?.score < 50) {
          //     newArr.push(item)
          //   }
          // });
          newArr = newArr.filter(item => item?.avScore?.score < 50)
          break;
        default:
          break;
      }

      if (newArr.length) {
        setTableDataClone(newArr);
        setNoResultPage(false);
      } else {
        setNoResultPage(true);
      }
    }
    if (speedFilterId !== null) {
      switch (speedFilterId) {
        case (1):
          // tableData.forEach(item => {
          //   if (item?.avSpeed?.speed === 'Above Expected') {
          //     newArr.push(item)
          //   }
          // });
          newArr = newArr.filter(item => item?.avSpeed?.speed === 'Above Expected')
          break;
        case (0):
          // tableData.forEach(item => {
          //   if (item?.avSpeed?.speed === 'As Expected') {
          //     newArr.push(item)
          //   }
          // });
          newArr = newArr.filter(item => item?.avSpeed?.speed === 'As Expected')
          break;
        case (-1):
          // tableData.forEach(item => {
          //   if (item?.avSpeed?.speed === 'Below Expected') {
          //     newArr.push(item)
          //   }
          // });
          newArr = newArr.filter(item => item?.avSpeed?.speed === 'Below Expected')
          break;
        default:
          break;
      }

      if (newArr.length) {
        setTableDataClone(newArr);
        setNoResultPage(false);
      } else {
        setNoResultPage(true);
      }
    }
    if (classesFilterId === null && scoreFilterId === null && speedFilterId === null) {
      setTableDataClone([...tableData]);
      setNoResultPage(false)
    }

    setPageSize(10);
    setCurrentPage(0);
  }, [classesFilterId, scoreFilterId, speedFilterId, tableData])

  useEffect(() => {
    setSelectedStudentType(selectedStudent.length ? selectedStudent[0].isArchive : 2);
  }, [selectedStudent])

  const openModalArchive = useCallback(() => setOpenModalArchive(true), []);
  const closeModalArchive = useCallback(() => setOpenModalArchive(false), []);

  useEffect(() => {
    const from = currentPage * pageSize;
    const to = (currentPage + 1) * pageSize;
    setPaginationData([...secondTableClone.slice(from, to)]);
    setIsSelectedAll(false);
    setSelectedStudent([]);
  }, [pageSize, currentPage, secondTableClone, isSort])

  useEffect(() => {
    if (!selectedStudent.length) {
      setIsSelectedAll(false)
    }
  }, [selectedStudent])

  const openSelectMenu = (student, operation) => {
    if (operation) {
      setSelectedStudent(prev => {
        const newArr = [];
        newArr.push(...prev);
        const index = newArr.findIndex(item => {
          if (item.studentId === student.studentId) {
            return true;
          }
          return false;
        });
        newArr.splice(index, 1);
        return newArr
      });
    } else {
      setSelectedStudent(prev => {
        const newArr = [];
        newArr.push(...prev, student);
        return newArr
      });
    }
  }

  const selectAll = (operation) => {
    if (operation) {
      setSelectedStudent([]);
      setIsSelectedAll(false);
    } else {
      const newArr = [];

      paginationData.forEach(item => {
        if (item.isArchive === 0) {
          newArr.push(item);
        }
      })
      setIsSelectedAll(true);
      setSelectedStudent(Array.of(...newArr));
    }
  }

  const sortByArchive = (data) => {
    data.sort((a, b) => {
      return a.isArchive - b.isArchive;
    })
    return data;
  }

  const [scoreSortDir, setScoreSortDir] = useState(0);
  const [speedSortDir, setSpeedSortDir] = useState(0);
  const [letterSortDir, setLetterSortDir] = useState(0);

  const sortBySpeed = useCallback(() => {
    setLetterSortDir(0);
    setScoreSortDir(0);
    let newArr = [];
    let secArr = [];
    if (speedSortDir === 0 || speedSortDir === -1) {
      [...tableDataClone].forEach(item => {
        if (item?.avSpeed?.value !== undefined) {
          secArr.push(item)
        } else {
          newArr.push(item);
        }
      });
      secArr = [...secArr].sort((a, b) => b.avSpeed.value - a.avSpeed.value);
      newArr = [...secArr, ...newArr];
      setSpeedSortDir(1);
    } else if (speedSortDir === 1) {
      [...tableDataClone].forEach(item => {
        if (item?.avSpeed?.value !== undefined) {
          secArr.push(item)
        } else {
          newArr.push(item);
        }
      });
      secArr = [...secArr].sort((a, b) => a.avSpeed.value - b.avSpeed.value);
      newArr = [...secArr, ...newArr];
      setSpeedSortDir(-1)
    }
    setTableDataClone(sortByArchive(newArr));
    setIsSort(!isSort);
  }, [speedSortDir, isSort, tableDataClone]);

  const sortByLetter = useCallback(() => {
    setScoreSortDir(0);
    setSpeedSortDir(0);
    let newArr;
    if (letterSortDir === 0 || letterSortDir === -1) {
      newArr = [...tableDataClone].sort((a, b) => a.lastName.localeCompare(b.lastName));
      setLetterSortDir(1);
    } else if (letterSortDir === 1) {
      newArr = [...tableDataClone].sort((a, b) => b.lastName.localeCompare(a.lastName));
      setLetterSortDir(-1);
    }

    setTableDataClone(sortByArchive(newArr));
    setIsSort(!isSort);
  }, [letterSortDir, isSort, tableDataClone]);

  const sortByScore = useCallback(() => {
    setLetterSortDir(0);
    setSpeedSortDir(0);
    let newArr = [];
    let secArr = [];
    if (scoreSortDir === 0 || scoreSortDir === -1) {
      [...tableDataClone].forEach(item => {
        if (item?.avScore?.score !== undefined) {
          secArr.push(item)
        } else {
          newArr.push(item);
        }
      });
      secArr = [...secArr].sort((a, b) => b.avScore.score - a.avScore.score);
      newArr = [...secArr, ...newArr];
      setScoreSortDir(1);
    } else if (scoreSortDir === 1) {
      [...tableDataClone].forEach(item => {
        if (item?.avScore?.score !== undefined) {
          secArr.push(item)
        } else {
          newArr.push(item);
        }
      });
      secArr = [...secArr].sort((a, b) => a.avScore.score - b.avScore.score);
      newArr = [...secArr, ...newArr];
      setScoreSortDir(-1)
    }

    setTableDataClone(sortByArchive(newArr));
    setIsSort(!isSort);

  }, [scoreSortDir, isSort, tableDataClone]);

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue) {
      const newArr = [];

      secondTableClone.forEach(item => {
        let searchByName = item.name;
        let searchByFirstParent = item?.parentsDetailInfo?.firstParent?.name;
        let searchBySecondParent = item?.parentsDetailInfo?.secondtParent?.name;
        let searchById = item.id;

        if (searchByName.toLowerCase().includes(searchValue.toLowerCase())
          || searchByFirstParent.toLowerCase().includes(searchValue.toLowerCase())
          || searchBySecondParent?.toLowerCase().includes(searchValue.toLowerCase())
          || searchById?.toLowerCase().includes(searchValue.toLowerCase())) {
          newArr.push(item);
        }
      });

      if (newArr.length) {
        setSecondTableClone(newArr);
        setNoResultPage(false);
      } else {
        setNoResultPage(true);
      }
    } else {
      setSecondTableClone(tableDataClone);
    }
  }, [searchValue])

  return (
    <div>
      {selectedStudent.length ? (
        <SubHeaderCollapse
          selectedType={selectedStudentType}
          dataType='student'
          setActiveCounter={setSelectedStudent}
          activeCounter={selectedStudent}
          openModalArchive={openModalArchive}
          openModalUnArchive={openModalUnArchive}
        />
      ) : (
        <TableHeadLine
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          dataType='student'
          tableRowData={paginationData}
          openModal={openModal}
          headlineType={'students'}
          config={{ headline: 'headlineInputTeachersWrapper' }}
        />
      )}

      {noResultPage ? (
        <div className="noResultWrapper">
          <NoSearchResultsIndicator
            searchValue={searchValue}
            backFn={() => {
              setPageSize(10);
              setCurrentPage(0);
              setClassesFilterId(null);
              setSpeedFilterId(null);
              setScoreFilterId(null);
              setNoResultPage(false);
              setSearchValue('');
            }}
          />
        </div>
      ) : (
        <>
          <Table
            fillDefaultEditValues={fillDefaultEditValues}
            openModalEdit={openModalEdit}
            callAlert={callAlert}
            closeAlert={closeAlert}
            openModalUnArchive={openModalUnArchive}
            setIsSelectedAll={setIsSelectedAll}
            selectedTeacherType={0}
            selectedStudent={selectedStudent}
            openSelectMenu={openSelectMenu}
            selectAll={selectAll}
            isSelectAll={isSelectAll}
            tableRowData={paginationData}
            typeTableRow="students"
            selectedStudentType={selectedStudentType}

            key={isSort}
            scoreSortDir={scoreSortDir}
            sortByScore={sortByScore}
            letterSortDir={letterSortDir}
            sortByLetter={sortByLetter}
            statusSortDir={speedSortDir}
            sortByStatus={sortBySpeed}
          />

          <Pagination
            totalCount={secondTableClone.length}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}

      {modalArchive && (
        <StudentsModalArchive
          setShowAlert={setShowAlert}
          submitForm={submitForm}
          unArchive={modalUnArchive}
          setSelectedStudent={setSelectedStudent}
          selectedStudent={selectedStudent}
          close={modalUnArchive ? closeModalUnArchive : closeModalArchive}
        />
      )}
    </div>
  )
}

export default StudentsContent;