import styled from 'styled-components';

export const StyledNotFoundPage = styled.div`
  .NotFoundPageText {
    margin-top: 24px;
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    line-height: 22px;
    color: #777777;
  }
  .NotFoundPageImgWrapper {
    padding-bottom: 60px;
    position: relative;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
  }
  .NotFoundPageImage {
    position: absolute;
    top: 40px;
    left: -35px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 335px;
    height: 342px;
  }
  .roundBGImg {
    position: absolute;
    top: 35px;
    left: -90px;
  }
`;
