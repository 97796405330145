import { useContext } from 'react';
import { AppContext } from '../../../../../context/AppContext';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import './ParentHeaderProfilePhoto.scss';

const ParentHeaderProfilePhoto = ({ handleClick }) => {
  const { user } = useContext(AppContext);
  // console.log('user', user)
  return (
    <div className="parent_header_profile_person_icon" onClick={handleClick}>
      <div className="parent_header_profile_name">
        {user?.firstName} {user?.lastName}
      </div>
      <div className="parent_header_profile_icon">
        <PersonOutlineOutlinedIcon width="22px" height="22px" />
      </div>
    </div>
  );
};

export default ParentHeaderProfilePhoto;