import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import ErrorBoundary from '../../../../../components/ErrorBaundary';
import CheckboxTwoTone from '../../../../../components/CheckboxTwoTone';
import ArchivePageTeacherInfo from '../../../../../components/ArchiveComponents/ArchivePageTeachersInfo';
import UnarchiveTwoToneIcon from '@mui/icons-material/UnarchiveTwoTone';
import LightTooltip from '../../../../../components/LightTooltip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ArchivePageModalUnarchive from '../../../../../components/ArchiveComponents/ArchivePageModalUnarchive';

import './ArchivePageClassRow.scss';

function ArchivePageClassRow({
  classInfo,
  activeClasses,
  setActiveClasses,
  callRender,
}) {
  const [modalUnarchive, setModalUnarchive] = useState(false);
  const onOpenModalUnarchive = useCallback(() => setModalUnarchive(true), []);
  const onCloseModalUnarchive = useCallback(() => setModalUnarchive(false), []);

  const { teachers, avScore, avSpeed } = classInfo;

  let isClassActive = false;
  if (activeClasses.includes(classInfo)) {
    isClassActive = true;
  }

  const onChangeCheck = () => (isClassActive) ?
    deleteActiveClassHandler(classInfo) :
    setActiveClasses((prevState) => [...prevState, classInfo]);

  const deleteActiveClassHandler = (activeClassId) => {
    const dataToSetAsActiveClass = [...activeClasses];
    const index = dataToSetAsActiveClass.indexOf(activeClassId);
    if (index > -1) {
      dataToSetAsActiveClass.splice(index, 1);
    }
    setActiveClasses(dataToSetAsActiveClass);
  };

  const teachersInfo = teachers.length > 0 ? (
    <ArchivePageTeacherInfo teachers={teachers} />) : (
    <span className="value-noData">no data</span>);

  const scoreInfo = avScore ? (
    <span style={{ color: avScore.color, padding: '3px' }}>
      {avScore.score.toFixed(0)} %
    </span>) : (
    <span className="value-noData">no data</span>);

  const speedInfo = avSpeed ? (
    <span style={{ color: avSpeed.color, padding: '3px' }}>
      {avSpeed.speed}
    </span>) : (
    <span className="value-noData">no data</span>);

  const selectedRow = (isClassActive) ? ' checked' : '';

  return (
    <ErrorBoundary>
      <div className={`archivePageClassRow__inner${selectedRow}`}>
        <span className="archivePageClassRow__checkbox">
          <CheckboxTwoTone checked={isClassActive} onChange={onChangeCheck} />
        </span>
        <div className="archivePageClassRow__content">
          {/* <span className="archivePageClassRow__content-class">{classInfo.class}</span> */}
          <span className="archivePageClassRow__content-class">
            {classInfo?.className ? classInfo.className : classInfo.class}
          </span>
          <div className="archivePageClassRow__content-teachers">
            <span className="value-name">TEACHERS:</span>
            <span className="value-data">{teachersInfo}</span>
          </div>
          <div className="archivePageClassRow__content-score">
            <span className="value-name">AV. SCORE:</span>
            <span className="value-data">{scoreInfo}</span>
          </div>
          <div className="archivePageClassRow__content-speed">
            <span className="value-name">AV. SPEED:</span>
            <span className="value-data">{speedInfo}</span>
          </div>
        </div>
        <div className="archivePageClassRow__button">
          <LightTooltip title="Unarchive" placement="top">
            <UnarchiveTwoToneIcon className="unarchiveBtn" onClick={onOpenModalUnarchive} />
          </LightTooltip>
          <LightTooltip title="Open archived gradebook in the New Tab" placement="top">
            <Link
              target="_blank"
              className="movieLink"
              to={`/archive/class?class=${classInfo.classId}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <OpenInNewIcon />
            </Link>
          </LightTooltip>
        </div>
        {modalUnarchive && (
          <ArchivePageModalUnarchive
            close={onCloseModalUnarchive}
            unarchive={[classInfo.classId]}
            callRender={callRender}
          />
        )}
      </div>
    </ErrorBoundary>
  );
}

export default ArchivePageClassRow;
