import React from 'react';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { OutlinedTextField } from '../../../../OutlinedTextField/OutlinedTextField';
import CheckboxTwoTone from '../../../../CheckboxTwoTone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import './TestInputSelect.scss';

function TestInputSelect({
  control = () => { },
  name = '',
  multiple = false,
  freeSolo = true,
  loader = false,
  open = false,
  setOpen = () => { },
  limitTags = 1,
  data: options = [],
  defaultData = [],
  inputLabel = '',
  setSelectedValue = () => { },
}) {
  console.log(defaultData)

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: 'Required Field',
      }}
      render={({ field: { ref, onChange }, formState: { errors } }) => (
        <Autocomplete
          multiple={multiple}
          freeSolo={freeSolo}
          autoSelect={freeSolo}
          limitTags={limitTags}
          options={options}
          getOptionLabel={(option) => (option?.className && `${option?.className}`) || `${option}`}
          renderInput={(params) => (
            <div className="inputWrapper">
              <OutlinedTextField
                {...params}
                label={inputLabel}
                placeholder="Search"
                inputRef={ref}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loader ? <CircularProgress color="inherit" size={15} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
              <div className="textHelper">
                {errors?.[name]?.message && (
                  <>
                    <p>{errors?.[name]?.message}</p>
                    <InfoOutlinedIcon />
                  </>
                )}
              </div>
            </div>
          )}
          renderOption={(props, option, { selected }) => (
            <li className="checkbox" key={option?.id ? `${option.id}` : option} {...props}>
              <CheckboxTwoTone checked={selected} />
              <span className="value">{option?.className ? `${option.className}` : option}</span>
            </li>
          )}
          onChange={(_, newValues) => {
            setSelectedValue(newValues);
            onChange(newValues);
            setOpen(false);
          }}
          defaultValue={
              defaultData.length > 0
                ? () => {
                  setSelectedValue(defaultData);
                  onChange(defaultData);
                  return defaultData;
                }
                : undefined
          }
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          loading={loader}
          disableCloseOnSelect
          ListboxProps={{ style: { maxHeight: '120px' }, position: 'bottom-start' }}
        />
      )}
    />
  );
}

export default TestInputSelect;
