import styled from 'styled-components/macro';
import BckGd from './photo-user.png';

export const StyledTableCell = styled.div`
  text-align: start;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url(${(props) => (props.backgroundImg ? BckGd : null)}) 0 0/100% 100% no-repeat;
  background: url('./photo-user.png') 0 0/100% 100% no-repeat;
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : null)};

  svg {
    display: inline-block;
    font-size: 20px;
    width: 16px;
    height: 16px;
    color: #c0c0c0;
  }

  .tableRowAction {
    svg {
      color: #828282;

      &:hover {
        color: #5b5b5b;
      }
    }
  }
`;
