import { useEffect, useState } from 'react';
import { TableRow } from '../TableRow/TableRow';
import { TableCell } from '../TableCell/TableCell';
import { TableRowAction } from '../TableRowAction/TableRowAction';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import UnarchiveTwoToneIcon from '@mui/icons-material/UnarchiveTwoTone';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import './TableRowTeachers.scss';
import teacherLogo from '../../images/defaultLogo.svg';
import IconButton from '../buttons/IconButton';
import LightTooltip from '../LightTooltip';

export const TableRowTeachers = ({
  typeTableRow,
  teacher,
  index,
  openModal,
  openSelectMenu,
  selectedTeacher,
  isSelectAll,
  callAlert,
  fillDefaultEditValues,
  openModalUnArchive,
  selectedTeacherType,
  closeAlert,
  setIsSelectedAll,
  dataLength,
}) => {
  const [checkBox, setCheckBox] = useState(false);
  // const [openTeacherDetailInfo, setOpenTeacherDetailInfo] = useState(false);

  // const handleOpenTeacherDetailInfo = () => {
  //   setOpenTeacherDetailInfo((prevState) => !prevState);
  //   //    console.log('openTeacherDetailInfo', openTeacherDetailInfo)
  // };
  //    const handleOpenStudentDetailInfo = () => {
  //    }
  // console.log(teacher)

  useEffect(() => {
    if (selectedTeacher.length === 0) {
      setCheckBox(false);
    }
    if (isSelectAll && teacher.isArchive === 0) {
      setCheckBox(true);
    }
  }, [selectedTeacher, isSelectAll]);

  const openModalCallback = () => {
    fillDefaultEditValues(teacher);
    openModal();
  };

  // FOR THR QUANTITY OF CLASSES
  let classesCounter = 0;
  Object.values(teacher.classes).forEach((item) => { classesCounter += item.length });

  const teschersToTooltipJoin = Object.values(teacher.classes).map((item, index) =>
    index === 0 ? item.join(', ') : ', ' + item.join(', ')
  );
  // console.log('teschersToTooltipJoin', teschersToTooltipJoin);

  return (
    <>
      <TableRow selected={checkBox} typeTableRow={typeTableRow} index={index}>
        <TableCell>
          <div
            style={{
              cursor:
                selectedTeacherType !== 2
                  ? selectedTeacherType === 1
                    ? teacher.isArchive === 1
                      ? 'pointer'
                      : 'not-allowed '
                    : teacher.isArchive === 0
                    ? 'pointer'
                    : 'not-allowed '
                  : 'pointer',
            }}
          >
            <div
              className="checkBox"
              style={{
                pointerEvents:
                  selectedTeacherType !== 2
                    ? selectedTeacherType === 1
                      ? teacher.isArchive === 1
                        ? 'auto'
                        : 'none'
                      : teacher.isArchive === 0
                      ? 'auto'
                      : 'none'
                    : 'auto',
              }}
              onClick={() => {
                setIsSelectedAll(false);
                setCheckBox(!checkBox);
                openSelectMenu(teacher, checkBox);
              }}
            >
              {checkBox ? (
                <CheckBoxTwoToneIcon style={{ color: '#323232' }} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ color: '#828282' }} />
              )}
            </div>
          </div>
        </TableCell>

        <TableCell>
          <div className={teacher.isArchive ? 'archiveImg' : ''}>
            {teacher.photo ? (
              <img className="imgWrapper" src={teacher.photo} alt="Teacher-logo" />
            ) : (
              <img className="imgWrapper" src={teacherLogo} alt="Teacher-logo" />
            )}
          </div>
        </TableCell>

        <TableCell>
          {teacher.isArchive ? (
            <div>
              {teacher.teacherName}
              <br />
              <div className="archived">Archived</div>
            </div>
          ) : (
            teacher.teacherName
          )}
        </TableCell>

        {teacher.stats ? (
          <>
            <TableCell color={teacher.stats.avScore.color}>
              {Math.round(teacher.stats.avScore.score)}%
            </TableCell>
            <TableCell color={teacher.stats.avStatus.color}>
              {teacher.stats.avStatus.speed}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
          </>
        )}

        <TableCell>
          <div
            className="flexCell"
            onClick={() => {
              navigator.clipboard.writeText(teacher.phone);
              callAlert({ type: true, text: 'Copied!', close: closeAlert });
            }}
          >
            <IconButton type={'3'} active={true} iconName={'ContentCopyTwoTone'} />
            {teacher.phone}
          </div>
        </TableCell>
        <TableCell>
          <div
            className="flexCell"
            onClick={() => {
              navigator.clipboard.writeText(teacher.email);
              callAlert({ type: true, text: 'Copied!', close: closeAlert });
            }}
          >
            <IconButton type={'3'} active={true} iconName={'ContentCopyTwoTone'} />
            <div className="ellipsisCell" title={teacher.email}>
              {teacher.email}
            </div>
          </div>
        </TableCell>

        <TableCell>
          <div
            className="ellipsisCell"
            title={Object.values(teacher.classes).join(', ')}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <LightTooltip title={teschersToTooltipJoin} placement="top">
              <InfoOutlinedIcon style={{ cursor: 'pointer', marginRight: 3 }} />
            </LightTooltip>
            {classesCounter}
          </div>
        </TableCell>

        <TableCell>
          {teacher.isArchive ? (
            <button
              className="unArchiveBtn"
              style={{
                pointerEvents:
                  selectedTeacherType !== 2
                    ? selectedTeacherType === 1
                      ? teacher.isArchive === 1
                        ? 'auto'
                        : 'none'
                      : teacher.isArchive === 0
                      ? 'auto'
                      : 'none'
                    : 'auto',
              }}
              onClick={() => {
                openModalUnArchive(teacher);
              }}
            >
              <UnarchiveTwoToneIcon />
            </button>
          ) : (
            <TableRowAction
              // handleForOpenIcon={handleOpenTeacherDetailInfo}
              openModal={openModalCallback}
              btnOne={true}
              btnTwo={false}
              btnThree={false}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
