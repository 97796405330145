import axios from "axios";
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../../../components/Button/Button';
import { AppContext } from '../../../../../context/AppContext';
import { config } from "../../../../../config"
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import './ParentHeaderProfilePopUp.scss';

export const ParentHeaderProfilePopUp = ({ handleParentEditProfileModalOpen }) => {
  const history = useHistory();
  const {
    isAuth,
    setIsAuth,
    user,
    updateUser,
    defaultUser,
    updateSchools,
    setCurrentSchoolId,
    updateCurrentClassId,
    setUploadedPhoto,
    setFileToSave,
  } = useContext(AppContext);

  const userParent = {
    userName: `${user.firstName} ${user.lastName}` ?? "",
    status: 'Parent',
    userEmail: `${user.email}` ?? "",
  };

  const logout = async () =>{
    try {
      await axios.get(`${config.API_URL}/api/auth/logout`, {
        withCredentials: true
      }).then((response) => {
       return response
      });
    } catch (e) {
      console.log(e);
    }
   }
  
  const handleLogout = async () => {
    logout()
    setIsAuth(false);
    updateUser(defaultUser);
    updateSchools([]);
    setCurrentSchoolId(null);
    updateCurrentClassId(null);
    setUploadedPhoto('');
    setFileToSave({
    photo: null,
  })
        localStorage.clear();
    history.push("/sign-in")
  }

  return (
    <div className="parent_portfolioPopUp_wrapper">
      <div className="parent_portfolioPopUp_privateInfo">
        <div className="parent_portfolioPopUp_statusWrapper">
          <div className="parent_portfolioPopUp_fullName">{userParent.userName}</div>
          <div className="parent_portfolioPopUp_position">
            <span className="parent_portfolioPopUp_status">Status:</span>
            <span className="parent_portfolioPopUp_statusName">{userParent.status}</span>
          </div>
        </div>
      </div>
      <div className="parent_portfolioPopUp_userEmailWrapper">
        <span className="parent_portfolioPopUp_userEmail">EMAIL:</span>
        <span className="parent_portfolioPopUp_userEmailData">{userParent.userEmail}</span>
      </div>
      <div className="parent_portfolioPopUp_main">
        <div className="parent_portfolioPopUp_buttonWrapper">
          <Button onClick={handleParentEditProfileModalOpen}>Change Password</Button>
        </div>
        <div className="parent_greyHorizontalLine"></div>
        <div className="parent_portfolioPopUp_exitWrapper" onClick={handleLogout}>
          <LoginOutlinedIcon style={{ marginRight: 5 }} />
          Log out
        </div>
      </div>
    </div>
  );
};
