import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import './Alert.scss';

const modalRoot = document.getElementById("modal-root");

export const Alert = ({ alertType, text, close = () => { }, show = true }) => {
  const [slide, setSlide] = useState(-280);
  const closeAlert = useCallback(() => {
    let timer = 0;
    const slideInterval = setInterval(() => {
      if (timer > -280) {
        timer -= 4;
        setSlide(timer);
      } else {
        clearInterval(slideInterval);
        close();
      }
    }, 1);
  }, [close]);

  useEffect(() => {
    let timer = -280;
    const slideInterval = setInterval(() => {
      if (timer < 0) {
        timer += 4;
        setSlide(timer);
      } else {
        clearInterval(slideInterval);
      }
    }, 1);
    const sliderTimer = setTimeout(closeAlert, 3000);
    return () => {
      clearInterval(slideInterval);
      clearTimeout(sliderTimer);
    };
  }, [closeAlert]);

  return (
    createPortal(
      <div className='backdrop' style={{ right: `${slide}px` }}>
        <div className="modal">
          <button
            className='closeBtn'
            onClick={closeAlert}>
            <CloseIcon style={{ color: '#828282' }} />
          </button>

          <div className="content">
            {(alertType && show) ? <CheckCircleOutlinedIcon style={{ color: '#0F9D58' }} />
              : <ErrorOutlineOutlinedIcon style={{ color: '#A6192E' }} />}
            <div className={`text ${alertType ? 'greenText' : ''}`}>
              {text}
            </div>
          </div>
        </div>
      </div>,
      modalRoot
    )
  );
};
