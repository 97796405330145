import { useCallback, useContext, useState } from 'react';
import { LoaderFullBook } from '../../../../components/Loaders/LoaderFullBook/LoaderFullBook';
import { AppContext } from '../../../../context/AppContext';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styles from './ParentSchoolSelect.module.scss';
import { ParentSchoolSelectPopover } from './ParentSchoolSelectPopover/ParentSchoolSelectPopover';



export const ParentSchoolSelect = () => {
  const {
    showLoader,
    setShowLoader,
    schools = [],
    updateSchools,
    currentSchool,
    currentSchoolId,
    setCurrentSchoolId,
    updateCurrentSchoolId,
    getSchoolsData,
    updateSchoolName,
    setUpdateSchoolInfo,
  } = useContext(AppContext);

  const [mouseOverSchoolId, setMouseOverSchoolId] = useState(null);
  const mouseOverSchool = schools.find((school) => school.Id === mouseOverSchoolId);

  const handleSchoolMouseOver = useCallback((schoolId) => () => setMouseOverSchoolId(schoolId), []);
  const handleSchoolMouseOut = useCallback(() => setMouseOverSchoolId(null), []);

  const handleChooseSchool = (schoolId) => () => {
    schools.find(({ Id }) => Id === schoolId);
    setCurrentSchoolId(schoolId);
    localStorage.setItem('currentSchoolId', JSON.stringify(schoolId));
  };

  return (
    <>
      {showLoader && <LoaderFullBook />}
      <div
        id="SchoolSelectContainer"
        className={styles.container}
        onMouseLeave={handleSchoolMouseOut}
      >
        <div className={styles.currentSchoolName}>
          {currentSchool?.Name ? currentSchool.Name : 'School'}
        </div>

        <div className={styles.optionsContainer}>
          {schools.map(({ Id = 1, Name }) => (
            <div
              key={Id}
              onClick={handleChooseSchool(Id)}
              onMouseOver={handleSchoolMouseOver(Id)}
              className={styles.option}
            >
              {Name}
            </div>
          ))}
        </div>

        <ArrowDropDownIcon color="disabled" fontSize="large" className={styles.arrowDown} />

        {mouseOverSchool && (
          <ParentSchoolSelectPopover
            school={mouseOverSchool}
          />
        )}
      </div>
    </>
  );
};


