
import styled from 'styled-components';

export const ExpButtonStyled = styled.div`
  margin-right: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg{
    height: 16px;
    width: 16px;
  }

  & a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;

    &.white{
      color: #fff;

      &:hover{
       color: #F2F2F2;
      }
    }  
  }

  .ExpBtn {
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  background-color: #a6192e;
  font-family: Rubik;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  }
`;


