import { forwardRef, useCallback, useState } from 'react';

import { ModalContainer } from '../../../Modal/ModalContainer/ModalContainer';
import { ModalHeader } from '../../../Modal/ModalHeader/ModalHeader';
import { ModalWrapper } from "../../../Modal/ModalWrapper/ModalWrapper";
import TestForm from '../TestForm';

const AddTestModal = forwardRef(
  ({ closeModal, callRerender, callAlert, closeAlert, testsData, classInfo, defaultClass}, ref) => {
    const [loader, setLoader] = useState(false);

    return (
      <>
        <ModalWrapper loader={loader}>
          <ModalContainer ref={ref}>
            <ModalHeader header="Add New Test" onClose={closeModal} />
            <TestForm
              defaultClass={defaultClass}
              testsData={testsData}
              closeModal={closeModal}
              callAlert={callAlert}
              closeAlert={closeAlert}
              callRerender={callRerender}
              setLoader={setLoader}
              isEditForm={false}
              classInfo={classInfo}
            />
          </ModalContainer>
        </ModalWrapper>
      </>
    );
  }
);

export default AddTestModal;
