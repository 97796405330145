import React, { createRef, useState } from 'react';
import { TeacherInfoPopOver } from '../../TeacherInfoPopOver/TeacherInfoPopOver';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

function TestsPageTeacherInfo({ teachers }) {

  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = createRef();
  const openTeacherInfo = Boolean(anchorEl);
  const handleOpenInfoTeacher = (event) => setAnchorEl(event.currentTarget);
  const handleCloseInfoTeacher = () => setAnchorEl(null);

  const allTeachers = teachers
    .map(({ firstName, lastName }) =>
      `${firstName[0].toUpperCase() + firstName.slice(1).toLowerCase()} ` +
      `${lastName[0].toUpperCase() + lastName.slice(1).toLowerCase()}`)
    .join(', ');


  return (
    <>
      <InfoOutlinedIcon onClick={handleOpenInfoTeacher} />
      <Popover
        open={openTeacherInfo}
        onClose={handleCloseInfoTeacher}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        ref={divRef}
        elevation={2}
      >
        <Typography component={'span'} variant={'body2'}>
          <TeacherInfoPopOver teachers={teachers} ref={divRef} />
        </Typography>
      </Popover>
      <span className="value teacherNames">
        {/* {allTeachers} */}
        {allTeachers.length > 32 ? allTeachers.substr(0, 30) + '...' : allTeachers}
      </span>
    </>
  );
}

export default TestsPageTeacherInfo;
